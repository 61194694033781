header {
  letter-spacing: 0.04em;
  width: 100%;
  height: cal(100, pc);
  background: $color-white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;

  .header {
    &__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      // max-width: cal($pcsize, pc);
      height: 100%;
      padding: 0 cal(180, pc);
      margin: 0 auto;
    }


    &__logo {
      width: cal(284.85, pc);
      margin-top: cal(-4, pc);
      margin-left: cal(-125, pc);

      img {
        margin: 0;
      }

      span {
        display: none;
      }
    }

    &__menu {
      &__list {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      &__item {
        font-size: cal(16, pc);
        line-height: (24 / 16);
        font-weight: $medium;

        &+.header__menu__item {
          margin-left: cal(32, pc);
        }
      }
    }

    &__btns {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      position: absolute;
      right: 0;
      top: 0;

      &__item {
        width: cal(120, pc);
        height: cal(100, pc);

        &.recruit {
          a {
            background: $header-recruit-color;
          }
        }

        a {
          font-size: cal(15, pc);
          line-height: (24 / 15);
          font-weight: $bold;
          color: $color-white;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        }
      }
    }

  }
}