#about {
  .sec {
    .container {
      width: 100%;
      max-width: cal($spsize);
      height: 100%;
      padding: 0 cal(40);
      margin: 0 auto;
      position: relative;
    }

    &-title {
      font-size: cal(32);
      line-height: (40 / 32);
      padding-bottom: cal(20);
      margin-bottom: cal(82);
      position: relative;

      &::after {
        content: "";
        display: inline-block;
        width: cal(40);
        height: cal(2);
        background: $primary-color;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }

  #head {
    padding: cal(100) 0;
    background: $color-white;
    position: relative;

    .container {
      width: 100%;
      padding: 0;
    }

    .head {
      &__contents {
        color: $color-white;
        letter-spacing: 0.04em;
        padding: cal(145) cal(40) cal(160);
        position: relative;
      }

      &__bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;

        >div {
          width: 100%;
          height: 100%;
          background: $primary-color center / cover no-repeat;
        }
      }

      &__box {
        position: relative;
      }

      &__title {
        margin-bottom: cal(70.5);

        h2 {
          font-size: cal(64);
          line-height: (76 / 64);
          font-weight: $semibold;
          margin-bottom: cal(30);
        }

        p {
          font-size: cal(24);
          line-height: (42 / 24);
          font-weight: $bold;
        }
      }

      &__desc {
        font-size: cal(20);
        line-height: (29 / 20);
        font-weight: $medium;
        margin-bottom: cal(57.5);
      }

      &__list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      &__item {
        width: calc(50% - #{cal(5)});
        background-color: rgba($primary-color, 0.4);
        backdrop-filter: blur(10px) brightness(1.5);

        &:nth-child(n + 3) {
          margin-top: cal(10);
        }
      }
    }
  }

  #message {
    padding: cal(98) 0;
    background: $secondry-background-color;

    .message {
      &__item {
        letter-spacing: 0.04em;
        padding-bottom: cal(80);

        &:nth-child(n + 2) {
          border-top: cal(1) solid $border-color;
          padding-top: cal(80);
        }

        &:last-child {
          padding-bottom: 0;
        }

        &__img {
          width: cal(240);
          margin: 0 auto cal(80);
        }

        &__body {
          flex: 1;
        }

        &__text {
          font-size: cal(22);
          line-height: (32 / 22);
          margin-bottom: cal(38);

          p+p {
            margin-top: cal(32);
          }
        }

        &__name {
          font-size: cal(24);
          line-height: (32 / 24);
          font-weight: $bold;
        }
      }
    }
  }

  #overview {
    padding: cal(100) 0;

    .overview {
      &__title{
        margin-bottom: cal(10);
      }

      &__item {
        font-size: cal(22);
        line-height: (32 / 22);
        letter-spacing: 0.04em;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: cal(38) cal(18);
        border-top: cal(1) solid $border-color;

        &:last-child {
          border-bottom: cal(1) solid $border-color;
        }

        &__label {
          font-weight: $medium;
          width: cal(180);
        }

        &__body {
          flex: 1;

          a {
            text-decoration: underline;
          }
        }

        &__posts {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;

          &__post {
            width: cal(200);
          }
        }
      }
    }
  }

  #lang, #lang2{
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    margin-bottom: cal(38.6);

    input[type="radio"]{
      display    : none;
    }

    label{
      display: inline-block;
      font-size: cal(15);
      line-height: (32 / 15);
      letter-spacing: 0.04em;

      border: cal(1) solid #2F5897;
      border-collapse: collapse;
      padding: cal(10) cal(15);
      color: #21589C;
      cursor: pointer;
    }

    input[type="radio"]:checked + label {
      background : #2F5897;
      color: #FFFFFF;
    }
  }

  #history {
    padding: cal(98) 0;
    background: $secondry-background-color;

    .history {
      &__list {
        +.history__list {
          margin-top: cal(80);
        }

        &__title {
          font-size: cal(22);
          line-height: (32 / 22);
          letter-spacing: 0.04em;
          font-weight: $bold;
          padding-left: cal(22);
          margin-bottom: cal(40);
          position: relative;

          &::before {
            content: "";
            display: inline-block;
            width: cal(7);
            height: cal(7);
            background: $primary-color;
            position: absolute;
            left: 0;
            top: cal(11.5);
          }
        }

        &__item {
          font-size: cal(22);
          line-height: (32 / 22);
          letter-spacing: 0.04em;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          padding: cal(38) cal(18);
          border-top: cal(1) solid $border-color;

          &:last-child {
            border-bottom: cal(1) solid $border-color;
          }

          &__time {
            font-weight: $medium;
            width: cal(180);
          }

          &__body {
            flex: 1;
          }
        }
      }
    }
  }

  #access {
    padding: cal(100) 0;

    .access {
      &__title{
        margin-bottom: cal(10);
      }

      &__item {
        padding: cal(80) 0;
        border-top: cal(1) solid $border-color;

        &:last-child {
          border-bottom: cal(1) solid $border-color;
        }

        &__body {
          letter-spacing: 0.04em;
          margin-bottom: cal(80);
        }

        &__name {
          font-size: cal(26);
          line-height: (36 / 26);
          font-weight: $bold;
          padding-left: cal(26);
          position: relative;

          &::before {
            content: "";
            display: inline-block;
            width: cal(7);
            height: cal(7);
            background: $primary-color;
            position: absolute;
            left: 0;
            top: cal(11.5);
          }
        }

        &__address,
        &__tel {
          font-size: cal(24);
          line-height: (36 / 24);
        }

        &__map {
          width: 100%;
          height: cal(480);

          iframe {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  #others {
    padding: cal(100) 0;
    background: $secondry-background-color;
    margin-bottom: cal(40);

    .others {
      &__item {
        font-size: cal(24);
        line-height: (32 / 24);
        letter-spacing: 0.04em;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: cal(38) 0 cal(38) cal(18);
        border-top: cal(1) solid $border-color;

        &:last-child {
          border-bottom: cal(1) solid $border-color;
        }

        &__label {
          font-weight: $medium;
          width: cal(180);
          margin-right: cal(20);
        }

        &__body {
          flex: 1;

          a {
            text-decoration: underline;
          }
        }

        &__desc {
          font-size: cal(22);
          line-height: (24 / 22);
          margin-top: cal(24);
        }
      }
    }
  }

}