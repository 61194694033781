footer {
  color: $footer-text-color;
  background: $footer-background-color;
  padding: cal(142, pc) 0 cal(138, pc);

  .footer {
    &__inner {
      max-width: cal($pcsize, pc);
      padding: 0 cal(180, pc);
      margin: 0 auto;
    }

    &__nav {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: cal(92, pc);
    }

    &__sec {
      padding-top: cal(14, pc);

      &__title {
        font-size: cal(16, pc);
        line-height: (24 / 16);
        font-weight: $bold;
        color: $color-white;
        display: inline-block;
        padding-bottom: cal(17, pc);
        position: relative;

        &::after {
          content: "";
          display: inline-block;
          width: cal(20, pc);
          height: 1px;
          background: $footer-border-color;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }

      &__block {
        &+.footer__sec__block {
          margin-top: cal(78, pc);
        }
      }
    }

    &__menus {
      font-size: cal(14, pc);
      line-height: (30 / 14);
      margin-top: cal(34, pc);

      p {
        font-weight: $bold;
      }
    }

    &__list {
      &+.footer__list {
        margin-top: cal(18, pc);
      }
    }

    &__logo {
      width: cal(280.95, pc);
    }


    &__copy {
      font-size: cal(14, pc);
      line-height: (18 / 14);
      font-weight: $medium;
      text-align: center;
    }
  }
}
