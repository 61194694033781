html {
  font-size: 3.125vw;
  font-family: $primary-font;

  &.resize {
    * {
      font-size: inherit;
    }
  }

  @include mq(max) {
    @media (orientation: landscape) {
      font-size: 3.125vh;
    }
  }

  @include mq(min, $max-width) {
    font-size: $max-width * 0.03125;
  }
}

img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;

  @include mq(max) {
    width: 100%;
  }
}

.sp {
  @include mq() {
    display: none !important;
  }
}

.pc {
  @include mq(max) {
    display: none !important;
  }
}

body {
  font-size: 16px;
  color: $text-color;
  font-weight: $regular;
  font-feature-settings: "palt";
  overflow: hidden;
  background-color: $background-color;

  &.loaded {
    overflow: auto;
  }

  &.open {
    overflow: hidden;
  }
}

.obj-c {
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.obj-con {
  object-fit: contain;
  font-family: 'object-fit: contain;';
}

.container {
  margin: 0 auto;
}

.barlow {
  font-family: $secondry-font;
}

.barlow-italic {
  font-family: $secondry-font;
  font-style: italic;
}

a,
button {
  @include mq() {
    display: inline-block;
    transition: 0.3s ease all;

    &:hover {
      opacity: 0.7;
    }
  }
}

main {
  display: block;
  position: relative;
  z-index: 0;

  @include mq() {
    margin-top: cal(100, pc);
  }

  @include mq(max) {
    margin-top: cal(100);
  }

  #pagetop {
    @include mq() {
      width: cal(60, pc);
      position: absolute;
      bottom: cal(40, pc);
      right: cal(40, pc);
      z-index: 100;

      img {
        width: 100%;
        margin: 0;
      }
    }

    @include mq(max) {
      width: cal(80);
      position: absolute;
      bottom: cal(20);
      right: cal(20);
      z-index: 100;

      img {
        width: 100%;
        margin: 0;
      }
    }
  }
}