/*-------------------------------------------------
  メディアクエリ生成 (デザイン幅)
  mq(min) → PC版
  mq(max) → SP版
-------------------------------------------------*/
@mixin mq($rule: min, $breakpoint: $break-point) {
  @if $rule == min {
    $breakpoint: $breakpoint + 1;
  }
  @media (#{$rule}-width: $breakpoint) {
    @content;
  }
}
@mixin orientation($rule: landscape) {
  @media (orientation: $rule) {
    @content;
  }
}
/*-------------------------------------------------
  レスポンシブ計算
  rem : cal(デザインデータサイズ,  pc or sp) default : sp
  vw : calvw(デザインデータサイズ,  pc or sp) default : sp
-------------------------------------------------*/
@function cal($size, $device : sp) {
  $return : 0;
  @if $device == pc {
    $ratio : $pcsize / $spsize;
    $return: $size / $spsize * 100 / 3.125 / $ratio;
  }
  @elseif $device == sp {
    $return: $size / $spsize * 100 / 3.125;
  }
  @return $return * 1rem;
}

@function calvw($size, $device : sp) {
  $return : 0;
  @if $device == pc {
    $ratio : $pcsize / $spsize;
    $return: $size / $spsize * 100 / $ratio;
  }
  @elseif $device == sp {
    $return: $size / $spsize * 100;
  }
  @return $return * 1vw;
}


@mixin Button {
  width: cal(280, pc);

  a {
    font-size: cal(16, pc);
    line-height: (24 / 16);
    font-weight: $bold;
    color: $color-white;
    text-align: center;
    display: inline-block;
    width: 100%;
    padding: cal(28, pc) 0;
    background: $primary-color;
    position: relative;
    transition: 0.3s ease all;

    &::after {
      content: "";
      width: cal(16.29, pc);
      height: cal(10.89, pc);
      background: url(../img/common/arrow_right.svg) center / contain no-repeat;
      position: absolute;
      top: 50%;
      right: cal(30.3, pc);
      transform: translateY(-50%);
      z-index: 0;
    }

    &:hover {
      opacity: 0.7;
    }
  }
}

@mixin ButtonSP {
  width: 100%;

  a {
    font-size: cal(20);
    line-height: (30 / 20);
    font-weight: $bold;
    color: $color-white;
    text-align: center;
    display: inline-block;
    width: 100%;
    padding: cal(38) 0;
    background: $primary-color;
    position: relative;

    &::after {
      content: "";
      width: cal(20);
      height: cal(13.36);
      background: url(../img/common/arrow_right.svg) center / contain no-repeat;
      position: absolute;
      top: 50%;
      right: cal(40);
      transform: translateY(-50%);
      z-index: 0;
    }
  }
}