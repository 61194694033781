#recruit {
  .sec {
    .container {
      width: 100%;
      max-width: cal($pcsize, pc);
      padding: 0 cal(180, pc);
      margin: 0 auto;
      position: relative;
    }

    &-title {
      font-size: cal(28, pc);
      line-height: (40 / 28);
      font-weight: $bold;
      color: $primary-color;
      margin-bottom: cal(26, pc);
    }
  }

  #desc {
    padding: cal(100, pc) 0 cal(60, pc);
    background: $color-white;

    .desc {
      &__desc {
        font-size: cal(18, pc);
        font-weight: $bold;
        line-height: (32 / 18);
        letter-spacing: 0.04em;
      }
    }
  }

  .sbiwork{
    padding-bottom: cal(90, pc) !important;

    .desc__desc {
      text-align: center;
    }
  }

  #box {
    padding: 0 0 cal(200, pc);
    background: $color-white;

    .box {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;

      &__title {
        width: cal(580, pc);
        height: cal(200, pc);
        text-align: center;
        background-color: $color-green;
        font-size: cal(24, pc);
        line-height: (36 / 24);
        color: $color-white;
        border-radius: cal(10, pc);
        padding-top: cal(89, pc);
      }

      a:first-child {
        .box__title{
          padding-top: cal(70, pc);
        }
      }

      a:nth-child(even) {
        margin-left: auto;
      }

      a:nth-child(n+3) {
        margin-top: cal(60, pc);
      }
    }
  }

  #sbiwork_box {
    padding: 0 0 cal(200, pc);
    background: $color-white;

    .sbiwork_box {
      text-align: center;

      &__title {
        width: cal(580, pc);
        height: cal(100, pc);
        text-align: center;
        background-color: $color-green;
        font-size: cal(24, pc);
        line-height: (36 / 24);
        color: $color-white;
        border-radius: cal(10, pc);
        padding-top: cal(33, pc);
      }

    }
  }

  #head {
    letter-spacing: 0.04em;
    font-weight: $bold;
    padding: cal(104, pc) 0 cal(70, pc);
    background: $color-white;

    .head {
      &__title {
        font-size: cal(36, pc);
        line-height: (54 / 36);
        padding-bottom: cal(12, pc);
        position: relative;

        &::after {
          content: "";
          display: inline-block;
          width: cal(40, pc);
          height: cal(2, pc);
          background: $primary-color;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }

      &__desc {
        font-size: cal(18, pc);
        line-height: (32 / 18);
        margin-top: cal(72, pc);
      }
    }
  }

  #btn {

    .btn {
      width: cal(580, pc);
      margin: 0 auto;

      a {
        letter-spacing: 0.04em;
        font-size: cal(24, pc);
        font-weight: $bold;
        line-height: (32 / 24);
        text-align: center;
        display: inline-block;
        width: 100%;
        padding: cal(33, pc) cal(178, pc) cal(32, pc);
        color: $color-green;
        background: $color-white;
        margin-bottom: cal(100, pc);
        border: cal(1, pc) solid $color-green;
        border-radius: cal(10, pc);
      }
    }
  }

  #content {
    letter-spacing: 0.04em;
    font-weight: $bold;
    padding: cal(93.5, pc) 0 cal(100, pc);
    background: $tertiary-background-color;

    .content {
      &__title {
        font-size: cal(30, pc);
        line-height: (36 / 30);
        color: $color-green;
        margin-bottom: cal(36, pc);
      }

      &__box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: $color-white;
        font-size: cal(16, pc);
        font-weight: $bold;
        line-height: (36 / 16);
        padding: cal(41.4, pc) cal(49, pc) cal(41, pc) cal(50, pc);
        border-radius: cal(10, pc);

        ul{
          width: cal(750, pc);

          li{
            position: relative;
            padding-left: cal(25, pc);

            &::before {
              position: absolute;
              content: "";
              width: cal(16, pc);
              height: cal(16, pc);
              top: cal(11, pc);
              left: 0;
              vertical-align: top;
              border-radius: cal(16, pc);
              background-color: $color-green;
            }
          }
        }

        &__img {
          width: cal(360, pc);
          margin-left: cal(30, pc);
        }
      }

      &__title:nth-child(3){
        margin-top: cal(80, pc);
      }
    }
  }

  #question {
    letter-spacing: 0.04em;
    padding: cal(100, pc) 0 cal(99.5, pc);
    background: $color-white;

    .question {
      &__title {
        font-size: cal(30, pc);
        font-weight: $bold;
        line-height: (36 / 30);
        color: $color-green;
        margin-bottom: cal(55.4, pc);
      }

      &__item {
        margin-bottom: cal(30, pc);

        &__title {
          background: $color-green;
          font-size: cal(18, pc);
          font-weight: $bold;
          line-height: (30 / 18);
          color: $color-white;
          padding: cal(27.7, pc) cal(404, pc) cal(21.7, pc) cal(20, pc);
          border-radius: cal(10, pc) cal(10, pc) 0 0;

          position: relative;

          h4{
            &::before {
              content: "";
              display: inline-block;
              width: cal(40, pc);
              height: cal(40, pc);
              background: url(../img/recruit/lifestyle/icon_lifestyle01.svg) no-repeat;
              background-size: contain;
              vertical-align: middle;
              margin-right: cal(20, pc);
            }
          }
        }

        &__desc {
          position: relative;
          background: $color-white;
          font-size: cal(16, pc);
          line-height: (30 / 16);
          padding: cal(26.8, pc) cal(36 , pc) cal(32.6, pc) cal(80, pc);
          border: cal(1, pc) solid #DDDDDD;
          border-radius: 0 0 cal(10, pc) cal(10, pc);

          span{
            &::before {
              position: absolute;
              content: "";
              display: inline-block;
              width: cal(40, pc);
              height: cal(40, pc);
              background: url(../img/recruit/lifestyle/icon_lifestyle02.svg) no-repeat;
              background-size: contain;
              top: cal(20, pc);
              left: cal(20, pc);
              padding-right: cal(20, pc);
            }
          }
        }
      }
    }
  }

  #found {
    padding: cal(99.6, pc) 0 cal(100, pc);
    background: $tertiary-background-color;

    .found {
      &__title {
        font-size: cal(30, pc);
        line-height: (46 / 30);
        font-weight: $bold;
        color: $color-green;
        text-align: center;
        margin-bottom: cal(32, pc);
      }

      &__desc {
        font-size: cal(16, pc);
        line-height: (30 / 16);
        text-align: center;
        margin-bottom: cal(57, pc);
      }

      &__btn {
        width: cal(580, pc);
        margin: 0 auto;

        a {
          font-size: cal(24, pc);
          line-height: (32 / 24);
          font-weight: $bold;
          color: $color-white;
          text-align: center;
          display: inline-block;
          width: 100%;
          padding: cal(33, pc) 0;
          background: $color-green;
          border-radius: cal(10, pc);

        }
      }
    }
  }

  #flow {
    background: $tertiary-background-color;
    padding: cal(100, pc) 0;
    position: relative;

    .flow {
      &__title {
        color: $color-green;
      }

      &__desc {
        font-size: cal(16, pc);
        line-height: (30 / 16);
        letter-spacing: 0.04em;
        font-weight: $medium;
        margin-bottom: cal(54, pc);
      }

      &__contents {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
      }

      &__item {
        height: cal(150, pc);
        flex: 1;
        background: $background-color;
        position: relative;

        &:nth-child(n + 2) {
          margin-left: cal(10, pc);
        }

        &:last-child {
          &::before,
          &::after {
            content: none;
          }

          .flow__item__wrapper {
            margin-left: 0;
          }
        }

        &::before,
        &::after {
          content: "";
          width: cal(32, pc);
          height: cal(75, pc);
          display: inline-block;
          position: absolute;
          right: 0;
        }

        &::before {
          top: 0;
          border-top: cal(37.5, pc) solid $tertiary-background-color;
          border-right: cal(16, pc) solid $tertiary-background-color;
          border-bottom: cal(37.5, pc) solid transparent;
          border-left: cal(16, pc) solid transparent;
        }

        &::after {
          bottom: 0;
          border-top: cal(37.5, pc) solid transparent;
          border-right: cal(16, pc) solid $tertiary-background-color;
          border-bottom: cal(37.5, pc) solid $tertiary-background-color;
          border-left: cal(16, pc) solid transparent;
        }

        &__wrapper {
          letter-spacing: 0.04em;
          text-align: center;
          white-space: nowrap;
          margin-left: cal(-10, pc);
          position: absolute;
          top: cal(28, pc);
          left: 50%;
          z-index: 2;
          transform: translateX(-50%);
        }

        &__number {
          font-size: cal(14, pc);
          line-height: (17 / 14);
          font-weight: $semibold;
          color: $color-green;
          margin-bottom: cal(9, pc);
        }

        &__text {
          font-size: cal(16, pc);
          line-height: (24 / 16);
          font-weight: $bold;
        }
      }
    }
  }

  #contact {
    padding: cal(90, pc) 0 cal(200, pc);
    background: $background-color;

    .contact {
      &__title {
        font-size: cal(30, pc);
        line-height: (36 / 30);
        font-weight: $bold;
        color: $color-green;
        text-align: center;
        margin-bottom: cal(55.4, pc);
      }

      &__btn {
        width: cal(580, pc);
        margin: 0 auto;

        a {
          font-size: cal(24, pc);
          line-height: (32 / 24);
          font-weight: $bold;
          color: $color-white;
          text-align: center;
          display: inline-block;
          width: 100%;
          padding: cal(33, pc) 0;
          background: $color-green;
          border-radius: cal(10, pc);

        }
      }
    }
  }

  #jobform {
    padding: cal(90, pc) 0 cal(160, pc);
    background: $color-white;

    .container {
      padding: 0 cal(260, pc);
    }

    .jobform {
      &__title {
        font-size: cal(30, pc);
        line-height: (36 / 30);
        font-weight: $bold;
        color: $color-green;
        text-align: center;
        margin-bottom: cal(54.6, pc);
      }

      &__content {
        padding: cal(60, pc) cal(83, pc) cal(78.5, pc) cal(82, pc);
        border: cal(1, pc) solid #CCCCCC;
        border-radius: cal(10, pc);

        &__desc {
          width: cal(732, pc);
          font-size: cal(16, pc);
          line-height: (30 / 16);
          margin: 0 auto;
          margin-bottom: cal(45, pc);
        }

        &__item {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          width: 100%;
          margin-bottom: cal(20, pc);
          position: relative;

          &__label {
            width: cal(249, pc);
            margin-right: cal(60, pc);
            font-size: cal(16, pc);
            line-height: (65 / 16);
            font-weight: $bold;
            color: $text-color;
            letter-spacing: 0.04em;
          }

          &__input {
            width: cal(640, pc);

            &__box {
              width: 100%;
              height: cal(280, pc);
              overflow-x: auto;
              box-sizing: border-box;
              margin-bottom: cal(39, pc);
              padding: cal(20, pc);
              border: cal(1, pc) solid #CCCCCC;
              border-radius: cal(5, pc);
              outline: none;

              font-size: cal(14, pc);
              line-height: (24 / 14);
              letter-spacing: 0.04em;
            }

            &__radio {
              display: flex;
              align-items: flex-start;
              justify-content: flex-start;
              //width: cal(100, pc) !important;

              &__label {
                position: relative; /* ボックスの位置を指定する */
                padding: 0 0 0 42px; /* ボックス内側の余白を指定する */
                font-size: cal(16, pc);
                line-height: (60 / 16);
                font-weight: $bold;
                color: $text-color;
              }

              &__label:after, &__label:before {
                position: absolute; /* ボックスの位置を指定する */
                content: ""; /* ボックスのコンテンツ */
                display: block; /* ブロックレベル要素化する */
                top: 50%; /* 上部から配置の基準位置を決める */
              }

              &__label:after {
                left: 15px; /* 左から配置の基準位置を決める */
                margin-top: -10px; /* チェック枠の位置 */
                width: 16px; /* ボックスの横幅を指定する */
                height: 16px; /* ボックスの高さを指定する */
                border: 2px solid #ccc; /* ボックスの境界線を実線で指定する */
                border-radius: 50%; /* ボックスの角丸を指定する */
              }

              &__label:before {
                left: 20px; /* 左から配置の基準位置を決める */
                margin-top: -5px; /* チェックマークの位置 */
                width: 10px; /* ボックスの横幅を指定する */
                height: 10px; /* ボックスの高さを指定する */
                background: red; /* ボックスの背景色を指定する */
                border-radius: 50%; /* ボックスの角丸を指定する */
                opacity: 0; /* 要素を透過指定する */
              }

              input[type=radio] {
                display: none;
              }

              input[type=radio]:checked + .jobform__content__item__input__radio__label::before {
                opacity: 1; /* 要素を表示する */
              }

              &__label:hover:after {
                border-color: #0171bd; /* ボックスの境界線を実線で指定する */
              }
            }
          }

          .birthday {
            &__input {
              width: cal(120, pc) !important;
              margin-right: cal(9.5, pc);
            }

            &__span {
              font-size: cal(16, pc);
              line-height: (30 / 16);
              letter-spacing: 0.04em;
              color: $text-color;
              margin-right: cal(19.5, pc);
            }
          }

          label {
            position: relative;
            display: block;
            width: 100%;

            &::before {
              position: absolute;
              content: "";
              top: 45%;
              left: cal(21, pc);
              border: cal(6, pc) solid transparent;
              border-top: cal(7, pc) solid #6F8293;
            }

            select {
              display: block;
              width: 100%;
              font-size: cal(16, pc);
              color: #6F8293;
              line-height: (30 / 16);
              padding: cal(18, pc);
              padding-left: cal(37, pc);
              border: cal(1, pc) solid #CCCCCC;
              border-radius: cal(5, pc);
              outline: none;

              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;

              &::-ms-expand {
                display: none;
              }
            }
          }

          input[type=text] {
            font-size: cal(16, pc);
            color: #6F8293;
            line-height: (30 / 16);
            box-sizing: border-box;
            width: 100%;
            padding: cal(18, pc);
            border: cal(1, pc) solid #CCCCCC;
            border-radius: cal(5, pc);
            outline: none;
          }
        }

        &__submit {
          &__agree {
            width: cal(212, pc);
            margin: 0 auto;
            margin-bottom: cal(30, pc);

            input[type=checkbox] {
              display: none;
            }

            &__label {
              box-sizing: border-box;
              cursor: pointer;
              display: inline-block;
              position: relative;
              width: auto;
              outline: none;
              font-size: cal(16, pc);
              line-height: (30 / 16);
              color: $text-color;
              padding-left: cal(31, pc);

              &::before {
                background: #fff;
                border: cal(1, pc) solid #DDDDDD;
                border-radius: cal(2, pc);
                content: '';
                display: block;
                height: cal(20, pc);
                left: 0;
                margin-top: cal(-8, pc);
                position: absolute;
                top: 50%;
                width: cal(20, pc);
              }
              &::after {
                border-right: cal(1, pc) solid #231815;
                border-bottom: cal(1, pc) solid #231815;
                content: '';
                display: block;
                height: cal(15, pc);
                left: 3.2%;
                margin-top: cal(-7, pc);
                opacity: 0;
                position: absolute;
                top: 50%;
                transform: rotate(45deg);
                width: cal(8, pc);
              }
            }

            input[type=checkbox]:checked + .jobform__content__submit__agree__label::after {
              opacity: 1;
            }
          }
          &__btn {
            width: cal(200, pc);
            margin: 0 auto;

            a {
              font-size: cal(16, pc);
              line-height: (30 / 16);
              font-weight: $bold;
              color: $color-white;
              text-align: center;
              display: inline-block;
              width: 100%;
              padding: cal(24, pc) 0;
              background: $color-green;
              border-radius: cal(10, pc);
            }
          }
        }
      }
    }
  }
}