#newsrelease {
  .sec {
    .container {
      width: 100%;
      max-width: cal($spsize);
      padding: 0 cal(40);
      margin: 0 auto;
      position: relative;
    }

    &-title {
      font-size: cal(28);
      line-height: (40 / 28);
      font-weight: $bold;
      color: $primary-color;
      margin-bottom: cal(38);
    }
  }

  #head {
    letter-spacing: 0.04em;
    font-weight: $bold;
    padding: cal(104) 0 cal(152);
    background: $color-white;

    .head {
      &__title {
        font-size: cal(36);
        line-height: (54 / 36);
        padding-bottom: cal(12);
        position: relative;

        &::after {
          content: "";
          display: inline-block;
          width: cal(40);
          height: cal(2);
          background: $primary-color;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }

      &__desc {
        font-size: cal(18);
        line-height: (32 / 18);
        margin-top: cal(72);
      }
    }
  }

  #news-head {
    letter-spacing: 0.04em;
    font-weight: $bold;
    padding: cal(104) 0 0;
    background: $color-white;

    .head {
      &__title {
        font-size: cal(32);
        line-height: (48 / 32);
        position: relative;
        margin-bottom: cal(22.6);
      }
    }
  }

  #news {
    padding: 0 0 cal(159.5);
    background: $color-white;

    .news {
      &__list {
        margin-bottom: cal(60);
      }

      &__item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;
        padding: cal(30) cal(17.5);
        border-top: cal(1) solid $border-color;
        position: relative;
        transition: 0.3s ease all;

        &:last-child {
          border-bottom: cal(1) solid $border-color;
        }

        >a {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }

        &__time {
          font-size: cal(20);
          line-height: (24 / 20);
          margin-right: cal(20);
        }

        &__category {
          font-size: cal(16);
          line-height: (24 / 16);
          color: $color-white;
          text-align: center;
          width: cal(120);
          padding: cal(5) 0;
          border-radius: cal(20);

          &.category01 {
            background: $category01-color;
          }

          &.category02 {
            background: $category02-color;
          }

          &.category03 {
            background: $category03-color;
          }
        }

        &__title {
          font-size: cal(20);
          line-height: (30 / 20);
          letter-spacing: 0.04em;
          width: 100%;
          margin-top: cal(5);
        }
      }
    }
  }

  #article {
    padding: 0 0 cal(159.5);
    background: $color-white;

    .article {
      &__list {
        margin-bottom: cal(60);
      }

      &__item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding-bottom: cal(40.3);
        margin-bottom: cal(50);
        border-bottom: cal(1) solid $border-color;
        position: relative;
        transition: 0.3s ease all;

        &__time {
          font-size: cal(16);
          line-height: (24 / 16);
          margin-right: cal(14);
        }

        &__category {
          font-size: cal(12);
          line-height: (24 / 12);
          color: $color-white;
          text-align: center;
          width: cal(90);
          border-radius: cal(12);
          margin-right: cal(22);

          &.category01 {
            //background: $category01-color;
            background: $category-color;
          }

          &.category02 {
            //background: $category02-color;
            background: $category-color;
          }

          &.category03 {
            //background: $category03-color;
            background: $category-color;
          }
        }
      }

      &__content{
        font-size: cal(16);
        line-height: (30 / 16);
        letter-spacing: 0.04em;
        margin-bottom: cal(45);
      }

      &__btn {
        width: cal(280);
        margin: 0 auto;

        a {
          font-size: cal(16);
          line-height: (30 / 16);
          font-weight: $bold;
          color: $color-white;
          text-align: center;
          display: inline-block;
          width: 100%;
          padding: cal(24) 0;
          background: $primary-color;
          position: relative;

          &::after {
            content: "";
            width: cal(18.32);
            height: cal(12.24);
            background: url(../img/common/arrow_right.svg) center / contain no-repeat;
            position: absolute;
            top: 50%;
            right: cal(33.6);
            transform: translateY(-50%);
            z-index: 0;
          }
        }
      }
    }
  }
}