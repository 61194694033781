#service-list {
  .sec {
    .container {
      width: 100%;
      max-width: cal($spsize);
      height: 100%;
      padding: 0 cal(40);
      margin: 0 auto;
      position: relative;
    }

    &-title {
      font-size: cal(32);
      line-height: (40 / 32);
      padding-bottom: cal(20);
      margin-bottom: cal(82);
      position: relative;

      &::after {
        content: "";
        display: inline-block;
        width: cal(40);
        height: cal(2);
        background: $primary-color;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }

  #service {
    padding: cal(103) 0 cal(200);

    .service {
      &__item {
        background: $secondry-background-color;
      }
    }
  }
}

#service-item {
  .sec {
    .container {
      width: 100%;
      max-width: cal($spsize);
      padding: 0 cal(40);
      margin: 0 auto;
      position: relative;
    }

    &-title {
      font-size: cal(28);
      line-height: (40 / 28);
      font-weight: $bold;
      color: $primary-color;
      margin-bottom: cal(38);
    }
  }

  #head {
    letter-spacing: 0.04em;
    font-weight: $bold;
    padding: cal(104) 0 cal(152);
    background: $color-white;

    .head {
      &__title {
        font-size: cal(36);
        line-height: (54 / 36);
        padding-bottom: cal(12);
        position: relative;

        &::after {
          content: "";
          display: inline-block;
          width: cal(40);
          height: cal(2);
          background: $primary-color;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }

      &__desc {
        font-size: cal(18);
        line-height: (32 / 18);
        margin-top: cal(72);
      }
    }
  }

  #business {
    background: $secondry-background-color;
    padding: cal(100) 0 cal(93);
    position: relative;

    .business {
      &__title {}

      &__blocks{
        width: 100%;

        &__block {
          width: 100%;
          height: cal(320);
          background: $background-color;
          padding: cal(54.1) cal(60) 0;
          margin-bottom: cal(40);

          &__title {
            font-size: cal(24);
            line-height: (40 / 24);
            letter-spacing: 0.04em;
            font-weight: $bold;
            padding-bottom: cal(17.7);
            margin-bottom: cal(24.8);
            border-bottom: 1px solid #DDDDDD;
          }

          &__name{
            font-size: cal(20);
            color: $tertiary-text-color;
            line-height: (40 / 20);
            letter-spacing: 0.04em;
            font-weight: $bold;
            margin-bottom: cal(11.3);
          }

          &__desc{
            font-size: cal(16);
            line-height: (30 / 16);
            letter-spacing: 0.04em;
          }
        }

        &__block:first-child {
          margin-right: auto;
        }
      }

      &__list{
        li {
          font-size: cal(16);
          line-height: (30 / 16);
          letter-spacing: 0.04em;
        }
      }
    }
  }

  #uniqueness {
    background: $background-color;
    padding: cal(100) 0 cal(98.3);
    position: relative;

    .uniqueness {
      &__title {}

      &__list{
        li {
          font-size: cal(16);
          line-height: (30 / 16);
          letter-spacing: 0.04em;
        }
      }
    }
  }

  #task {
    background: $secondry-background-color;
    padding: cal(93) 0 cal(82);
    position: relative;

    .task {
      &__title {}

      &__h4 {
        font-size: cal(20);
        line-height: (32 / 20);
        font-weight: $bold;
        margin-bottom: cal(16);
      }

      &__list {
        li {
          font-size: cal(18);
          line-height: (40 / 18);
          font-weight: $medium;
          padding-left: cal(20);
          position: relative;

          &::before {
            content: "";
            display: inline-block;
            width: cal(7);
            height: cal(7);
            background: $primary-color;
            position: absolute;
            left: 0;
            top: cal(16.5);
          }
        }
      }
    }
  }

  #offer {
    background: $color-white;
    padding: cal(96.5) 0 cal(76);
    position: relative;

    .offer {
      &__title {}

      &__list {
        letter-spacing: 0.04em;

        &:nth-child(n + 2) {
          margin-top: cal(38);
        }

        &__title {
          font-size: cal(20);
          line-height: (28 / 20);
          font-weight: $bold;
          color: $primary-color;
          margin-bottom: cal(22);
        }

        li {
          font-size: cal(18);
          line-height: (40 / 18);
          font-weight: $medium;
          padding-left: cal(20);
          position: relative;

          &::before {
            content: "";
            display: inline-block;
            width: cal(7);
            height: cal(7);
            background: $primary-color;
            position: absolute;
            left: 0;
            top: cal(16.5);
          }
        }
      }
    }
  }

  #feature {
    background: $secondry-background-color;
    padding: cal(93) 0 cal(82);
    position: relative;

    .feature {
      &__title {}

      &__blocks{
        width: 100%;

        &__block {
          width: 100%;
          height: cal(433);
          background: $background-color;
          padding: cal(89) cal(17) 0;
          margin-bottom: cal(30);

          &__img{
            width: cal(133.42);
            margin: 0 auto;
            margin-bottom: cal(70.8);
          }

          &__sub{
            display:table;
            font-size: cal(16);
            line-height: (36 / 18);
            letter-spacing: 0.04em;
            margin: 0 auto;
          }

          &__title{
            display:table;
            font-size: cal(24);
            line-height: (36 / 24);
            font-weight: $bold;
            color: $primary-color;
            margin: cal(18.3) auto;
          }

          &__desc{
            display:table;
            font-size: cal(15);
            line-height: (28 / 15);
            margin: 0 auto;
          }
        }

        &__block:nth-child(n+2) {
          padding: cal(56) cal(17) 0;

          .feature__blocks__block__img{
            width: cal(111.21);
            margin-bottom: cal(37.4);
          }
        }
      }

      &__list {
        li {
          font-size: cal(18);
          line-height: (40 / 18);
          font-weight: $medium;
          padding-left: cal(20);
          position: relative;

          &::before {
            content: "";
            display: inline-block;
            width: cal(7);
            height: cal(7);
            background: $primary-color;
            position: absolute;
            left: 0;
            top: cal(16.5);
          }
        }
      }
    }
  }

  #case {
    background: $secondry-background-color;
    padding: cal(93) 0 cal(82);
    position: relative;

    .case {
      &__title {}

      &__block {
        background: $background-color;
        padding: cal(50) cal(60) cal(44);
        margin-bottom: cal(40);

        &__title {
          font-size: cal(24);
          line-height: (40 / 24);
          letter-spacing: 0.04em;
          font-weight: $bold;
          padding-bottom: cal(17.7);
          margin-bottom: cal(24.8);
          border-bottom: 1px solid #DDDDDD;
        }

        &__lists{
          h5 {
            font-size: cal(20);
            line-height: (40 / 20);
            font-weight: $bold;
            color: $tertiary-text-color;
          }

          &__ul {
            li {
              font-size: cal(18);
              line-height: (40 / 18);
              font-weight: $medium;
              padding-left: cal(20);
              position: relative;

              &::before {
                content: "";
                display: inline-block;
                width: cal(7);
                height: cal(7);
                background: $primary-color;
                position: absolute;
                left: 0;
                top: cal(16.5);
              }
            }
          }
        }

        &__lists:nth-child(2){
          margin-bottom: cal(19.6);
        }
      }
    }
  }

  #margin {
    background: $background-color;
    padding-top: cal(41);
    position: relative;
  }

  #flow {
    background: $color-white;
    padding: cal(100) 0;
    position: relative;

    .flow {
      &__title {}

      &__desc {
        font-size: cal(16);
        line-height: (30 / 16);
        letter-spacing: 0.04em;
        font-weight: $medium;
        margin-bottom: cal(54);
      }


      &__contents {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
      }

      &__item {
        width: cal(300);
        height: cal(200);
        background: $secondry-background-color;
        position: relative;

        &:nth-child(n + 2) {
          margin-top: cal(10);
        }

        &:last-child {
          &::before,
          &::after {
            content: none;
          }

          .flow__item__wrapper {
            margin-top: 0;
          }
        }

        &::before,
        &::after {
          content: "";
          width: cal(150);
          height: cal(50);
          display: inline-block;
          position: absolute;
          bottom: 0;
        }

        &::before {
          left: 0;
          border-top: cal(25) solid transparent;
          border-right: cal(75) solid transparent;
          border-bottom: cal(25) solid $color-white;
          border-left: cal(75) solid $color-white;
        }

        &::after {
          right: 0;
          border-top: cal(25) solid transparent;
          border-right: cal(75) solid $color-white;
          border-bottom: cal(25) solid $color-white;
          border-left: cal(75) solid transparent;
        }

        &__wrapper {
          letter-spacing: 0.04em;
          text-align: center;
          white-space: nowrap;
          margin-top: cal(-18);
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 2;
          transform: translate(-50%, -50%);
        }

        &__number {
          font-size: cal(14);
          line-height: (17 / 14);
          font-weight: $semibold;
          color: $primary-color;
          margin-bottom: cal(9);
        }

        &__text {
          font-size: cal(16);
          line-height: (24 / 16);
          font-weight: $bold;
        }
      }
    }
  }

  #suppliers {
    background: $secondry-background-color;
    padding: cal(100) 0 cal(100);
    position: relative;

    .suppliers{
      &__title {}

      &__list{
        width: 100%;

        li{
          width: cal(200);
          height: cal(200);
          background: $background-color;
          padding-top: cal(89);
          margin: cal(20) auto;

          img{
            width: cal(150.93);
          }
        }
      }
    }
  }

  #contact {
    padding: cal(90) 0 cal(128);
    background: $secondry-background-color;
    position: relative;

    .contact {
      &__title {
        font-size: cal(28);
        line-height: (40 / 28);
        font-weight: $bold;
        color: $primary-color;
        text-align: center;
        margin-bottom: cal(58);
      }

      &__btn {
        @include ButtonSP;
      }
    }
  }

  #links {
    padding: cal(92) 0 cal(122);
    background: $color-white;
    position: relative;

    .links {
      &__title {
        font-size: cal(28);
        line-height: (68 / 28);
        letter-spacing: 0.04em;
        font-weight: $bold;
        color: $primary-color;
        text-align: center;
        border: cal(1) solid currentColor;
        margin-bottom: cal(48);
      }

      &__block {
        &:nth-child(n + 2) {
          margin-top: cal(70.5);
        }

        &__title {
          font-size: cal(30);
          line-height: (36 / 30);
          letter-spacing: 0.04em;
          font-weight: $bold;
          padding-left: cal(40);
          margin-bottom: cal(45);
          position: relative;

          &::before {
            content: "";
            width: cal(25);
            height: cal(2);
            background: currentColor;
            position: absolute;
            top: cal(17);
            left: 0;
          }
        }
      }

      &__item {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        width: 100%;
        min-height: cal(200);
        background: $secondry-background-color;
        position: relative;

        >a {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }

        &:nth-child(n + 2) {
          margin-top: cal(30);
        }

        &__img {
          width: cal(200);
          height: cal(200);
        }

        &__content {
          letter-spacing: 0.04em;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex: 1;
          padding: 0 cal(38);
        }

        &__title {
          font-size: cal(25);
          line-height: (36 / 25);
          font-weight: $bold;
          color: $primary-color;
        }

        &__desc {
          font-size: cal(20);
          line-height: (28 / 20);
          color: $primary-color;
          margin-bottom: cal(8);
        }
      }
    }
  }


  #call-back {
    padding: 0 0 cal(122);
    background: $color-white;
    position: relative;

    .call-back {
      &__blocks {
        &__block {
          width: 100%;
          margin-bottom: cal(60);

          &__img {
            width: 100%;
            margin-bottom: cal(26);
          }

          &__title{
            font-size: cal(24);
            line-height: (24 / 24);
            letter-spacing: 0.04em;
            font-weight: $bold;
            color: $primary-color;
            margin-bottom: cal(14.8);
          }

          &__desc{
            font-size: cal(15);
            line-height: (24 / 15);
            letter-spacing: 0.04em;
          }
        }
      }
    }
  }
}