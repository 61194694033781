/*-------------------------------------------------

  web font
  ウェブフォントの読み込みはここに記載
  
  -------------------------------------------------*/
  @import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,400;0,500;0,600;1,600&family=Noto+Sans+JP:wght@400;500;700&display=swap');
  /*-------------------------------------------------
  
  FONT FAMILY
  フォントファミリーはここで変数化

-------------------------------------------------*/
$primary-font: 'Noto Sans JP', YuGothic,'Yu Gothic',sans-serif;
$secondry-font: 'Barlow',sans-serif;

/*-------------------------------------------------

  FONT WEIGHT
  各種フォントウェイトはこの変数を使用

-------------------------------------------------*/
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$ex-bold: 800;
$black: 900;
