header {
  letter-spacing: 0.04em;
  width: 100%;
  height: cal(100);
  background: $color-white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;

  .header {
    &__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;
      background: $color-white;
      margin: 0 auto;
      position: relative;
      z-index: 10001;
    }

    &__logo {
      width: cal(284.85);
      margin-top: cal(-4);
      margin-left: cal(35);

      img {
        margin: 0;
      }

      span {
        display: none;
      }
    }

    &__btns {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      position: absolute;
      right: 0;
      top: 0;

      &__item {
        font-size: cal(15);
        line-height: (24 / 15);
        font-weight: $bold;
        color: $color-white;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        width: cal(120);
        height: cal(100);

        &.recruit {
          a {
            background: $header-recruit-color;
          }
        }

        &.hamburger {
          background: $primary-color;

          .hamburger__inner {
            width: cal(40);
            height: cal(26);
            position: relative;
          }

          span {
            display: inline-block;
            width: cal(40);
            height: cal(2);
            background: $color-white;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            transition: 0.3s ease all;

            &:nth-child(1) {
              top: 0;
            }

            &:nth-child(2) {
              top: 50%;
              transform: translate(-50%, -50%);
            }

            &:nth-child(3) {
              top: calc(100% - #{cal(2)});
            }
          }


          &.open {
            span {
              &:nth-child(1) {
                top: 50%;
                transform: translate(-50%, -50%) rotate(135deg);
              }

              &:nth-child(2) {
                width: 0;
              }

              &:nth-child(3) {
                top: 50%;
                transform: translate(-50%, -50%)  rotate(-135deg);
              }
            }
          }
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        }
      }
    }

    &__menu {
      width: 100%;
      height: 0;
      background: $secondry-background-color;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: -1;
      transition: 0.5s ease opacity, 0.5s ease height, 0s 0.5s visibility;
      visibility: hidden;
      opacity: 0;
      overflow: hidden;

      &.open {
        visibility: visible;
        opacity: 1;
        transition-delay: 0s;
      }

      &__list {
        padding: cal(108) cal(80);
      }

      &__item {
        font-size: cal(30);
        line-height: (40 / 30);
        letter-spacing: 0.04em;
        font-weight: $medium;

        &+.header__menu__item {
          margin-top: cal(110);
        }
      }
    }

  }
}