#kv {
  &.kv-common {
    color: $color-white;
    width: 100%;
    height: cal(340);
    position: relative;

    .container {
      width: 100%;
      max-width: cal($spsize);
      height: 100%;
      padding: 0 cal(40);
      margin: 0 auto;
      position: relative;
    }

    .kv {
      &__bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__title {
        white-space: nowrap;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        h1 {
          font-size: cal(48);
          line-height: (70 / 48);
          letter-spacing: 0.04em;
          font-weight: $bold;
          margin-bottom: cal(14.5);
        }

        p {
          font-size: cal(20);
          line-height: (29 / 20);
          letter-spacing: 0.04em;
          font-weight: $bold;
        }
      }

      &__breadcrumb {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        position: absolute;
        left: cal(40);
        bottom: cal(32);

        li {
          font-size: cal(14);
          line-height: (20 / 14);
          letter-spacing: 0.04em;
          margin-right: cal(28);
          position: relative;

          &:last-child {
            &::after {
              content: none;
            }
          }

          &::after {
            content: "";
            width: cal(10);
            height: cal(1);
            background: currentColor;
            position: absolute;
            top: 55%;
            left: calc(100% + #{cal(9)});
            transform: translateY(-50%) rotate(-45deg);
          }
        }
      }
    }
  }
}

#top,
#service-list {
  #service {
    .service {
      &__title {
        margin-bottom: cal(75);
      }

      &__contents {
        margin-bottom: cal(80);
      }

      &__block {
        &:nth-child(n + 2) {
          margin-top: cal(70.5);
        }

        &__title {
          font-size: cal(30);
          line-height: (36 / 30);
          letter-spacing: 0.04em;
          font-weight: $bold;
          padding-left: cal(40);
          margin-bottom: cal(45);
          position: relative;

          &::before {
            content: "";
            width: cal(25);
            height: cal(2);
            background: currentColor;
            position: absolute;
            top: cal(17);
            left: 0;
          }
        }
      }

      &__item {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        min-height: cal(200);
        background: $color-white;
        position: relative;

        >a {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }

        &:nth-child(n + 2) {
          margin-top: cal(30);
        }

        &__img {
          width: cal(200);
          height: cal(200);
        }

        &__content {
          letter-spacing: 0.04em;
          flex: 1;
          padding: cal(15) cal(28) 0 cal(40);
        }

        &__title {
          font-size: cal(25);
          line-height: (36 / 25);
          font-weight: $bold;
          color: $primary-color;
        }

        &__desc {
          font-size: cal(20);
          line-height: (28 / 20);
          color: $primary-color;
          // min-height: cal(28);
          margin-bottom: cal(8);
        }

        &__text {
          font-size: cal(20);
          line-height: (28 / 20);
        }
      }

      &__btn {
        @include ButtonSP;
      }
    }
  }
}