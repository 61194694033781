footer {
  color: $footer-text-color;
  background: $footer-background-color;
  padding: cal(142) 0 cal(138);

  .footer {
    &__inner {
      max-width: cal($spsize);
      padding: 0 cal(28);
      margin: 0 auto;
    }

    &__nav {
      margin-bottom: cal(120);
    }

    &__sec {
      width: calc(50% - #{cal(20)});
      padding-top: cal(14);

      &:nth-child(odd) {
        float: left;
      }

      &:nth-child(even) {
        float: right;
      }

      &:nth-child(n + 3) {
        margin-top: cal(50);
      }

      &__title {
        font-size: cal(20);
        line-height: (28 / 20);
        font-weight: $bold;
        color: $color-white;
        display: inline-block;
        padding-bottom: cal(17);
        position: relative;

        &::after {
          content: "";
          display: inline-block;
          width: cal(20);
          height: 1px;
          background: $footer-border-color;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }

      &__block {
        &+.footer__sec__block {
          margin-top: cal(78);
        }
      }
    }

    &__bottoms {
      padding-top: cal(104);
      clear: both;

      .footer {
        &__list {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;

          &__item {
            width: calc(50% - #{cal(20)});
          }
        }
      }
    }

    &__menus {
      font-size: cal(18);
      line-height: (48 / 18);
      margin-top: cal(34);

      p {
        font-weight: $bold;
      }
    }

    &__list {
      &+.footer__list {
        margin-top: cal(18);
      }
    }

    &__logo {
      width: cal(280.95);
      margin-bottom: cal(45);
    }


    &__copy {
      font-size: cal(18);
      line-height: (24.5 / 18);
      font-weight: $medium;
      text-align: center;
    }
  }
}
