#other-pages {
  .sec {
    .container {
      width: 100%;
      max-width: cal($spsize);
      height: 100%;
      padding: 0 cal(40);
      margin: 0 auto;
      position: relative;
    }

    &-title {
      font-size: cal(32);
      line-height: (40 / 32);
      padding-bottom: cal(20);
      margin-bottom: cal(82);
      position: relative;

      &::after {
        content: "";
        display: inline-block;
        width: cal(40);
        height: cal(2);
        background: $primary-color;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }

  #other {
    padding: cal(103.5) 0 cal(206);

    .other {
      &__contents {}

      &__section {
        font-size: cal(16);
        line-height: (30 / 16);
        letter-spacing: 0.04em;

        +.other__section {
          margin-top: cal(80);
        }

        &.right {
          text-align: right;
        }

        .box {
          margin-top: cal(30);

          &+.box {
            margin-top: cal(60);
          }
        }

        .list {
          margin-top: cal(30);

        }

        .margin-list {
          >li {
            &+li {
              margin-top: cal(30);
            }
          }
        }

        p {
          &+.paragraph {
            margin-top: cal(30);
          }

          &.bold {
            font-weight: $bold;
          }
        }

        &__sitemap {
          &__title {
            font-size: cal(18);
            line-height: (40 / 18);
            color: $tertiary-text-color;
            margin-bottom: cal(18.3);
          }

          &__list {
            font-size: cal(18);
            line-height: (40 / 18);
            margin-bottom: cal(40);

            ul {
              li {
                position: relative;
                padding-left: cal(20);

                &::before {
                  position: absolute;
                  content: "";
                  width: cal(7);
                  height: cal(7);
                  top: cal(17);
                  left: 0;
                  vertical-align: top;
                  background-color: $primary-color;
                }
              }
            }
          }
        }

        .top_margin {
          margin-bottom: cal(47.4) !important;
        }

        .last_margin {
          margin-bottom: cal(100) !important;
        }
      }
    }

    .fotFound {
      &__title {
        font-size: cal(64);
        line-height: (92 / 64);
        letter-spacing: 0.04em;
        font-weight: $bold;
        text-align: center;
      }

      &__desc {
        font-size: cal(30);
        line-height: (44 / 30);
        letter-spacing: 0.04em;
        font-weight: $bold;
        text-align: center;
        margin-bottom: cal(78.5);
      }

      &__btn {
        margin: 0 auto;
        @include ButtonSP();
      }
    }
  }
}