#service-list {
  .sec {
    .container {
      width: 100%;
      max-width: cal($pcsize, pc);
      padding: 0 cal(180, pc);
      margin: 0 auto;
      position: relative;
    }

    &-title {
      font-size: cal(32, pc);
      line-height: (40 / 32);
      padding-bottom: cal(20, pc);
      margin-bottom: cal(82, pc);
      position: relative;

      &::after {
        content: "";
        display: inline-block;
        width: cal(40, pc);
        height: cal(2, pc);
        background: $primary-color;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }

  #service {
    padding: cal(103, pc) 0 cal(200, pc);

    .service {
      &__item {
        background: $secondry-background-color;
      }
    }
  }
}

#service-item {
  .sec {
    .container {
      width: 100%;
      max-width: cal($pcsize, pc);
      padding: 0 cal(180, pc);
      margin: 0 auto;
      position: relative;
    }

    &-title {
      font-size: cal(28, pc);
      line-height: (40 / 28);
      font-weight: $bold;
      color: $primary-color;
      margin-bottom: cal(26, pc);
    }
  }

  #head {
    letter-spacing: 0.04em;
    font-weight: $bold;
    padding: cal(104, pc) 0 cal(152, pc);
    background: $color-white;

    .head {
      &__title {
        font-size: cal(36, pc);
        line-height: (54 / 36);
        padding-bottom: cal(12, pc);
        position: relative;

        &::after {
          content: "";
          display: inline-block;
          width: cal(40, pc);
          height: cal(2, pc);
          background: $primary-color;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }

      &__desc {
        font-size: cal(18, pc);
        line-height: (32 / 18);
        margin-top: cal(72, pc);
      }
    }
  }

  #business {
    background: $secondry-background-color;
    padding: cal(100, pc) 0 cal(93, pc);
    position: relative;

    .business {
      &__title {}

      &__blocks{
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;

        &__block {
          width: cal(580, pc);
          height: cal(320, pc);
          background: $background-color;
          padding: cal(54.1, pc) cal(60, pc) 0;
          margin-bottom: cal(40, pc);

          &__title {
            font-size: cal(24, pc);
            line-height: (40 / 24);
            letter-spacing: 0.04em;
            font-weight: $bold;
            padding-bottom: cal(17.7, pc);
            margin-bottom: cal(24.8, pc);
            border-bottom: 1px solid #DDDDDD;
          }

          &__name{
            font-size: cal(20, pc);
            color: $tertiary-text-color;
            line-height: (40 / 20);
            letter-spacing: 0.04em;
            font-weight: $bold;
            margin-bottom: cal(11.3, pc);
          }

          &__desc{
            font-size: cal(16, pc);
            line-height: (30 / 16);
            letter-spacing: 0.04em;
          }
        }

        &__block:first-child {
          margin-right: auto;
        }
      }

      &__list{
        li {
          font-size: cal(16, pc);
          line-height: (30 / 16);
          letter-spacing: 0.04em;
        }
      }
    }
  }

  #uniqueness {
    background: $background-color;
    padding: cal(100, pc) 0 cal(98.3, pc);
    position: relative;

    .uniqueness {
      &__title {}

      &__list{
        li {
          font-size: cal(16, pc);
          line-height: (30 / 16);
          letter-spacing: 0.04em;
        }
      }
    }
  }

  #task {
    background: $secondry-background-color;
    padding: cal(93, pc) 0 cal(82, pc);
    position: relative;

    .task {
      &__title {}

      &__h4 {
        font-size: cal(20, pc);
        line-height: (32 / 20);
        font-weight: $bold;
        margin-bottom: cal(16, pc);
      }

      &__list {
        li {
          font-size: cal(18, pc);
          line-height: (40 / 18);
          font-weight: $medium;
          padding-left: cal(20, pc);
          position: relative;

          &::before {
            content: "";
            display: inline-block;
            width: cal(7, pc);
            height: cal(7, pc);
            background: $primary-color;
            position: absolute;
            left: 0;
            top: cal(16.5, pc);
          }
        }
      }
    }
  }

  #offer {
    background: $color-white;
    padding: cal(96.5, pc) 0 cal(76, pc);
    position: relative;

    .offer {
      &__title {}

      &__contents {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
      }

      &__list {
        letter-spacing: 0.04em;
        width: calc(100% / 3);
        padding-right: cal(52, pc);

        &.only {
          width: 100%;
        }

        &:nth-child(3n) {
          padding-right: 0;
        }

        &__title {
          font-size: cal(20, pc);
          line-height: (28 / 20);
          font-weight: $bold;
          color: $primary-color;
          margin-bottom: cal(22, pc);
        }

        li {
          font-size: cal(18, pc);
          line-height: (40 / 18);
          font-weight: $medium;
          padding-left: cal(20, pc);
          position: relative;

          &::before {
            content: "";
            display: inline-block;
            width: cal(7, pc);
            height: cal(7, pc);
            background: $primary-color;
            position: absolute;
            left: 0;
            top: cal(16.5, pc);
          }
        }
      }

      &__list:nth-child(n + 4) {
        margin-top: cal(31, pc);

      }
    }
  }

  #feature {
    background: $secondry-background-color;
    padding: cal(93, pc) 0 cal(82, pc);
    position: relative;

    .feature {
      &__title {}

      &__blocks{
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        &__block {
          width: cal(380, pc);
          height: cal(433, pc);
          background: $background-color;
          padding: cal(89, pc) cal(17, pc) 0;
          margin-right: auto;

          &__img{
            width: cal(133.42, pc);
            margin: 0 auto;
            margin-bottom: cal(70.8, pc);
          }

          &__sub{
            display:table;
            font-size: cal(16, pc);
            line-height: (36 / 18);
            letter-spacing: 0.04em;
            margin: 0 auto;
          }

          &__title{
            display:table;
            font-size: cal(24, pc);
            line-height: (36 / 24);
            font-weight: $bold;
            color: $primary-color;
            margin: cal(18.3, pc) auto;
          }

          &__desc{
            display:table;
            font-size: cal(15, pc);
            line-height: (28 / 15);
            margin: 0 auto;
          }


        }

        &__block:nth-child(n+2) {
          padding: cal(56, pc) cal(17, pc) 0;

          .feature__blocks__block__img{
            width: cal(111.21, pc);
            margin-bottom: cal(37.4, pc);
          }
        }

        &__block:last-child {
          margin-right: 0;
        }
      }


      &__list {
        li {
          font-size: cal(18, pc);
          line-height: (40 / 18);
          font-weight: $medium;
          padding-left: cal(20, pc);
          position: relative;

          &::before {
            content: "";
            display: inline-block;
            width: cal(7, pc);
            height: cal(7, pc);
            background: $primary-color;
            position: absolute;
            left: 0;
            top: cal(16.5, pc);
          }
        }
      }
    }
  }

  #flow {
    background: $color-white;
    padding: cal(100, pc) 0;
    position: relative;

    .flow {
      &__title {}

      &__desc {
        font-size: cal(16, pc);
        line-height: (30 / 16);
        letter-spacing: 0.04em;
        font-weight: $medium;
        margin-bottom: cal(54, pc);
      }

      &__contents {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
      }

      &__item {
        height: cal(150, pc);
        flex: 1;
        background: $secondry-background-color;
        position: relative;

        &:nth-child(n + 2) {
          margin-left: cal(10, pc);
        }

        &:last-child {
          &::before,
          &::after {
            content: none;
          }

          .flow__item__wrapper {
            margin-left: 0;
          }
        }

        &::before,
        &::after {
          content: "";
          width: cal(32, pc);
          height: cal(75, pc);
          display: inline-block;
          position: absolute;
          right: 0;
        }

        &::before {
          top: 0;
          border-top: cal(37.5, pc) solid $color-white;
          border-right: cal(16, pc) solid $color-white;
          border-bottom: cal(37.5, pc) solid transparent;
          border-left: cal(16, pc) solid transparent;
        }
        
        &::after {
          bottom: 0;
          border-top: cal(37.5, pc) solid transparent;
          border-right: cal(16, pc) solid $color-white;
          border-bottom: cal(37.5, pc) solid $color-white;
          border-left: cal(16, pc) solid transparent;
        }

        &__wrapper {
          letter-spacing: 0.04em;
          text-align: center;
          white-space: nowrap;
          margin-left: cal(-10, pc);
          position: absolute;
          top: cal(28, pc);
          left: 50%;
          z-index: 2;
          transform: translateX(-50%);
        }

        &__number {
          font-size: cal(14, pc);
          line-height: (17 / 14);
          font-weight: $semibold;
          color: $primary-color;
          margin-bottom: cal(9, pc);
        }

        &__text {
          font-size: cal(16, pc);
          line-height: (24 / 16);
          font-weight: $bold;
        }
      }
    }
  }

  #case {
    background: $secondry-background-color;
    padding: cal(93, pc) 0 cal(82, pc);
    position: relative;

    .case {
      &__title {}

      &__block {
        background: $background-color;
        padding: cal(50, pc) cal(60, pc) cal(44, pc);
        margin-bottom: cal(40, pc);

        &__title {
          font-size: cal(24, pc);
          line-height: (40 / 24);
          letter-spacing: 0.04em;
          font-weight: $bold;
          padding-bottom: cal(17.7, pc);
          margin-bottom: cal(24.8, pc);
          border-bottom: 1px solid #DDDDDD;
        }

        &__lists{
          h5 {
            font-size: cal(20, pc);
            line-height: (40 / 20);
            font-weight: $bold;
            color: $tertiary-text-color;
          }

          &__ul {
            li {
              font-size: cal(18, pc);
              line-height: (40 / 18);
              font-weight: $medium;
              padding-left: cal(20, pc);
              position: relative;

              &::before {
                content: "";
                display: inline-block;
                width: cal(7, pc);
                height: cal(7, pc);
                background: $primary-color;
                position: absolute;
                left: 0;
                top: cal(16.5, pc);
              }
            }
          }
        }

        &__lists:nth-child(2){
          margin-bottom: cal(19.6, pc);
        }
      }
    }
  }

  #margin {
    background: $background-color;
    padding-top: cal(41, pc);
    position: relative;
  }

  #suppliers {
    background: $secondry-background-color;
    padding: cal(100, pc) 0 cal(100, pc);
    position: relative;

    .suppliers{
      &__title {}

      &__list{
        width: 100%;
        display: flex;
        align-items: stretch;
        justify-content: space-between;

        li{
          width: cal(200, pc);
          height: cal(200, pc);
          background: $background-color;
          padding-top: cal(89, pc);


          img{
            width: cal(150.93, pc);
          }
        }
      }
    }
  }

  #contact {
    padding: cal(90, pc) 0 cal(128, pc);
    background: $secondry-background-color;
    position: relative;

    .contact {
      &__title {
        font-size: cal(28, pc);
        line-height: (40 / 28);
        font-weight: $bold;
        color: $primary-color;
        text-align: center;
        margin-bottom: cal(58, pc);
      }

      &__btn {
        width: cal(640, pc);
        margin: 0 auto;

        a {
          font-size: cal(20, pc);
          line-height: (28 / 20);
          font-weight: $bold;
          color: $color-white;
          text-align: center;
          display: inline-block;
          width: 100%;
          padding: cal(46, pc) 0;
          background: $primary-color;
          position: relative;
      
          &::after {
            content: "";
            width: cal(18.32, pc);
            height: cal(12.24, pc);
            background: url(../img/common/arrow_right.svg) center / contain no-repeat;
            position: absolute;
            top: 50%;
            right: cal(33.6, pc);
            transform: translateY(-50%);
            z-index: 0;
          }
        }
      }
    }
  }

  #links {
    padding: cal(92, pc) 0 cal(122, pc);
    background: $color-white;
    position: relative;
    
    .links {
      &__title {
        font-size: cal(28, pc);
        line-height: (68 / 28);
        letter-spacing: 0.04em;
        font-weight: $bold;
        color: $primary-color;
        text-align: center;
        border: cal(1, pc) solid currentColor;
        margin-bottom: cal(48, pc);
      }

      
      &__contents {
        margin-bottom: cal(80, pc);
      }

      &__block {
        &:nth-child(n + 2) {
          margin-top: cal(38, pc);
        }

        &__title {
          font-size: cal(20, pc);
          line-height: (30 / 20);
          letter-spacing: 0.04em;
          font-weight: $bold;
          padding-left: cal(30, pc);
          margin-bottom: cal(36, pc);
          position: relative;

          &::before {
            content: "";
            width: cal(20, pc);
            height: cal(2, pc);
            background: currentColor;
            position: absolute;
            top: cal(14, pc);
            left: 0;
          }
        }
      }

      &__list {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      &__item {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        width: calc(50% - #{cal(20, pc)});
        min-height: cal(120, pc);
        background: $secondry-background-color;
        position: relative;
        transition: 0.3s ease all;

        &:hover {
          opacity: 0.7;
        }

        >a {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }

        &:nth-child(n + 3) {
          margin-top: cal(20, pc);
        }

        &__img {
          width: cal(120, pc);
          height: cal(120, pc);
        }

        &__content {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex: 1;
          padding: 0 cal(38, pc);
        }

        &__title {
          font-size: cal(20, pc);
          line-height: (24 / 20);
          letter-spacing: 0.04em;
          font-weight: $bold;
          color: $primary-color;
          margin-bottom: cal(7.5, pc);
        }

        &__desc {
          font-size: cal(15, pc);
          line-height: (21 / 15);
          letter-spacing: 0.04em;
          color: $primary-color;
        }
      }
    }
  }


  #call-back {
    padding: 0 0 cal(122, pc);
    background: $color-white;
    position: relative;

    .call-back {
      &__blocks {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        &__block {
          width: cal(600, pc);

          &__img {
            width: 100%;
            margin-bottom: cal(26, pc);
          }

          &__title{
            font-size: cal(24, pc);
            line-height: (24 / 24);
            letter-spacing: 0.04em;
            font-weight: $bold;
            color: $primary-color;
            margin-bottom: cal(14.8, pc);
          }

          &__desc{
            font-size: cal(15, pc);
            line-height: (24 / 15);
            letter-spacing: 0.04em;
          }
        }
      }
    }
  }
}