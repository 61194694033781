#top {
  .sec {
    .container {
      width: 100%;
      max-width: cal($pcsize, pc);
      padding: 0 cal(180, pc);
      margin: 0 auto;
    }

    &-title {
      h2 {
        font-size: cal(96, pc);
        line-height: (115 / 96);
        font-weight: $semibold;
        color: $primary-color;
        margin-bottom: cal(17.5, pc);
      }

      p {
        font-size: cal(18, pc);
        line-height: (26 / 18);
        letter-spacing: 0.04em;
        font-weight: $medium;
        color: $desc-color;
      }
    }
  }

  #kv {
    width: 100%;
    height: cal(680, pc);
    position: relative;

    .container {
      width: 100%;
      max-width: cal($pcsize, pc);
      height: 100%;
      padding: 0 cal(180, pc);
      margin: 0 auto;
      position: relative;
    }

    .kv {
      &__bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__text {
        color: $color-white;
        position: absolute;
        top: 50%;
        right: cal(180, pc);
        z-index: 1;
        transform: translateY(30px);
        opacity: 0;
        visibility: hidden;
        transition: 1s ease opacity, 1s ease transform, 0s 0s visibility;
        
        &.loaded {
          opacity: 1;
          transform: translateY(-50%);
          visibility: visible;
          transition-delay: 0s;
        }

        h1 {
          font-size: cal(80, pc);
          line-height: (87 / 80);
          font-weight: $semibold;
          margin-bottom: cal(30, pc);
        }

        p {
          font-size: cal(20, pc);
          line-height: (28 / 20);
          letter-spacing: 0.04em;
          font-weight: $bold;
        }
      }

      &__scroll {
        color: $color-white;
        width: cal(140, pc);
        position: absolute;
        right: cal(147.5, pc);
        bottom: 0;
        z-index: 1;
        transform: rotate(90deg);
        transform-origin: right bottom;

        &::after{
          content: "";
          width: 100%;
          height: 1px;
          background: currentColor;
          position: absolute;
          bottom: 0;
          left: 0;
        }

        p {
          font-family: $secondry-font;
          font-size: cal(16, pc);
          line-height: (20 / 16);
          color: currentColor;
          font-weight: $semibold;
          margin-bottom: cal(8.5, pc);
        }
      }
    }

  }

  #about {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background: $color-white;
    padding: cal(160, pc) 0;
    position: relative;

    .container {
      width: 50%;
      max-width: cal(800, pc);
      padding: 0 cal(180, pc) 0 0;
      margin: 0 auto 0 0;
    }

    .about {
      &__img {
        width: 50%;
        height: cal(620, pc);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__wrapper {
        padding-left: cal(100, pc);
      }

      &__title {
        margin-bottom: cal(70, pc);
      }

      &__texts {
        letter-spacing: 0.04em;
        margin-bottom: cal(50.5, pc);

        &__heading {
          font-size: cal(24, pc);
          line-height: (32 / 24);
          font-weight: $bold;
          margin-bottom: cal(28, pc);
        }

        &__desc {
          font-size: cal(16, pc);
          line-height: (30 / 16);
        }
      }

      &__btn {
        @include Button;
      }
    }
  }

  #service {
    padding: cal(134.5, pc) 0 cal(159.5, pc);
    background: $secondry-background-color;
  }

  #news {
    padding: cal(134.5, pc) 0 cal(159.5, pc);
    background: $color-white;

    .news {
      &__title {
        margin-bottom: cal(70, pc);
      }

      &__list {
        margin-bottom: cal(60, pc);
      }

      &__item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding: cal(38, pc) cal(20, pc);
        border-top: cal(1, pc) solid $border-color;
        position: relative;
        transition: 0.3s ease all;

        &:hover {
          background: $secondry-background-color;
          opacity: 0.7;
        }

        &:last-child {
          border-bottom: cal(1, pc) solid $border-color;
        }

        >a {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }

        &__time {
          font-size: cal(16, pc);
          line-height: (24 / 16);
          margin-right: cal(19, pc);
        }

        &__category {
          font-size: cal(12, pc);
          line-height: (24 / 12);
          color: $color-white;
          text-align: center;
          width: cal(90, pc);
          border-radius: cal(12, pc);
          margin-right: cal(22, pc);

          &.category01 {
            background: $category01-color;
          }

          &.category02 {
            background: $category02-color;
          }

          &.category03 {
            background: $category03-color;
          }
        }

        &__title {
          font-size: cal(16, pc);
          line-height: (24 / 16);
          letter-spacing: 0.04em;
          flex: 1;
        }
      }

      &__btn {
        margin: 0 auto;
        @include Button;
      }
    }
  }
}