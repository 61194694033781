#banner {
  padding: cal(105, pc) 0 cal(120, pc);
  background: $secondry-background-color;


  .container {
    width: 100%;
    max-width: cal($pcsize, pc);
    padding: 0 cal(180, pc);
    margin: 0 auto;
  }

  .banner {
    &__title {
      font-size: cal(16, pc);
      line-height: (24 / 16);
      font-weight: $bold;
      margin-bottom: cal(32, pc);
    }

    &__content {
      margin-bottom: cal(60, pc);
    }

    &__block {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      &:nth-child(n + 2) {
        margin-top: cal(16, pc);
      }

      &__title {
        font-size: cal(12, pc);
        line-height: (24 / 12);
        letter-spacing: 0.04em;
        width: cal(108, pc);
      }

      &__list {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        flex: 1;
      }
    }

    &__item {
      font-size: cal(12, pc);
      line-height: (24 / 12);
      letter-spacing: 0.04em;
      color: $primary-color;
      padding: 0 cal(12, pc);
      position: relative;

      &::after {
        content: "|";
        position: absolute;
        right: 0;
        top: 0;
      }

      &:last-child {
        &::after {
          content: none;
        }
      }

      a {
        display: inline-block;
        text-decoration: underline;
      }
    }

    &__images {
      display: flex;
      align-items: center;
      justify-content: center;

      &__item {
        width: cal(240, pc);
        margin: 0 cal(20, pc);
      }
    }
  }
}