#top {
  .sec {
    .container {
      width: 100%;
      max-width: cal($spsize);
      padding: 0 cal(40);
      margin: 0 auto;
    }

    &-title {
      h2 {
        font-size: cal(96);
        line-height: (115 / 96);
        font-weight: $semibold;
        color: $primary-color;
        margin-bottom: cal(17.5);
      }

      p {
        font-size: cal(24);
        line-height: (34 / 24);
        letter-spacing: 0.04em;
        font-weight: $medium;
        color: $desc-color;
      }
    }
  }

  #kv {
    width: 100%;
    height: cal(980);
    position: relative;

    .container {
      width: 100%;
      max-width: cal($spsize);
      height: 100%;
      margin: 0 auto;
      position: relative;
    }

    .kv {
      &__bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__text {
        color: $color-white;
        position: absolute;
        top: cal(300);
        left: cal(164);
        z-index: 1;
        transform: translateY(30px);
        opacity: 0;
        visibility: hidden;
        transition: 1s ease opacity, 1s ease transform, 0s 0s visibility;
        
        &.loaded {
          opacity: 1;
          transform: translateY(0);
          visibility: visible;
          transition-delay: 0s;
        }

        h1 {
          font-size: cal(80);
          line-height: (78 / 80);
          font-weight: $semibold;
          margin-bottom: cal(42);
        }

        p {
          font-size: cal(25);
          line-height: (40 / 25);
          letter-spacing: 0.04em;
          font-weight: $bold;
        }
      }

      &__scroll {
        color: $color-white;
        width: cal(140);
        position: absolute;
        right: cal(74.55);
        bottom: 0;
        z-index: 1;
        transform: rotate(90deg);
        transform-origin: right bottom;

        &::after {
          content: "";
          width: 100%;
          height: 1px;
          background: currentColor;
          position: absolute;
          bottom: 0;
          left: 0;
        }

        p {
          font-family: $secondry-font;
          font-size: cal(16);
          line-height: (20 / 16);
          color: currentColor;
          font-weight: $semibold;
          margin-bottom: cal(8.5);
        }
      }
    }

  }

  #about {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    width: 100%;
    background: $color-white;
    padding: cal(130) 0;
    position: relative;

    .container {
      width: 100%;
      max-width: cal(800);
      padding: 0 cal(40);
      margin: 0 auto;
    }

    .about {
      &__wrapper {
        margin-bottom: cal(160);
      }

      &__title {
        margin-bottom: cal(80);
      }

      &__texts {
        letter-spacing: 0.04em;
        margin-bottom: cal(74);

        &__heading {
          font-size: cal(30);
          line-height: (42 / 30);
          font-weight: $bold;
          margin-bottom: cal(24);
        }

        &__desc {
          font-size: cal(20);
          line-height: (36 / 20);
        }
      }

      &__btn {
        @include ButtonSP;
      }

      &__img {
        width: 100%;
        height: cal(582);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  #service {
    padding: cal(143.5) 0 cal(159.5);
    background: $secondry-background-color;
  }

  #news {
    padding: cal(133.5) 0 cal(159.5);
    background: $color-white;

    .news {
      &__title {
        margin-bottom: cal(75);
      }

      &__list {
        margin-bottom: cal(60);
      }

      &__item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;
        padding: cal(30) cal(17.5);
        border-top: cal(1) solid $border-color;
        position: relative;
        transition: 0.3s ease all;

        &:last-child {
          border-bottom: cal(1) solid $border-color;
        }

        >a {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }

        &__time {
          font-size: cal(20);
          line-height: (24 / 20);
          margin-right: cal(20);
        }

        &__category {
          font-size: cal(16);
          line-height: (24 / 16);
          color: $color-white;
          text-align: center;
          width: cal(120);
          padding: cal(5) 0;
          border-radius: cal(20);

          &.category01 {
            background: $category01-color;
          }

          &.category02 {
            background: $category02-color;
          }

          &.category03 {
            background: $category03-color;
          }
        }

        &__title {
          font-size: cal(20);
          line-height: (30 / 20);
          letter-spacing: 0.04em;
          width: 100%;
          margin-top: cal(5);
        }
      }

      &__btn {
        @include ButtonSP;
      }
    }
  }
}