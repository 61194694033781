@charset "UTF-8";
/*-------------------------------------------------

  MAIN COLOR
  サイトのプライマリーカラーなど
  
  -------------------------------------------------*/
/*-------------------------------------------------
  
    BACKGROUND COLOR
    サイトのバックグラウンドカラーなど
    
    -------------------------------------------------*/
/*-------------------------------------------------
  
    TEXT COLOR
    サイトのテキストカラーなど
    
    -------------------------------------------------*/
/*-------------------------------------------------

  PAGE COLOR
  サイトのページごとのカラーなど
  
  -------------------------------------------------*/
/*-------------------------------------------------

  OTHER COLOR
  サイトのその他のカラーなど
  
  -------------------------------------------------*/
/*-------------------------------------------------

  web font
  ウェブフォントの読み込みはここに記載
  
  -------------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,400;0,500;0,600;1,600&family=Noto+Sans+JP:wght@400;500;700&display=swap");
/*-------------------------------------------------
  
  FONT FAMILY
  フォントファミリーはここで変数化

-------------------------------------------------*/
/*-------------------------------------------------

  FONT WEIGHT
  各種フォントウェイトはこの変数を使用

-------------------------------------------------*/
/*-------------------------------------------------
  メディアクエリ生成 (デザイン幅)
  mq(min) → PC版
  mq(max) → SP版
-------------------------------------------------*/
/*-------------------------------------------------
  レスポンシブ計算
  rem : cal(デザインデータサイズ,  pc or sp) default : sp
  vw : calvw(デザインデータサイズ,  pc or sp) default : sp
-------------------------------------------------*/
/*-----------------------------------


  アニメーション関連変数


-----------------------------------*/
/*-----------------------------------


  ローディングスクリーン


-----------------------------------*/
@keyframes loading {
  0%,
  100%,
  80% {
    box-shadow: 0 0;
    height: 4em; }
  40% {
    box-shadow: 0 -2em;
    height: 5em; } }

.load_bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  z-index: 100000;
  transform: translate3d(0, 0, 0); }
  body.animation .load_bg,
  body.loaded .load_bg {
    visibility: hidden;
    opacity: 0;
    transition: 0.3s ease opacity, 0s visibility 0.3s; }
  .load_bg__contents, .load_bg__contents:after, .load_bg__contents:before {
    background: #21589c;
    animation: loading 1s infinite ease-in-out;
    width: 1em;
    height: 4em; }
  .load_bg__contents {
    color: #21589c;
    text-indent: -9999em;
    font-size: 0.4rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3D(-50%, -50%, 0);
    animation-delay: -0.16s; }
    @media (max-width: 768px) {
      .load_bg__contents {
        font-size: 0.59733rem; } }
  .load_bg__contents:after, .load_bg__contents:before {
    position: absolute;
    top: 0;
    content: ''; }
  .load_bg__contents:before {
    left: -1.5em;
    animation-delay: -0.32s; }
  .load_bg__contents:after {
    left: 1.5em; }

/*-----------------------------------


  フェードインアニメーション


-----------------------------------*/
.js-fadeIn {
  visibility: hidden;
  opacity: 0;
  transform: translateY(20px); }
  .js-fadeIn.fadeIn {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    transition: 0s visibility, 0.8s opacity ease, 0.8s transform ease; }

/*-----------------------------------


  フェードイングループアニメーション


-----------------------------------*/
.js-fadeInGroup .js-fadeInGroupChild {
  visibility: hidden;
  opacity: 0;
  transform: translateY(20px); }

.js-fadeInGroup.fadeIn .js-fadeInGroupChild {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  transition: 0s visibility, 0.8s opacity ease, 0.8s transform ease; }

/*-----------------------------------


  フェードインテキストアニメーション


-----------------------------------*/
.js-fadeInText span {
  visibility: hidden;
  opacity: 0;
  transform: translateY(20px); }

.js-fadeInText.fadeIn span {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  transition: 0s visibility, 0.8s opacity ease, 0.8s transform ease; }

/*-----------------------------------


  KV スクロールアニメーション


-----------------------------------*/
@keyframes scrollAnimation {
  0% {
    transform: scale(0, 1);
    transform-origin: 0 0; }
  35% {
    transform: scale(1, 1);
    transform-origin: 0 0; }
  35.1% {
    transform: scale(1, 1);
    transform-origin: 100% 0; }
  100%,
  70% {
    transform: scale(0, 1);
    transform-origin: 100% 0; } }

.js-scroll::after {
  animation: scrollAnimation 3s ease infinite; }

/*-----------------------------------


  グラデーションアニメーション


-----------------------------------*/
@keyframes gradation {
  0%,
  100% {
    background-position: 0% 0%; }
  50% {
    background-position: 100% 100%; } }

/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

nav ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input,
select {
  vertical-align: middle; }

sup {
  vertical-align: super; }

sub {
  vertical-align: sub; }

*,
*:after,
*:before {
  box-sizing: border-box; }

a {
  text-decoration: none;
  color: inherit; }

ul {
  list-style: none; }

sup {
  font-size: smaller;
  line-height: 1;
  color: unset; }

html {
  font-size: 3.125vw;
  font-family: "Noto Sans JP", YuGothic, "Yu Gothic", sans-serif; }
  html.resize * {
    font-size: inherit; }

@media (max-width: 768px) and (orientation: landscape) {
  html {
    font-size: 3.125vh; } }
  @media (min-width: 1601px) {
    html {
      font-size: 50px; } }

img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  display: block; }
  @media (max-width: 768px) {
    img {
      width: 100%; } }

@media (min-width: 769px) {
  .sp {
    display: none !important; } }

@media (max-width: 768px) {
  .pc {
    display: none !important; } }

body {
  font-size: 16px;
  color: #435a6e;
  font-weight: 400;
  font-feature-settings: "palt";
  overflow: hidden;
  background-color: #fff; }
  body.loaded {
    overflow: auto; }
  body.open {
    overflow: hidden; }

.obj-c {
  object-fit: cover;
  font-family: 'object-fit: cover;'; }

.obj-con {
  object-fit: contain;
  font-family: 'object-fit: contain;'; }

.container {
  margin: 0 auto; }

.barlow {
  font-family: "Barlow", sans-serif; }

.barlow-italic {
  font-family: "Barlow", sans-serif;
  font-style: italic; }

@media (min-width: 769px) {
  a,
  button {
    display: inline-block;
    transition: 0.3s ease all; }
    a:hover,
    button:hover {
      opacity: 0.7; } }

main {
  display: block;
  position: relative;
  z-index: 0; }
  @media (min-width: 769px) {
    main {
      margin-top: 2rem; } }
  @media (max-width: 768px) {
    main {
      margin-top: 4.26667rem; } }
  @media (min-width: 769px) {
    main #pagetop {
      width: 1.2rem;
      position: absolute;
      bottom: 0.8rem;
      right: 0.8rem;
      z-index: 100; }
      main #pagetop img {
        width: 100%;
        margin: 0; } }
  @media (max-width: 768px) {
    main #pagetop {
      width: 3.41333rem;
      position: absolute;
      bottom: 0.85333rem;
      right: 0.85333rem;
      z-index: 100; }
      main #pagetop img {
        width: 100%;
        margin: 0; } }

@media (min-width: 769px) {
  header {
    letter-spacing: 0.04em;
    width: 100%;
    height: 2rem;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001; }
    header .header__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0 3.6rem;
      margin: 0 auto; }
    header .header__logo {
      width: 5.697rem;
      margin-top: -0.08rem;
      margin-left: -2.5rem; }
      header .header__logo img {
        margin: 0; }
      header .header__logo span {
        display: none; }
    header .header__menu__list {
      display: flex;
      align-items: center;
      justify-content: flex-end; }
    header .header__menu__item {
      font-size: 0.32rem;
      line-height: 1.5;
      font-weight: 500; }
      header .header__menu__item + .header__menu__item {
        margin-left: 0.64rem; }
    header .header__btns {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      position: absolute;
      right: 0;
      top: 0; }
      header .header__btns__item {
        width: 2.4rem;
        height: 2rem; }
        header .header__btns__item.recruit a {
          background: #3ac319; }
        header .header__btns__item a {
          font-size: 0.3rem;
          line-height: 1.6;
          font-weight: 700;
          color: #fff;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%; } }

@media (max-width: 768px) {
  header {
    letter-spacing: 0.04em;
    width: 100%;
    height: 4.26667rem;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001; }
    header .header__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;
      background: #fff;
      margin: 0 auto;
      position: relative;
      z-index: 10001; }
    header .header__logo {
      width: 12.1536rem;
      margin-top: -0.17067rem;
      margin-left: 1.49333rem; }
      header .header__logo img {
        margin: 0; }
      header .header__logo span {
        display: none; }
    header .header__btns {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      position: absolute;
      right: 0;
      top: 0; }
      header .header__btns__item {
        font-size: 0.64rem;
        line-height: 1.6;
        font-weight: 700;
        color: #fff;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 5.12rem;
        height: 4.26667rem; }
        header .header__btns__item.recruit a {
          background: #3ac319; }
        header .header__btns__item.hamburger {
          background: #21589c; }
          header .header__btns__item.hamburger .hamburger__inner {
            width: 1.70667rem;
            height: 1.10933rem;
            position: relative; }
          header .header__btns__item.hamburger span {
            display: inline-block;
            width: 1.70667rem;
            height: 0.08533rem;
            background: #fff;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            transition: 0.3s ease all; }
            header .header__btns__item.hamburger span:nth-child(1) {
              top: 0; }
            header .header__btns__item.hamburger span:nth-child(2) {
              top: 50%;
              transform: translate(-50%, -50%); }
            header .header__btns__item.hamburger span:nth-child(3) {
              top: calc(100% - 0.08533rem); }
          header .header__btns__item.hamburger.open span:nth-child(1) {
            top: 50%;
            transform: translate(-50%, -50%) rotate(135deg); }
          header .header__btns__item.hamburger.open span:nth-child(2) {
            width: 0; }
          header .header__btns__item.hamburger.open span:nth-child(3) {
            top: 50%;
            transform: translate(-50%, -50%) rotate(-135deg); }
        header .header__btns__item a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%; }
    header .header__menu {
      width: 100%;
      height: 0;
      background: #f7f9fa;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: -1;
      transition: 0.5s ease opacity, 0.5s ease height, 0s 0.5s visibility;
      visibility: hidden;
      opacity: 0;
      overflow: hidden; }
      header .header__menu.open {
        visibility: visible;
        opacity: 1;
        transition-delay: 0s; }
      header .header__menu__list {
        padding: 4.608rem 3.41333rem; }
      header .header__menu__item {
        font-size: 1.28rem;
        line-height: 1.33333;
        letter-spacing: 0.04em;
        font-weight: 500; }
        header .header__menu__item + .header__menu__item {
          margin-top: 4.69333rem; } }

@media (min-width: 769px) {
  footer {
    color: #afb8bf;
    background: #132333;
    padding: 2.84rem 0 2.76rem; }
    footer .footer__inner {
      max-width: 32rem;
      padding: 0 3.6rem;
      margin: 0 auto; }
    footer .footer__nav {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 1.84rem; }
    footer .footer__sec {
      padding-top: 0.28rem; }
      footer .footer__sec__title {
        font-size: 0.32rem;
        line-height: 1.5;
        font-weight: 700;
        color: #fff;
        display: inline-block;
        padding-bottom: 0.34rem;
        position: relative; }
        footer .footer__sec__title::after {
          content: "";
          display: inline-block;
          width: 0.4rem;
          height: 1px;
          background: #21589c;
          position: absolute;
          left: 0;
          bottom: 0; }
      footer .footer__sec__block + .footer__sec__block {
        margin-top: 1.56rem; }
    footer .footer__menus {
      font-size: 0.28rem;
      line-height: 2.14286;
      margin-top: 0.68rem; }
      footer .footer__menus p {
        font-weight: 700; }
    footer .footer__list + .footer__list {
      margin-top: 0.36rem; }
    footer .footer__logo {
      width: 5.619rem; }
    footer .footer__copy {
      font-size: 0.28rem;
      line-height: 1.28571;
      font-weight: 500;
      text-align: center; } }

@media (max-width: 768px) {
  footer {
    color: #afb8bf;
    background: #132333;
    padding: 6.05867rem 0 5.888rem; }
    footer .footer__inner {
      max-width: 32rem;
      padding: 0 1.19467rem;
      margin: 0 auto; }
    footer .footer__nav {
      margin-bottom: 5.12rem; }
    footer .footer__sec {
      width: calc(50% - 0.85333rem);
      padding-top: 0.59733rem; }
      footer .footer__sec:nth-child(odd) {
        float: left; }
      footer .footer__sec:nth-child(even) {
        float: right; }
      footer .footer__sec:nth-child(n + 3) {
        margin-top: 2.13333rem; }
      footer .footer__sec__title {
        font-size: 0.85333rem;
        line-height: 1.4;
        font-weight: 700;
        color: #fff;
        display: inline-block;
        padding-bottom: 0.72533rem;
        position: relative; }
        footer .footer__sec__title::after {
          content: "";
          display: inline-block;
          width: 0.85333rem;
          height: 1px;
          background: #21589c;
          position: absolute;
          left: 0;
          bottom: 0; }
      footer .footer__sec__block + .footer__sec__block {
        margin-top: 3.328rem; }
    footer .footer__bottoms {
      padding-top: 4.43733rem;
      clear: both; }
      footer .footer__bottoms .footer__list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap; }
        footer .footer__bottoms .footer__list__item {
          width: calc(50% - 0.85333rem); }
    footer .footer__menus {
      font-size: 0.768rem;
      line-height: 2.66667;
      margin-top: 1.45067rem; }
      footer .footer__menus p {
        font-weight: 700; }
    footer .footer__list + .footer__list {
      margin-top: 0.768rem; }
    footer .footer__logo {
      width: 11.9872rem;
      margin-bottom: 1.92rem; }
    footer .footer__copy {
      font-size: 0.768rem;
      line-height: 1.36111;
      font-weight: 500;
      text-align: center; } }

@media (min-width: 769px) {
  #banner {
    padding: 2.1rem 0 2.4rem;
    background: #f7f9fa; }
    #banner .container {
      width: 100%;
      max-width: 32rem;
      padding: 0 3.6rem;
      margin: 0 auto; }
    #banner .banner__title {
      font-size: 0.32rem;
      line-height: 1.5;
      font-weight: 700;
      margin-bottom: 0.64rem; }
    #banner .banner__content {
      margin-bottom: 1.2rem; }
    #banner .banner__block {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start; }
      #banner .banner__block:nth-child(n + 2) {
        margin-top: 0.32rem; }
      #banner .banner__block__title {
        font-size: 0.24rem;
        line-height: 2;
        letter-spacing: 0.04em;
        width: 2.16rem; }
      #banner .banner__block__list {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        flex: 1; }
    #banner .banner__item {
      font-size: 0.24rem;
      line-height: 2;
      letter-spacing: 0.04em;
      color: #21589c;
      padding: 0 0.24rem;
      position: relative; }
      #banner .banner__item::after {
        content: "|";
        position: absolute;
        right: 0;
        top: 0; }
      #banner .banner__item:last-child::after {
        content: none; }
      #banner .banner__item a {
        display: inline-block;
        text-decoration: underline; }
    #banner .banner__images {
      display: flex;
      align-items: center;
      justify-content: center; }
      #banner .banner__images__item {
        width: 4.8rem;
        margin: 0 0.4rem; } }

@media (max-width: 768px) {
  #banner {
    padding: 4.48rem 0 5.12rem;
    background: #f7f9fa; }
    #banner .container {
      width: 100%;
      max-width: 32rem;
      padding: 0 1.70667rem;
      margin: 0 auto; }
    #banner .banner__title {
      font-size: 1.28rem;
      line-height: 1.46667;
      font-weight: 700;
      margin-bottom: 1.55733rem; }
    #banner .banner__content {
      margin-bottom: 2.56rem; }
    #banner .banner__block {
      border-top: 0.04267rem solid #e5e5e5; }
      #banner .banner__block:last-child {
        border-bottom: 0.04267rem solid #e5e5e5; }
      #banner .banner__block__title {
        font-size: 0.85333rem;
        line-height: 1.4;
        letter-spacing: 0.04em;
        font-weight: 500;
        padding: 1.70667rem 0; }
      #banner .banner__block__list {
        padding: 1.70667rem 0; }
    #banner .banner__item {
      font-size: 0.85333rem;
      line-height: 1.4;
      letter-spacing: 0.04em;
      color: #21589c;
      padding: 0 1.024rem;
      position: relative; }
      #banner .banner__item:nth-child(n + 2) {
        margin-top: 0.512rem; }
      #banner .banner__item::before {
        content: "・";
        position: absolute;
        left: 0;
        top: 0; }
      #banner .banner__item a {
        display: inline-block;
        text-decoration: underline; }
    #banner .banner__images__item {
      width: 20.48rem;
      margin: 0 auto; }
      #banner .banner__images__item:nth-child(n + 2) {
        margin-top: 0.85333rem; } }

/*-------------------------------------------------

  pagesのインデックス
  下記を複製
  
  -------------------------------------------------*/
@media (min-width: 769px) {
  #kv.kv-common {
    color: #fff;
    width: 100%;
    height: 6.8rem;
    position: relative; }
    #kv.kv-common .container {
      width: 100%;
      max-width: 32rem;
      height: 100%;
      padding: 0 3.6rem;
      margin: 0 auto;
      position: relative; }
    #kv.kv-common .kv__bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1; }
      #kv.kv-common .kv__bg img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    #kv.kv-common .kv__title {
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
      #kv.kv-common .kv__title h1 {
        font-size: 0.96rem;
        line-height: 1.45833;
        letter-spacing: 0.04em;
        font-weight: 700;
        margin-bottom: 0.29rem; }
      #kv.kv-common .kv__title p {
        font-size: 0.4rem;
        line-height: 1.45;
        letter-spacing: 0.04em;
        font-weight: 700; }
    #kv.kv-common .kv__breadcrumb {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: absolute;
      left: 3.6rem;
      bottom: 0.64rem; }
      #kv.kv-common .kv__breadcrumb li {
        font-size: 0.28rem;
        line-height: 1.42857;
        letter-spacing: 0.04em;
        margin-right: 0.56rem;
        position: relative; }
        #kv.kv-common .kv__breadcrumb li:last-child::after {
          content: none; }
        #kv.kv-common .kv__breadcrumb li::after {
          content: "";
          width: 0.2rem;
          height: 0.02rem;
          background: currentColor;
          position: absolute;
          top: 55%;
          left: calc(100% + 0.18rem);
          transform: translateY(-50%) rotate(-45deg); }
  #top #service .service__title,
  #service-list #service .service__title {
    margin-bottom: 1.4rem; }
  #top #service .service__contents,
  #service-list #service .service__contents {
    margin-bottom: 1.6rem; }
  #top #service .service__block:nth-child(n + 2),
  #service-list #service .service__block:nth-child(n + 2) {
    margin-top: 1.03rem; }
  #top #service .service__block__title,
  #service-list #service .service__block__title {
    font-size: 0.48rem;
    line-height: 1.5;
    letter-spacing: 0.04em;
    font-weight: 700;
    padding-left: 0.7rem;
    margin-bottom: 0.72rem;
    position: relative; }
    #top #service .service__block__title::before,
    #service-list #service .service__block__title::before {
      content: "";
      width: 0.4rem;
      height: 0.04rem;
      background: currentColor;
      position: absolute;
      top: 0.34rem;
      left: 0; }
  #top #service .service__list,
  #service-list #service .service__list {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap; }
  #top #service .service__item,
  #service-list #service .service__item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: calc(50% - 0.4rem);
    min-height: 4rem;
    background: #fff;
    position: relative;
    transition: 0.3s ease all; }
    #top #service .service__item.hover-animation:hover,
    #service-list #service .service__item.hover-animation:hover {
      opacity: 0.7; }
    #top #service .service__item > a,
    #service-list #service .service__item > a {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1; }
    #top #service .service__item:nth-child(n + 3),
    #service-list #service .service__item:nth-child(n + 3) {
      margin-top: 0.6rem; }
    #top #service .service__item__img,
    #service-list #service .service__item__img {
      width: 4rem;
      height: 4rem; }
    #top #service .service__item__content,
    #service-list #service .service__item__content {
      flex: 1;
      padding: 0.43rem 0.6rem 0 0.77rem; }
    #top #service .service__item__title,
    #service-list #service .service__item__title {
      font-size: 0.4rem;
      line-height: 1.2;
      letter-spacing: 0.04em;
      font-weight: 700;
      color: #21589c;
      margin-bottom: 0.15rem; }
    #top #service .service__item__desc,
    #service-list #service .service__item__desc {
      font-size: 0.3rem;
      line-height: 1.4;
      letter-spacing: 0.04em;
      color: #21589c;
      min-height: 0.42rem;
      margin-bottom: 0.3rem; }
    #top #service .service__item__text,
    #service-list #service .service__item__text {
      font-size: 0.3rem;
      line-height: 1.6;
      letter-spacing: 0.04em; }
  #top #service .service__btn,
  #service-list #service .service__btn {
    margin: 0 auto;
    width: 5.6rem; }
    #top #service .service__btn a,
    #service-list #service .service__btn a {
      font-size: 0.32rem;
      line-height: 1.5;
      font-weight: 700;
      color: #fff;
      text-align: center;
      display: inline-block;
      width: 100%;
      padding: 0.56rem 0;
      background: #21589c;
      position: relative;
      transition: 0.3s ease all; }
      #top #service .service__btn a::after,
      #service-list #service .service__btn a::after {
        content: "";
        width: 0.3258rem;
        height: 0.2178rem;
        background: url(../img/common/arrow_right.svg) center/contain no-repeat;
        position: absolute;
        top: 50%;
        right: 0.606rem;
        transform: translateY(-50%);
        z-index: 0; }
      #top #service .service__btn a:hover,
      #service-list #service .service__btn a:hover {
        opacity: 0.7; } }

@media (max-width: 768px) {
  #kv.kv-common {
    color: #fff;
    width: 100%;
    height: 14.50667rem;
    position: relative; }
    #kv.kv-common .container {
      width: 100%;
      max-width: 32rem;
      height: 100%;
      padding: 0 1.70667rem;
      margin: 0 auto;
      position: relative; }
    #kv.kv-common .kv__bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1; }
      #kv.kv-common .kv__bg img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    #kv.kv-common .kv__title {
      white-space: nowrap;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
      #kv.kv-common .kv__title h1 {
        font-size: 2.048rem;
        line-height: 1.45833;
        letter-spacing: 0.04em;
        font-weight: 700;
        margin-bottom: 0.61867rem; }
      #kv.kv-common .kv__title p {
        font-size: 0.85333rem;
        line-height: 1.45;
        letter-spacing: 0.04em;
        font-weight: 700; }
    #kv.kv-common .kv__breadcrumb {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      position: absolute;
      left: 1.70667rem;
      bottom: 1.36533rem; }
      #kv.kv-common .kv__breadcrumb li {
        font-size: 0.59733rem;
        line-height: 1.42857;
        letter-spacing: 0.04em;
        margin-right: 1.19467rem;
        position: relative; }
        #kv.kv-common .kv__breadcrumb li:last-child::after {
          content: none; }
        #kv.kv-common .kv__breadcrumb li::after {
          content: "";
          width: 0.42667rem;
          height: 0.04267rem;
          background: currentColor;
          position: absolute;
          top: 55%;
          left: calc(100% + 0.384rem);
          transform: translateY(-50%) rotate(-45deg); }
  #top #service .service__title,
  #service-list #service .service__title {
    margin-bottom: 3.2rem; }
  #top #service .service__contents,
  #service-list #service .service__contents {
    margin-bottom: 3.41333rem; }
  #top #service .service__block:nth-child(n + 2),
  #service-list #service .service__block:nth-child(n + 2) {
    margin-top: 3.008rem; }
  #top #service .service__block__title,
  #service-list #service .service__block__title {
    font-size: 1.28rem;
    line-height: 1.2;
    letter-spacing: 0.04em;
    font-weight: 700;
    padding-left: 1.70667rem;
    margin-bottom: 1.92rem;
    position: relative; }
    #top #service .service__block__title::before,
    #service-list #service .service__block__title::before {
      content: "";
      width: 1.06667rem;
      height: 0.08533rem;
      background: currentColor;
      position: absolute;
      top: 0.72533rem;
      left: 0; }
  #top #service .service__item,
  #service-list #service .service__item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    min-height: 8.53333rem;
    background: #fff;
    position: relative; }
    #top #service .service__item > a,
    #service-list #service .service__item > a {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1; }
    #top #service .service__item:nth-child(n + 2),
    #service-list #service .service__item:nth-child(n + 2) {
      margin-top: 1.28rem; }
    #top #service .service__item__img,
    #service-list #service .service__item__img {
      width: 8.53333rem;
      height: 8.53333rem; }
    #top #service .service__item__content,
    #service-list #service .service__item__content {
      letter-spacing: 0.04em;
      flex: 1;
      padding: 0.64rem 1.19467rem 0 1.70667rem; }
    #top #service .service__item__title,
    #service-list #service .service__item__title {
      font-size: 1.06667rem;
      line-height: 1.44;
      font-weight: 700;
      color: #21589c; }
    #top #service .service__item__desc,
    #service-list #service .service__item__desc {
      font-size: 0.85333rem;
      line-height: 1.4;
      color: #21589c;
      margin-bottom: 0.34133rem; }
    #top #service .service__item__text,
    #service-list #service .service__item__text {
      font-size: 0.85333rem;
      line-height: 1.4; }
  #top #service .service__btn,
  #service-list #service .service__btn {
    width: 100%; }
    #top #service .service__btn a,
    #service-list #service .service__btn a {
      font-size: 0.85333rem;
      line-height: 1.5;
      font-weight: 700;
      color: #fff;
      text-align: center;
      display: inline-block;
      width: 100%;
      padding: 1.62133rem 0;
      background: #21589c;
      position: relative; }
      #top #service .service__btn a::after,
      #service-list #service .service__btn a::after {
        content: "";
        width: 0.85333rem;
        height: 0.57003rem;
        background: url(../img/common/arrow_right.svg) center/contain no-repeat;
        position: absolute;
        top: 50%;
        right: 1.70667rem;
        transform: translateY(-50%);
        z-index: 0; } }

@media (min-width: 769px) {
  #other-pages .sec .container {
    width: 100%;
    max-width: 32rem;
    padding: 0 3.6rem;
    margin: 0 auto;
    position: relative; }
  #other-pages .sec-title {
    font-size: 0.64rem;
    line-height: 1.25;
    padding-bottom: 0.4rem;
    margin-bottom: 1.64rem;
    position: relative; }
    #other-pages .sec-title::after {
      content: "";
      display: inline-block;
      width: 0.8rem;
      height: 0.04rem;
      background: #21589c;
      position: absolute;
      bottom: 0;
      left: 0; }
  #other-pages #other {
    padding: 2.07rem 0 4.12rem; }
    #other-pages #other .other__section {
      font-size: 0.32rem;
      line-height: 1.875;
      letter-spacing: 0.04em; }
      #other-pages #other .other__section + .other__section {
        margin-top: 1.6rem; }
      #other-pages #other .other__section.right {
        text-align: right; }
      #other-pages #other .other__section .box {
        margin-top: 0.6rem; }
        #other-pages #other .other__section .box + .box {
          margin-top: 1.2rem; }
      #other-pages #other .other__section .list {
        margin-top: 0.6rem; }
      #other-pages #other .other__section .margin-list > li + li {
        margin-top: 0.6rem; }
      #other-pages #other .other__section p + .paragraph {
        margin-top: 0.6rem; }
      #other-pages #other .other__section p.bold {
        font-weight: 700; }
      #other-pages #other .other__section__sitemap__title {
        font-size: 0.36rem;
        line-height: 2.22222;
        color: #21589C;
        margin-bottom: 0.366rem; }
      #other-pages #other .other__section__sitemap__list {
        font-size: 0.36rem;
        line-height: 2.22222;
        margin-bottom: 0.8rem; }
        #other-pages #other .other__section__sitemap__list ul li {
          position: relative;
          padding-left: 0.4rem; }
          #other-pages #other .other__section__sitemap__list ul li::before {
            position: absolute;
            content: "";
            width: 0.14rem;
            height: 0.14rem;
            top: 0.34rem;
            left: 0;
            vertical-align: top;
            background-color: #21589c; }
      #other-pages #other .other__section .top_margin {
        margin-bottom: 0.948rem !important; }
      #other-pages #other .other__section .last_margin {
        margin-bottom: 2rem !important; }
    #other-pages #other .fotFound__title {
      font-size: 1.28rem;
      line-height: 1.4375;
      letter-spacing: 0.04em;
      font-weight: 700;
      text-align: center; }
    #other-pages #other .fotFound__desc {
      font-size: 0.6rem;
      line-height: 1.46667;
      letter-spacing: 0.04em;
      font-weight: 700;
      text-align: center;
      margin-bottom: 1.57rem; }
    #other-pages #other .fotFound__btn {
      margin: 0 auto;
      width: 5.6rem; }
      #other-pages #other .fotFound__btn a {
        font-size: 0.32rem;
        line-height: 1.5;
        font-weight: 700;
        color: #fff;
        text-align: center;
        display: inline-block;
        width: 100%;
        padding: 0.56rem 0;
        background: #21589c;
        position: relative;
        transition: 0.3s ease all; }
        #other-pages #other .fotFound__btn a::after {
          content: "";
          width: 0.3258rem;
          height: 0.2178rem;
          background: url(../img/common/arrow_right.svg) center/contain no-repeat;
          position: absolute;
          top: 50%;
          right: 0.606rem;
          transform: translateY(-50%);
          z-index: 0; }
        #other-pages #other .fotFound__btn a:hover {
          opacity: 0.7; } }

@media (max-width: 768px) {
  #other-pages .sec .container {
    width: 100%;
    max-width: 32rem;
    height: 100%;
    padding: 0 1.70667rem;
    margin: 0 auto;
    position: relative; }
  #other-pages .sec-title {
    font-size: 1.36533rem;
    line-height: 1.25;
    padding-bottom: 0.85333rem;
    margin-bottom: 3.49867rem;
    position: relative; }
    #other-pages .sec-title::after {
      content: "";
      display: inline-block;
      width: 1.70667rem;
      height: 0.08533rem;
      background: #21589c;
      position: absolute;
      bottom: 0;
      left: 0; }
  #other-pages #other {
    padding: 4.416rem 0 8.78933rem; }
    #other-pages #other .other__section {
      font-size: 0.68267rem;
      line-height: 1.875;
      letter-spacing: 0.04em; }
      #other-pages #other .other__section + .other__section {
        margin-top: 3.41333rem; }
      #other-pages #other .other__section.right {
        text-align: right; }
      #other-pages #other .other__section .box {
        margin-top: 1.28rem; }
        #other-pages #other .other__section .box + .box {
          margin-top: 2.56rem; }
      #other-pages #other .other__section .list {
        margin-top: 1.28rem; }
      #other-pages #other .other__section .margin-list > li + li {
        margin-top: 1.28rem; }
      #other-pages #other .other__section p + .paragraph {
        margin-top: 1.28rem; }
      #other-pages #other .other__section p.bold {
        font-weight: 700; }
      #other-pages #other .other__section__sitemap__title {
        font-size: 0.768rem;
        line-height: 2.22222;
        color: #21589C;
        margin-bottom: 0.7808rem; }
      #other-pages #other .other__section__sitemap__list {
        font-size: 0.768rem;
        line-height: 2.22222;
        margin-bottom: 1.70667rem; }
        #other-pages #other .other__section__sitemap__list ul li {
          position: relative;
          padding-left: 0.85333rem; }
          #other-pages #other .other__section__sitemap__list ul li::before {
            position: absolute;
            content: "";
            width: 0.29867rem;
            height: 0.29867rem;
            top: 0.72533rem;
            left: 0;
            vertical-align: top;
            background-color: #21589c; }
      #other-pages #other .other__section .top_margin {
        margin-bottom: 2.0224rem !important; }
      #other-pages #other .other__section .last_margin {
        margin-bottom: 4.26667rem !important; }
    #other-pages #other .fotFound__title {
      font-size: 2.73067rem;
      line-height: 1.4375;
      letter-spacing: 0.04em;
      font-weight: 700;
      text-align: center; }
    #other-pages #other .fotFound__desc {
      font-size: 1.28rem;
      line-height: 1.46667;
      letter-spacing: 0.04em;
      font-weight: 700;
      text-align: center;
      margin-bottom: 3.34933rem; }
    #other-pages #other .fotFound__btn {
      margin: 0 auto;
      width: 100%; }
      #other-pages #other .fotFound__btn a {
        font-size: 0.85333rem;
        line-height: 1.5;
        font-weight: 700;
        color: #fff;
        text-align: center;
        display: inline-block;
        width: 100%;
        padding: 1.62133rem 0;
        background: #21589c;
        position: relative; }
        #other-pages #other .fotFound__btn a::after {
          content: "";
          width: 0.85333rem;
          height: 0.57003rem;
          background: url(../img/common/arrow_right.svg) center/contain no-repeat;
          position: absolute;
          top: 50%;
          right: 1.70667rem;
          transform: translateY(-50%);
          z-index: 0; } }

@media (min-width: 769px) {
  #top .sec .container {
    width: 100%;
    max-width: 32rem;
    padding: 0 3.6rem;
    margin: 0 auto; }
  #top .sec-title h2 {
    font-size: 1.92rem;
    line-height: 1.19792;
    font-weight: 600;
    color: #21589c;
    margin-bottom: 0.35rem; }
  #top .sec-title p {
    font-size: 0.36rem;
    line-height: 1.44444;
    letter-spacing: 0.04em;
    font-weight: 500;
    color: #667784; }
  #top #kv {
    width: 100%;
    height: 13.6rem;
    position: relative; }
    #top #kv .container {
      width: 100%;
      max-width: 32rem;
      height: 100%;
      padding: 0 3.6rem;
      margin: 0 auto;
      position: relative; }
    #top #kv .kv__bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1; }
      #top #kv .kv__bg img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    #top #kv .kv__text {
      color: #fff;
      position: absolute;
      top: 50%;
      right: 3.6rem;
      z-index: 1;
      transform: translateY(30px);
      opacity: 0;
      visibility: hidden;
      transition: 1s ease opacity, 1s ease transform, 0s 0s visibility; }
      #top #kv .kv__text.loaded {
        opacity: 1;
        transform: translateY(-50%);
        visibility: visible;
        transition-delay: 0s; }
      #top #kv .kv__text h1 {
        font-size: 1.6rem;
        line-height: 1.0875;
        font-weight: 600;
        margin-bottom: 0.6rem; }
      #top #kv .kv__text p {
        font-size: 0.4rem;
        line-height: 1.4;
        letter-spacing: 0.04em;
        font-weight: 700; }
    #top #kv .kv__scroll {
      color: #fff;
      width: 2.8rem;
      position: absolute;
      right: 2.95rem;
      bottom: 0;
      z-index: 1;
      transform: rotate(90deg);
      transform-origin: right bottom; }
      #top #kv .kv__scroll::after {
        content: "";
        width: 100%;
        height: 1px;
        background: currentColor;
        position: absolute;
        bottom: 0;
        left: 0; }
      #top #kv .kv__scroll p {
        font-family: "Barlow", sans-serif;
        font-size: 0.32rem;
        line-height: 1.25;
        color: currentColor;
        font-weight: 600;
        margin-bottom: 0.17rem; }
  #top #about {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background: #fff;
    padding: 3.2rem 0;
    position: relative; }
    #top #about .container {
      width: 50%;
      max-width: 16rem;
      padding: 0 3.6rem 0 0;
      margin: 0 auto 0 0; }
    #top #about .about__img {
      width: 50%;
      height: 12.4rem; }
      #top #about .about__img img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    #top #about .about__wrapper {
      padding-left: 2rem; }
    #top #about .about__title {
      margin-bottom: 1.4rem; }
    #top #about .about__texts {
      letter-spacing: 0.04em;
      margin-bottom: 1.01rem; }
      #top #about .about__texts__heading {
        font-size: 0.48rem;
        line-height: 1.33333;
        font-weight: 700;
        margin-bottom: 0.56rem; }
      #top #about .about__texts__desc {
        font-size: 0.32rem;
        line-height: 1.875; }
    #top #about .about__btn {
      width: 5.6rem; }
      #top #about .about__btn a {
        font-size: 0.32rem;
        line-height: 1.5;
        font-weight: 700;
        color: #fff;
        text-align: center;
        display: inline-block;
        width: 100%;
        padding: 0.56rem 0;
        background: #21589c;
        position: relative;
        transition: 0.3s ease all; }
        #top #about .about__btn a::after {
          content: "";
          width: 0.3258rem;
          height: 0.2178rem;
          background: url(../img/common/arrow_right.svg) center/contain no-repeat;
          position: absolute;
          top: 50%;
          right: 0.606rem;
          transform: translateY(-50%);
          z-index: 0; }
        #top #about .about__btn a:hover {
          opacity: 0.7; }
  #top #service {
    padding: 2.69rem 0 3.19rem;
    background: #f7f9fa; }
  #top #news {
    padding: 2.69rem 0 3.19rem;
    background: #fff; }
    #top #news .news__title {
      margin-bottom: 1.4rem; }
    #top #news .news__list {
      margin-bottom: 1.2rem; }
    #top #news .news__item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      padding: 0.76rem 0.4rem;
      border-top: 0.02rem solid #e5e5e5;
      position: relative;
      transition: 0.3s ease all; }
      #top #news .news__item:hover {
        background: #f7f9fa;
        opacity: 0.7; }
      #top #news .news__item:last-child {
        border-bottom: 0.02rem solid #e5e5e5; }
      #top #news .news__item > a {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1; }
      #top #news .news__item__time {
        font-size: 0.32rem;
        line-height: 1.5;
        margin-right: 0.38rem; }
      #top #news .news__item__category {
        font-size: 0.24rem;
        line-height: 2;
        color: #fff;
        text-align: center;
        width: 1.8rem;
        border-radius: 0.24rem;
        margin-right: 0.44rem; }
        #top #news .news__item__category.category01 {
          background: #77a8e5; }
        #top #news .news__item__category.category02 {
          background: #6dd9bc; }
        #top #news .news__item__category.category03 {
          background: #5fcad8; }
      #top #news .news__item__title {
        font-size: 0.32rem;
        line-height: 1.5;
        letter-spacing: 0.04em;
        flex: 1; }
    #top #news .news__btn {
      margin: 0 auto;
      width: 5.6rem; }
      #top #news .news__btn a {
        font-size: 0.32rem;
        line-height: 1.5;
        font-weight: 700;
        color: #fff;
        text-align: center;
        display: inline-block;
        width: 100%;
        padding: 0.56rem 0;
        background: #21589c;
        position: relative;
        transition: 0.3s ease all; }
        #top #news .news__btn a::after {
          content: "";
          width: 0.3258rem;
          height: 0.2178rem;
          background: url(../img/common/arrow_right.svg) center/contain no-repeat;
          position: absolute;
          top: 50%;
          right: 0.606rem;
          transform: translateY(-50%);
          z-index: 0; }
        #top #news .news__btn a:hover {
          opacity: 0.7; } }

@media (max-width: 768px) {
  #top .sec .container {
    width: 100%;
    max-width: 32rem;
    padding: 0 1.70667rem;
    margin: 0 auto; }
  #top .sec-title h2 {
    font-size: 4.096rem;
    line-height: 1.19792;
    font-weight: 600;
    color: #21589c;
    margin-bottom: 0.74667rem; }
  #top .sec-title p {
    font-size: 1.024rem;
    line-height: 1.41667;
    letter-spacing: 0.04em;
    font-weight: 500;
    color: #667784; }
  #top #kv {
    width: 100%;
    height: 41.81333rem;
    position: relative; }
    #top #kv .container {
      width: 100%;
      max-width: 32rem;
      height: 100%;
      margin: 0 auto;
      position: relative; }
    #top #kv .kv__bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1; }
      #top #kv .kv__bg img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    #top #kv .kv__text {
      color: #fff;
      position: absolute;
      top: 12.8rem;
      left: 6.99733rem;
      z-index: 1;
      transform: translateY(30px);
      opacity: 0;
      visibility: hidden;
      transition: 1s ease opacity, 1s ease transform, 0s 0s visibility; }
      #top #kv .kv__text.loaded {
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
        transition-delay: 0s; }
      #top #kv .kv__text h1 {
        font-size: 3.41333rem;
        line-height: 0.975;
        font-weight: 600;
        margin-bottom: 1.792rem; }
      #top #kv .kv__text p {
        font-size: 1.06667rem;
        line-height: 1.6;
        letter-spacing: 0.04em;
        font-weight: 700; }
    #top #kv .kv__scroll {
      color: #fff;
      width: 5.97333rem;
      position: absolute;
      right: 3.1808rem;
      bottom: 0;
      z-index: 1;
      transform: rotate(90deg);
      transform-origin: right bottom; }
      #top #kv .kv__scroll::after {
        content: "";
        width: 100%;
        height: 1px;
        background: currentColor;
        position: absolute;
        bottom: 0;
        left: 0; }
      #top #kv .kv__scroll p {
        font-family: "Barlow", sans-serif;
        font-size: 0.68267rem;
        line-height: 1.25;
        color: currentColor;
        font-weight: 600;
        margin-bottom: 0.36267rem; }
  #top #about {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    width: 100%;
    background: #fff;
    padding: 5.54667rem 0;
    position: relative; }
    #top #about .container {
      width: 100%;
      max-width: 34.13333rem;
      padding: 0 1.70667rem;
      margin: 0 auto; }
    #top #about .about__wrapper {
      margin-bottom: 6.82667rem; }
    #top #about .about__title {
      margin-bottom: 3.41333rem; }
    #top #about .about__texts {
      letter-spacing: 0.04em;
      margin-bottom: 3.15733rem; }
      #top #about .about__texts__heading {
        font-size: 1.28rem;
        line-height: 1.4;
        font-weight: 700;
        margin-bottom: 1.024rem; }
      #top #about .about__texts__desc {
        font-size: 0.85333rem;
        line-height: 1.8; }
    #top #about .about__btn {
      width: 100%; }
      #top #about .about__btn a {
        font-size: 0.85333rem;
        line-height: 1.5;
        font-weight: 700;
        color: #fff;
        text-align: center;
        display: inline-block;
        width: 100%;
        padding: 1.62133rem 0;
        background: #21589c;
        position: relative; }
        #top #about .about__btn a::after {
          content: "";
          width: 0.85333rem;
          height: 0.57003rem;
          background: url(../img/common/arrow_right.svg) center/contain no-repeat;
          position: absolute;
          top: 50%;
          right: 1.70667rem;
          transform: translateY(-50%);
          z-index: 0; }
    #top #about .about__img {
      width: 100%;
      height: 24.832rem; }
      #top #about .about__img img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
  #top #service {
    padding: 6.12267rem 0 6.80533rem;
    background: #f7f9fa; }
  #top #news {
    padding: 5.696rem 0 6.80533rem;
    background: #fff; }
    #top #news .news__title {
      margin-bottom: 3.2rem; }
    #top #news .news__list {
      margin-bottom: 2.56rem; }
    #top #news .news__item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
      padding: 1.28rem 0.74667rem;
      border-top: 0.04267rem solid #e5e5e5;
      position: relative;
      transition: 0.3s ease all; }
      #top #news .news__item:last-child {
        border-bottom: 0.04267rem solid #e5e5e5; }
      #top #news .news__item > a {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1; }
      #top #news .news__item__time {
        font-size: 0.85333rem;
        line-height: 1.2;
        margin-right: 0.85333rem; }
      #top #news .news__item__category {
        font-size: 0.68267rem;
        line-height: 1.5;
        color: #fff;
        text-align: center;
        width: 5.12rem;
        padding: 0.21333rem 0;
        border-radius: 0.85333rem; }
        #top #news .news__item__category.category01 {
          background: #77a8e5; }
        #top #news .news__item__category.category02 {
          background: #6dd9bc; }
        #top #news .news__item__category.category03 {
          background: #5fcad8; }
      #top #news .news__item__title {
        font-size: 0.85333rem;
        line-height: 1.5;
        letter-spacing: 0.04em;
        width: 100%;
        margin-top: 0.21333rem; }
    #top #news .news__btn {
      width: 100%; }
      #top #news .news__btn a {
        font-size: 0.85333rem;
        line-height: 1.5;
        font-weight: 700;
        color: #fff;
        text-align: center;
        display: inline-block;
        width: 100%;
        padding: 1.62133rem 0;
        background: #21589c;
        position: relative; }
        #top #news .news__btn a::after {
          content: "";
          width: 0.85333rem;
          height: 0.57003rem;
          background: url(../img/common/arrow_right.svg) center/contain no-repeat;
          position: absolute;
          top: 50%;
          right: 1.70667rem;
          transform: translateY(-50%);
          z-index: 0; } }

@media (min-width: 769px) {
  #about .sec .container {
    width: 100%;
    max-width: 32rem;
    padding: 0 3.6rem;
    margin: 0 auto;
    position: relative; }
  #about .sec-title {
    font-size: 0.64rem;
    line-height: 1.25;
    padding-bottom: 0.4rem;
    margin-bottom: 1.64rem;
    position: relative; }
    #about .sec-title::after {
      content: "";
      display: inline-block;
      width: 0.8rem;
      height: 0.04rem;
      background: #21589c;
      position: absolute;
      bottom: 0;
      left: 0; }
  #about #head {
    padding: 2rem 0;
    background: #fff;
    position: relative; }
    #about #head .container {
      padding: 0 1.2rem; }
    #about #head .head__contents {
      color: #fff;
      letter-spacing: 0.04em;
      padding: 2.04rem 2.4rem 2.2rem;
      position: relative; }
    #about #head .head__bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0; }
      #about #head .head__bg > div {
        width: 100%;
        height: 100%;
        background: #21589c center/cover no-repeat; }
    #about #head .head__box {
      position: relative; }
    #about #head .head__title {
      margin-bottom: 1.04rem; }
      #about #head .head__title h2 {
        font-size: 1.28rem;
        line-height: 1.1875;
        font-weight: 600;
        text-align: center;
        margin-bottom: 0.4rem; }
      #about #head .head__title p {
        font-size: 0.4rem;
        line-height: 1.45;
        font-weight: 700;
        text-align: center; }
    #about #head .head__desc {
      font-size: 0.32rem;
      line-height: 1.5;
      font-weight: 500;
      text-align: center;
      margin-bottom: 0.95rem; }
    #about #head .head__list {
      display: flex;
      align-items: center;
      justify-content: space-between; }
    #about #head .head__item {
      width: calc(20% - 0.16rem);
      background-color: rgba(33, 88, 156, 0.4);
      backdrop-filter: blur(10px) brightness(1.5); }
  #about #message {
    padding: 1.96rem 0;
    background: #f7f9fa; }
    #about #message .message__item {
      letter-spacing: 0.04em;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding-bottom: 1.6rem; }
      #about #message .message__item:nth-child(n + 2) {
        border-top: 0.02rem solid #e5e5e5;
        padding-top: 1.6rem; }
      #about #message .message__item:last-child {
        padding-bottom: 0; }
      #about #message .message__item__img {
        width: 4.8rem;
        margin-right: 1.6rem; }
      #about #message .message__item__body {
        flex: 1; }
      #about #message .message__item__text {
        font-size: 0.36rem;
        line-height: 1.77778;
        margin-bottom: 0.76rem; }
        #about #message .message__item__text p + p {
          margin-top: 0.64rem; }
      #about #message .message__item__name {
        font-size: 0.36rem;
        line-height: 1.77778;
        font-weight: 700; }
  #about #overview {
    padding: 2rem 0; }
    #about #overview .overview__title {
      margin-bottom: 0.2rem; }
    #about #overview .overview__item {
      font-size: 0.32rem;
      line-height: 1.75;
      letter-spacing: 0.04em;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 0.76rem 0.36rem;
      border-top: 0.02rem solid #e5e5e5; }
      #about #overview .overview__item:last-child {
        border-bottom: 0.02rem solid #e5e5e5; }
      #about #overview .overview__item__label {
        font-weight: 500;
        width: 4.76rem; }
      #about #overview .overview__item__body {
        flex: 1; }
        #about #overview .overview__item__body a {
          text-decoration: underline; }
      #about #overview .overview__item__posts {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start; }
        #about #overview .overview__item__posts__post {
          width: 2.96rem; }
  #about #lang, #about #lang2 {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    margin-bottom: 0.772rem; }
    #about #lang input[type="radio"], #about #lang2 input[type="radio"] {
      display: none; }
    #about #lang label, #about #lang2 label {
      display: inline-block;
      font-size: 0.3rem;
      line-height: 2.13333;
      letter-spacing: 0.04em;
      border: 0.02rem solid #2F5897;
      border-collapse: collapse;
      padding: 0.2rem 0.3rem;
      color: #21589C;
      cursor: pointer; }
    #about #lang input[type="radio"]:checked + label, #about #lang2 input[type="radio"]:checked + label {
      background: #2F5897;
      color: #FFFFFF; }
  #about #history {
    padding: 1.96rem 0;
    background: #f7f9fa; }
    #about #history .history__list + .history__list {
      margin-top: 1.6rem; }
    #about #history .history__list__title {
      font-size: 0.36rem;
      line-height: 1.66667;
      letter-spacing: 0.04em;
      font-weight: 700;
      padding-left: 0.36rem;
      margin-bottom: 0.8rem;
      position: relative; }
      #about #history .history__list__title::before {
        content: "";
        display: inline-block;
        width: 0.14rem;
        height: 0.14rem;
        background: #21589c;
        position: absolute;
        left: 0;
        top: 0.23rem; }
    #about #history .history__list__item {
      font-size: 0.32rem;
      line-height: 1.75;
      letter-spacing: 0.04em;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 0.76rem 0.36rem;
      border-top: 0.02rem solid #e5e5e5; }
      #about #history .history__list__item:last-child {
        border-bottom: 0.02rem solid #e5e5e5; }
      #about #history .history__list__item__time {
        font-weight: 500;
        width: 4.76rem; }
      #about #history .history__list__item__body {
        flex: 1; }
  #about #access {
    padding: 2rem 0; }
    #about #access .access__title {
      margin-bottom: 0.2rem; }
    #about #access .access__item {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 1.2rem 0;
      border-top: 0.02rem solid #e5e5e5; }
      #about #access .access__item:last-child {
        border-bottom: 0.02rem solid #e5e5e5; }
      #about #access .access__item__body {
        letter-spacing: 0.04em;
        flex: 1; }
      #about #access .access__item__name {
        font-size: 0.36rem;
        line-height: 1.66667;
        font-weight: 700;
        padding-left: 0.36rem;
        position: relative; }
        #about #access .access__item__name::before {
          content: "";
          display: inline-block;
          width: 0.14rem;
          height: 0.14rem;
          background: #21589c;
          position: absolute;
          left: 0;
          top: 0.23rem; }
      #about #access .access__item__address, #about #access .access__item__tel {
        font-size: 0.32rem;
        line-height: 1.875; }
      #about #access .access__item__map {
        width: 12.8rem;
        height: 6.8rem; }
        #about #access .access__item__map iframe {
          width: 100%;
          height: 100%; }
  #about #others {
    padding: 2rem 0;
    background: #f7f9fa;
    margin-bottom: 0.8rem; }
    #about #others .others__item {
      font-size: 0.32rem;
      line-height: 1.75;
      letter-spacing: 0.04em;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 0.76rem 0 0.76rem 0.36rem;
      border-top: 0.02rem solid #e5e5e5; }
      #about #others .others__item:last-child {
        border-bottom: 0.02rem solid #e5e5e5; }
      #about #others .others__item__label {
        font-weight: 500;
        width: 6.4rem; }
      #about #others .others__item__body {
        flex: 1; }
        #about #others .others__item__body a {
          text-decoration: underline; }
      #about #others .others__item__desc {
        font-size: 0.28rem;
        line-height: 1.71429;
        margin-top: 0.48rem; } }

@media (max-width: 768px) {
  #about .sec .container {
    width: 100%;
    max-width: 32rem;
    height: 100%;
    padding: 0 1.70667rem;
    margin: 0 auto;
    position: relative; }
  #about .sec-title {
    font-size: 1.36533rem;
    line-height: 1.25;
    padding-bottom: 0.85333rem;
    margin-bottom: 3.49867rem;
    position: relative; }
    #about .sec-title::after {
      content: "";
      display: inline-block;
      width: 1.70667rem;
      height: 0.08533rem;
      background: #21589c;
      position: absolute;
      bottom: 0;
      left: 0; }
  #about #head {
    padding: 4.26667rem 0;
    background: #fff;
    position: relative; }
    #about #head .container {
      width: 100%;
      padding: 0; }
    #about #head .head__contents {
      color: #fff;
      letter-spacing: 0.04em;
      padding: 6.18667rem 1.70667rem 6.82667rem;
      position: relative; }
    #about #head .head__bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0; }
      #about #head .head__bg > div {
        width: 100%;
        height: 100%;
        background: #21589c center/cover no-repeat; }
    #about #head .head__box {
      position: relative; }
    #about #head .head__title {
      margin-bottom: 3.008rem; }
      #about #head .head__title h2 {
        font-size: 2.73067rem;
        line-height: 1.1875;
        font-weight: 600;
        margin-bottom: 1.28rem; }
      #about #head .head__title p {
        font-size: 1.024rem;
        line-height: 1.75;
        font-weight: 700; }
    #about #head .head__desc {
      font-size: 0.85333rem;
      line-height: 1.45;
      font-weight: 500;
      margin-bottom: 2.45333rem; }
    #about #head .head__list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap; }
    #about #head .head__item {
      width: calc(50% - 0.21333rem);
      background-color: rgba(33, 88, 156, 0.4);
      backdrop-filter: blur(10px) brightness(1.5); }
      #about #head .head__item:nth-child(n + 3) {
        margin-top: 0.42667rem; }
  #about #message {
    padding: 4.18133rem 0;
    background: #f7f9fa; }
    #about #message .message__item {
      letter-spacing: 0.04em;
      padding-bottom: 3.41333rem; }
      #about #message .message__item:nth-child(n + 2) {
        border-top: 0.04267rem solid #e5e5e5;
        padding-top: 3.41333rem; }
      #about #message .message__item:last-child {
        padding-bottom: 0; }
      #about #message .message__item__img {
        width: 10.24rem;
        margin: 0 auto 3.41333rem; }
      #about #message .message__item__body {
        flex: 1; }
      #about #message .message__item__text {
        font-size: 0.93867rem;
        line-height: 1.45455;
        margin-bottom: 1.62133rem; }
        #about #message .message__item__text p + p {
          margin-top: 1.36533rem; }
      #about #message .message__item__name {
        font-size: 1.024rem;
        line-height: 1.33333;
        font-weight: 700; }
  #about #overview {
    padding: 4.26667rem 0; }
    #about #overview .overview__title {
      margin-bottom: 0.42667rem; }
    #about #overview .overview__item {
      font-size: 0.93867rem;
      line-height: 1.45455;
      letter-spacing: 0.04em;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 1.62133rem 0.768rem;
      border-top: 0.04267rem solid #e5e5e5; }
      #about #overview .overview__item:last-child {
        border-bottom: 0.04267rem solid #e5e5e5; }
      #about #overview .overview__item__label {
        font-weight: 500;
        width: 7.68rem; }
      #about #overview .overview__item__body {
        flex: 1; }
        #about #overview .overview__item__body a {
          text-decoration: underline; }
      #about #overview .overview__item__posts {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start; }
        #about #overview .overview__item__posts__post {
          width: 8.53333rem; }
  #about #lang, #about #lang2 {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    margin-bottom: 1.64693rem; }
    #about #lang input[type="radio"], #about #lang2 input[type="radio"] {
      display: none; }
    #about #lang label, #about #lang2 label {
      display: inline-block;
      font-size: 0.64rem;
      line-height: 2.13333;
      letter-spacing: 0.04em;
      border: 0.04267rem solid #2F5897;
      border-collapse: collapse;
      padding: 0.42667rem 0.64rem;
      color: #21589C;
      cursor: pointer; }
    #about #lang input[type="radio"]:checked + label, #about #lang2 input[type="radio"]:checked + label {
      background: #2F5897;
      color: #FFFFFF; }
  #about #history {
    padding: 4.18133rem 0;
    background: #f7f9fa; }
    #about #history .history__list + .history__list {
      margin-top: 3.41333rem; }
    #about #history .history__list__title {
      font-size: 0.93867rem;
      line-height: 1.45455;
      letter-spacing: 0.04em;
      font-weight: 700;
      padding-left: 0.93867rem;
      margin-bottom: 1.70667rem;
      position: relative; }
      #about #history .history__list__title::before {
        content: "";
        display: inline-block;
        width: 0.29867rem;
        height: 0.29867rem;
        background: #21589c;
        position: absolute;
        left: 0;
        top: 0.49067rem; }
    #about #history .history__list__item {
      font-size: 0.93867rem;
      line-height: 1.45455;
      letter-spacing: 0.04em;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 1.62133rem 0.768rem;
      border-top: 0.04267rem solid #e5e5e5; }
      #about #history .history__list__item:last-child {
        border-bottom: 0.04267rem solid #e5e5e5; }
      #about #history .history__list__item__time {
        font-weight: 500;
        width: 7.68rem; }
      #about #history .history__list__item__body {
        flex: 1; }
  #about #access {
    padding: 4.26667rem 0; }
    #about #access .access__title {
      margin-bottom: 0.42667rem; }
    #about #access .access__item {
      padding: 3.41333rem 0;
      border-top: 0.04267rem solid #e5e5e5; }
      #about #access .access__item:last-child {
        border-bottom: 0.04267rem solid #e5e5e5; }
      #about #access .access__item__body {
        letter-spacing: 0.04em;
        margin-bottom: 3.41333rem; }
      #about #access .access__item__name {
        font-size: 1.10933rem;
        line-height: 1.38462;
        font-weight: 700;
        padding-left: 1.10933rem;
        position: relative; }
        #about #access .access__item__name::before {
          content: "";
          display: inline-block;
          width: 0.29867rem;
          height: 0.29867rem;
          background: #21589c;
          position: absolute;
          left: 0;
          top: 0.49067rem; }
      #about #access .access__item__address, #about #access .access__item__tel {
        font-size: 1.024rem;
        line-height: 1.5; }
      #about #access .access__item__map {
        width: 100%;
        height: 20.48rem; }
        #about #access .access__item__map iframe {
          width: 100%;
          height: 100%; }
  #about #others {
    padding: 4.26667rem 0;
    background: #f7f9fa;
    margin-bottom: 1.70667rem; }
    #about #others .others__item {
      font-size: 1.024rem;
      line-height: 1.33333;
      letter-spacing: 0.04em;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 1.62133rem 0 1.62133rem 0.768rem;
      border-top: 0.04267rem solid #e5e5e5; }
      #about #others .others__item:last-child {
        border-bottom: 0.04267rem solid #e5e5e5; }
      #about #others .others__item__label {
        font-weight: 500;
        width: 7.68rem;
        margin-right: 0.85333rem; }
      #about #others .others__item__body {
        flex: 1; }
        #about #others .others__item__body a {
          text-decoration: underline; }
      #about #others .others__item__desc {
        font-size: 0.93867rem;
        line-height: 1.09091;
        margin-top: 1.024rem; } }

@media (min-width: 769px) {
  #service-list .sec .container {
    width: 100%;
    max-width: 32rem;
    padding: 0 3.6rem;
    margin: 0 auto;
    position: relative; }
  #service-list .sec-title {
    font-size: 0.64rem;
    line-height: 1.25;
    padding-bottom: 0.4rem;
    margin-bottom: 1.64rem;
    position: relative; }
    #service-list .sec-title::after {
      content: "";
      display: inline-block;
      width: 0.8rem;
      height: 0.04rem;
      background: #21589c;
      position: absolute;
      bottom: 0;
      left: 0; }
  #service-list #service {
    padding: 2.06rem 0 4rem; }
    #service-list #service .service__item {
      background: #f7f9fa; }
  #service-item .sec .container {
    width: 100%;
    max-width: 32rem;
    padding: 0 3.6rem;
    margin: 0 auto;
    position: relative; }
  #service-item .sec-title {
    font-size: 0.56rem;
    line-height: 1.42857;
    font-weight: 700;
    color: #21589c;
    margin-bottom: 0.52rem; }
  #service-item #head {
    letter-spacing: 0.04em;
    font-weight: 700;
    padding: 2.08rem 0 3.04rem;
    background: #fff; }
    #service-item #head .head__title {
      font-size: 0.72rem;
      line-height: 1.5;
      padding-bottom: 0.24rem;
      position: relative; }
      #service-item #head .head__title::after {
        content: "";
        display: inline-block;
        width: 0.8rem;
        height: 0.04rem;
        background: #21589c;
        position: absolute;
        bottom: 0;
        left: 0; }
    #service-item #head .head__desc {
      font-size: 0.36rem;
      line-height: 1.77778;
      margin-top: 1.44rem; }
  #service-item #business {
    background: #f7f9fa;
    padding: 2rem 0 1.86rem;
    position: relative; }
    #service-item #business .business__blocks {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap; }
      #service-item #business .business__blocks__block {
        width: 11.6rem;
        height: 6.4rem;
        background: #fff;
        padding: 1.082rem 1.2rem 0;
        margin-bottom: 0.8rem; }
        #service-item #business .business__blocks__block__title {
          font-size: 0.48rem;
          line-height: 1.66667;
          letter-spacing: 0.04em;
          font-weight: 700;
          padding-bottom: 0.354rem;
          margin-bottom: 0.496rem;
          border-bottom: 1px solid #DDDDDD; }
        #service-item #business .business__blocks__block__name {
          font-size: 0.4rem;
          color: #21589C;
          line-height: 2;
          letter-spacing: 0.04em;
          font-weight: 700;
          margin-bottom: 0.226rem; }
        #service-item #business .business__blocks__block__desc {
          font-size: 0.32rem;
          line-height: 1.875;
          letter-spacing: 0.04em; }
      #service-item #business .business__blocks__block:first-child {
        margin-right: auto; }
    #service-item #business .business__list li {
      font-size: 0.32rem;
      line-height: 1.875;
      letter-spacing: 0.04em; }
  #service-item #uniqueness {
    background: #fff;
    padding: 2rem 0 1.966rem;
    position: relative; }
    #service-item #uniqueness .uniqueness__list li {
      font-size: 0.32rem;
      line-height: 1.875;
      letter-spacing: 0.04em; }
  #service-item #task {
    background: #f7f9fa;
    padding: 1.86rem 0 1.64rem;
    position: relative; }
    #service-item #task .task__h4 {
      font-size: 0.4rem;
      line-height: 1.6;
      font-weight: 700;
      margin-bottom: 0.32rem; }
    #service-item #task .task__list li {
      font-size: 0.36rem;
      line-height: 2.22222;
      font-weight: 500;
      padding-left: 0.4rem;
      position: relative; }
      #service-item #task .task__list li::before {
        content: "";
        display: inline-block;
        width: 0.14rem;
        height: 0.14rem;
        background: #21589c;
        position: absolute;
        left: 0;
        top: 0.33rem; }
  #service-item #offer {
    background: #fff;
    padding: 1.93rem 0 1.52rem;
    position: relative; }
    #service-item #offer .offer__contents {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap; }
    #service-item #offer .offer__list {
      letter-spacing: 0.04em;
      width: calc(100% / 3);
      padding-right: 1.04rem; }
      #service-item #offer .offer__list.only {
        width: 100%; }
      #service-item #offer .offer__list:nth-child(3n) {
        padding-right: 0; }
      #service-item #offer .offer__list__title {
        font-size: 0.4rem;
        line-height: 1.4;
        font-weight: 700;
        color: #21589c;
        margin-bottom: 0.44rem; }
      #service-item #offer .offer__list li {
        font-size: 0.36rem;
        line-height: 2.22222;
        font-weight: 500;
        padding-left: 0.4rem;
        position: relative; }
        #service-item #offer .offer__list li::before {
          content: "";
          display: inline-block;
          width: 0.14rem;
          height: 0.14rem;
          background: #21589c;
          position: absolute;
          left: 0;
          top: 0.33rem; }
    #service-item #offer .offer__list:nth-child(n + 4) {
      margin-top: 0.62rem; }
  #service-item #feature {
    background: #f7f9fa;
    padding: 1.86rem 0 1.64rem;
    position: relative; }
    #service-item #feature .feature__blocks {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start; }
      #service-item #feature .feature__blocks__block {
        width: 7.6rem;
        height: 8.66rem;
        background: #fff;
        padding: 1.78rem 0.34rem 0;
        margin-right: auto; }
        #service-item #feature .feature__blocks__block__img {
          width: 2.6684rem;
          margin: 0 auto;
          margin-bottom: 1.416rem; }
        #service-item #feature .feature__blocks__block__sub {
          display: table;
          font-size: 0.32rem;
          line-height: 2;
          letter-spacing: 0.04em;
          margin: 0 auto; }
        #service-item #feature .feature__blocks__block__title {
          display: table;
          font-size: 0.48rem;
          line-height: 1.5;
          font-weight: 700;
          color: #21589c;
          margin: 0.366rem auto; }
        #service-item #feature .feature__blocks__block__desc {
          display: table;
          font-size: 0.3rem;
          line-height: 1.86667;
          margin: 0 auto; }
      #service-item #feature .feature__blocks__block:nth-child(n+2) {
        padding: 1.12rem 0.34rem 0; }
        #service-item #feature .feature__blocks__block:nth-child(n+2) .feature__blocks__block__img {
          width: 2.2242rem;
          margin-bottom: 0.748rem; }
      #service-item #feature .feature__blocks__block:last-child {
        margin-right: 0; }
    #service-item #feature .feature__list li {
      font-size: 0.36rem;
      line-height: 2.22222;
      font-weight: 500;
      padding-left: 0.4rem;
      position: relative; }
      #service-item #feature .feature__list li::before {
        content: "";
        display: inline-block;
        width: 0.14rem;
        height: 0.14rem;
        background: #21589c;
        position: absolute;
        left: 0;
        top: 0.33rem; }
  #service-item #flow {
    background: #fff;
    padding: 2rem 0;
    position: relative; }
    #service-item #flow .flow__desc {
      font-size: 0.32rem;
      line-height: 1.875;
      letter-spacing: 0.04em;
      font-weight: 500;
      margin-bottom: 1.08rem; }
    #service-item #flow .flow__contents {
      display: flex;
      align-items: stretch;
      justify-content: flex-start; }
    #service-item #flow .flow__item {
      height: 3rem;
      flex: 1;
      background: #f7f9fa;
      position: relative; }
      #service-item #flow .flow__item:nth-child(n + 2) {
        margin-left: 0.2rem; }
      #service-item #flow .flow__item:last-child::before, #service-item #flow .flow__item:last-child::after {
        content: none; }
      #service-item #flow .flow__item:last-child .flow__item__wrapper {
        margin-left: 0; }
      #service-item #flow .flow__item::before, #service-item #flow .flow__item::after {
        content: "";
        width: 0.64rem;
        height: 1.5rem;
        display: inline-block;
        position: absolute;
        right: 0; }
      #service-item #flow .flow__item::before {
        top: 0;
        border-top: 0.75rem solid #fff;
        border-right: 0.32rem solid #fff;
        border-bottom: 0.75rem solid transparent;
        border-left: 0.32rem solid transparent; }
      #service-item #flow .flow__item::after {
        bottom: 0;
        border-top: 0.75rem solid transparent;
        border-right: 0.32rem solid #fff;
        border-bottom: 0.75rem solid #fff;
        border-left: 0.32rem solid transparent; }
      #service-item #flow .flow__item__wrapper {
        letter-spacing: 0.04em;
        text-align: center;
        white-space: nowrap;
        margin-left: -0.2rem;
        position: absolute;
        top: 0.56rem;
        left: 50%;
        z-index: 2;
        transform: translateX(-50%); }
      #service-item #flow .flow__item__number {
        font-size: 0.28rem;
        line-height: 1.21429;
        font-weight: 600;
        color: #21589c;
        margin-bottom: 0.18rem; }
      #service-item #flow .flow__item__text {
        font-size: 0.32rem;
        line-height: 1.5;
        font-weight: 700; }
  #service-item #case {
    background: #f7f9fa;
    padding: 1.86rem 0 1.64rem;
    position: relative; }
    #service-item #case .case__block {
      background: #fff;
      padding: 1rem 1.2rem 0.88rem;
      margin-bottom: 0.8rem; }
      #service-item #case .case__block__title {
        font-size: 0.48rem;
        line-height: 1.66667;
        letter-spacing: 0.04em;
        font-weight: 700;
        padding-bottom: 0.354rem;
        margin-bottom: 0.496rem;
        border-bottom: 1px solid #DDDDDD; }
      #service-item #case .case__block__lists h5 {
        font-size: 0.4rem;
        line-height: 2;
        font-weight: 700;
        color: #21589C; }
      #service-item #case .case__block__lists__ul li {
        font-size: 0.36rem;
        line-height: 2.22222;
        font-weight: 500;
        padding-left: 0.4rem;
        position: relative; }
        #service-item #case .case__block__lists__ul li::before {
          content: "";
          display: inline-block;
          width: 0.14rem;
          height: 0.14rem;
          background: #21589c;
          position: absolute;
          left: 0;
          top: 0.33rem; }
      #service-item #case .case__block__lists:nth-child(2) {
        margin-bottom: 0.392rem; }
  #service-item #margin {
    background: #fff;
    padding-top: 0.82rem;
    position: relative; }
  #service-item #suppliers {
    background: #f7f9fa;
    padding: 2rem 0 2rem;
    position: relative; }
    #service-item #suppliers .suppliers__list {
      width: 100%;
      display: flex;
      align-items: stretch;
      justify-content: space-between; }
      #service-item #suppliers .suppliers__list li {
        width: 4rem;
        height: 4rem;
        background: #fff;
        padding-top: 1.78rem; }
        #service-item #suppliers .suppliers__list li img {
          width: 3.0186rem; }
  #service-item #contact {
    padding: 1.8rem 0 2.56rem;
    background: #f7f9fa;
    position: relative; }
    #service-item #contact .contact__title {
      font-size: 0.56rem;
      line-height: 1.42857;
      font-weight: 700;
      color: #21589c;
      text-align: center;
      margin-bottom: 1.16rem; }
    #service-item #contact .contact__btn {
      width: 12.8rem;
      margin: 0 auto; }
      #service-item #contact .contact__btn a {
        font-size: 0.4rem;
        line-height: 1.4;
        font-weight: 700;
        color: #fff;
        text-align: center;
        display: inline-block;
        width: 100%;
        padding: 0.92rem 0;
        background: #21589c;
        position: relative; }
        #service-item #contact .contact__btn a::after {
          content: "";
          width: 0.3664rem;
          height: 0.2448rem;
          background: url(../img/common/arrow_right.svg) center/contain no-repeat;
          position: absolute;
          top: 50%;
          right: 0.672rem;
          transform: translateY(-50%);
          z-index: 0; }
  #service-item #links {
    padding: 1.84rem 0 2.44rem;
    background: #fff;
    position: relative; }
    #service-item #links .links__title {
      font-size: 0.56rem;
      line-height: 2.42857;
      letter-spacing: 0.04em;
      font-weight: 700;
      color: #21589c;
      text-align: center;
      border: 0.02rem solid currentColor;
      margin-bottom: 0.96rem; }
    #service-item #links .links__contents {
      margin-bottom: 1.6rem; }
    #service-item #links .links__block:nth-child(n + 2) {
      margin-top: 0.76rem; }
    #service-item #links .links__block__title {
      font-size: 0.4rem;
      line-height: 1.5;
      letter-spacing: 0.04em;
      font-weight: 700;
      padding-left: 0.6rem;
      margin-bottom: 0.72rem;
      position: relative; }
      #service-item #links .links__block__title::before {
        content: "";
        width: 0.4rem;
        height: 0.04rem;
        background: currentColor;
        position: absolute;
        top: 0.28rem;
        left: 0; }
    #service-item #links .links__list {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap; }
    #service-item #links .links__item {
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      width: calc(50% - 0.4rem);
      min-height: 2.4rem;
      background: #f7f9fa;
      position: relative;
      transition: 0.3s ease all; }
      #service-item #links .links__item:hover {
        opacity: 0.7; }
      #service-item #links .links__item > a {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1; }
      #service-item #links .links__item:nth-child(n + 3) {
        margin-top: 0.4rem; }
      #service-item #links .links__item__img {
        width: 2.4rem;
        height: 2.4rem; }
      #service-item #links .links__item__content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        padding: 0 0.76rem; }
      #service-item #links .links__item__title {
        font-size: 0.4rem;
        line-height: 1.2;
        letter-spacing: 0.04em;
        font-weight: 700;
        color: #21589c;
        margin-bottom: 0.15rem; }
      #service-item #links .links__item__desc {
        font-size: 0.3rem;
        line-height: 1.4;
        letter-spacing: 0.04em;
        color: #21589c; }
  #service-item #call-back {
    padding: 0 0 2.44rem;
    background: #fff;
    position: relative; }
    #service-item #call-back .call-back__blocks {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between; }
      #service-item #call-back .call-back__blocks__block {
        width: 12rem; }
        #service-item #call-back .call-back__blocks__block__img {
          width: 100%;
          margin-bottom: 0.52rem; }
        #service-item #call-back .call-back__blocks__block__title {
          font-size: 0.48rem;
          line-height: 1;
          letter-spacing: 0.04em;
          font-weight: 700;
          color: #21589c;
          margin-bottom: 0.296rem; }
        #service-item #call-back .call-back__blocks__block__desc {
          font-size: 0.3rem;
          line-height: 1.6;
          letter-spacing: 0.04em; } }

@media (max-width: 768px) {
  #service-list .sec .container {
    width: 100%;
    max-width: 32rem;
    height: 100%;
    padding: 0 1.70667rem;
    margin: 0 auto;
    position: relative; }
  #service-list .sec-title {
    font-size: 1.36533rem;
    line-height: 1.25;
    padding-bottom: 0.85333rem;
    margin-bottom: 3.49867rem;
    position: relative; }
    #service-list .sec-title::after {
      content: "";
      display: inline-block;
      width: 1.70667rem;
      height: 0.08533rem;
      background: #21589c;
      position: absolute;
      bottom: 0;
      left: 0; }
  #service-list #service {
    padding: 4.39467rem 0 8.53333rem; }
    #service-list #service .service__item {
      background: #f7f9fa; }
  #service-item .sec .container {
    width: 100%;
    max-width: 32rem;
    padding: 0 1.70667rem;
    margin: 0 auto;
    position: relative; }
  #service-item .sec-title {
    font-size: 1.19467rem;
    line-height: 1.42857;
    font-weight: 700;
    color: #21589c;
    margin-bottom: 1.62133rem; }
  #service-item #head {
    letter-spacing: 0.04em;
    font-weight: 700;
    padding: 4.43733rem 0 6.48533rem;
    background: #fff; }
    #service-item #head .head__title {
      font-size: 1.536rem;
      line-height: 1.5;
      padding-bottom: 0.512rem;
      position: relative; }
      #service-item #head .head__title::after {
        content: "";
        display: inline-block;
        width: 1.70667rem;
        height: 0.08533rem;
        background: #21589c;
        position: absolute;
        bottom: 0;
        left: 0; }
    #service-item #head .head__desc {
      font-size: 0.768rem;
      line-height: 1.77778;
      margin-top: 3.072rem; }
  #service-item #business {
    background: #f7f9fa;
    padding: 4.26667rem 0 3.968rem;
    position: relative; }
    #service-item #business .business__blocks {
      width: 100%; }
      #service-item #business .business__blocks__block {
        width: 100%;
        height: 13.65333rem;
        background: #fff;
        padding: 2.30827rem 2.56rem 0;
        margin-bottom: 1.70667rem; }
        #service-item #business .business__blocks__block__title {
          font-size: 1.024rem;
          line-height: 1.66667;
          letter-spacing: 0.04em;
          font-weight: 700;
          padding-bottom: 0.7552rem;
          margin-bottom: 1.05813rem;
          border-bottom: 1px solid #DDDDDD; }
        #service-item #business .business__blocks__block__name {
          font-size: 0.85333rem;
          color: #21589C;
          line-height: 2;
          letter-spacing: 0.04em;
          font-weight: 700;
          margin-bottom: 0.48213rem; }
        #service-item #business .business__blocks__block__desc {
          font-size: 0.68267rem;
          line-height: 1.875;
          letter-spacing: 0.04em; }
      #service-item #business .business__blocks__block:first-child {
        margin-right: auto; }
    #service-item #business .business__list li {
      font-size: 0.68267rem;
      line-height: 1.875;
      letter-spacing: 0.04em; }
  #service-item #uniqueness {
    background: #fff;
    padding: 4.26667rem 0 4.19413rem;
    position: relative; }
    #service-item #uniqueness .uniqueness__list li {
      font-size: 0.68267rem;
      line-height: 1.875;
      letter-spacing: 0.04em; }
  #service-item #task {
    background: #f7f9fa;
    padding: 3.968rem 0 3.49867rem;
    position: relative; }
    #service-item #task .task__h4 {
      font-size: 0.85333rem;
      line-height: 1.6;
      font-weight: 700;
      margin-bottom: 0.68267rem; }
    #service-item #task .task__list li {
      font-size: 0.768rem;
      line-height: 2.22222;
      font-weight: 500;
      padding-left: 0.85333rem;
      position: relative; }
      #service-item #task .task__list li::before {
        content: "";
        display: inline-block;
        width: 0.29867rem;
        height: 0.29867rem;
        background: #21589c;
        position: absolute;
        left: 0;
        top: 0.704rem; }
  #service-item #offer {
    background: #fff;
    padding: 4.11733rem 0 3.24267rem;
    position: relative; }
    #service-item #offer .offer__list {
      letter-spacing: 0.04em; }
      #service-item #offer .offer__list:nth-child(n + 2) {
        margin-top: 1.62133rem; }
      #service-item #offer .offer__list__title {
        font-size: 0.85333rem;
        line-height: 1.4;
        font-weight: 700;
        color: #21589c;
        margin-bottom: 0.93867rem; }
      #service-item #offer .offer__list li {
        font-size: 0.768rem;
        line-height: 2.22222;
        font-weight: 500;
        padding-left: 0.85333rem;
        position: relative; }
        #service-item #offer .offer__list li::before {
          content: "";
          display: inline-block;
          width: 0.29867rem;
          height: 0.29867rem;
          background: #21589c;
          position: absolute;
          left: 0;
          top: 0.704rem; }
  #service-item #feature {
    background: #f7f9fa;
    padding: 3.968rem 0 3.49867rem;
    position: relative; }
    #service-item #feature .feature__blocks {
      width: 100%; }
      #service-item #feature .feature__blocks__block {
        width: 100%;
        height: 18.47467rem;
        background: #fff;
        padding: 3.79733rem 0.72533rem 0;
        margin-bottom: 1.28rem; }
        #service-item #feature .feature__blocks__block__img {
          width: 5.69259rem;
          margin: 0 auto;
          margin-bottom: 3.0208rem; }
        #service-item #feature .feature__blocks__block__sub {
          display: table;
          font-size: 0.68267rem;
          line-height: 2;
          letter-spacing: 0.04em;
          margin: 0 auto; }
        #service-item #feature .feature__blocks__block__title {
          display: table;
          font-size: 1.024rem;
          line-height: 1.5;
          font-weight: 700;
          color: #21589c;
          margin: 0.7808rem auto; }
        #service-item #feature .feature__blocks__block__desc {
          display: table;
          font-size: 0.64rem;
          line-height: 1.86667;
          margin: 0 auto; }
      #service-item #feature .feature__blocks__block:nth-child(n+2) {
        padding: 2.38933rem 0.72533rem 0; }
        #service-item #feature .feature__blocks__block:nth-child(n+2) .feature__blocks__block__img {
          width: 4.74496rem;
          margin-bottom: 1.59573rem; }
    #service-item #feature .feature__list li {
      font-size: 0.768rem;
      line-height: 2.22222;
      font-weight: 500;
      padding-left: 0.85333rem;
      position: relative; }
      #service-item #feature .feature__list li::before {
        content: "";
        display: inline-block;
        width: 0.29867rem;
        height: 0.29867rem;
        background: #21589c;
        position: absolute;
        left: 0;
        top: 0.704rem; }
  #service-item #case {
    background: #f7f9fa;
    padding: 3.968rem 0 3.49867rem;
    position: relative; }
    #service-item #case .case__block {
      background: #fff;
      padding: 2.13333rem 2.56rem 1.87733rem;
      margin-bottom: 1.70667rem; }
      #service-item #case .case__block__title {
        font-size: 1.024rem;
        line-height: 1.66667;
        letter-spacing: 0.04em;
        font-weight: 700;
        padding-bottom: 0.7552rem;
        margin-bottom: 1.05813rem;
        border-bottom: 1px solid #DDDDDD; }
      #service-item #case .case__block__lists h5 {
        font-size: 0.85333rem;
        line-height: 2;
        font-weight: 700;
        color: #21589C; }
      #service-item #case .case__block__lists__ul li {
        font-size: 0.768rem;
        line-height: 2.22222;
        font-weight: 500;
        padding-left: 0.85333rem;
        position: relative; }
        #service-item #case .case__block__lists__ul li::before {
          content: "";
          display: inline-block;
          width: 0.29867rem;
          height: 0.29867rem;
          background: #21589c;
          position: absolute;
          left: 0;
          top: 0.704rem; }
      #service-item #case .case__block__lists:nth-child(2) {
        margin-bottom: 0.83627rem; }
  #service-item #margin {
    background: #fff;
    padding-top: 1.74933rem;
    position: relative; }
  #service-item #flow {
    background: #fff;
    padding: 4.26667rem 0;
    position: relative; }
    #service-item #flow .flow__desc {
      font-size: 0.68267rem;
      line-height: 1.875;
      letter-spacing: 0.04em;
      font-weight: 500;
      margin-bottom: 2.304rem; }
    #service-item #flow .flow__contents {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex-wrap: wrap; }
    #service-item #flow .flow__item {
      width: 12.8rem;
      height: 8.53333rem;
      background: #f7f9fa;
      position: relative; }
      #service-item #flow .flow__item:nth-child(n + 2) {
        margin-top: 0.42667rem; }
      #service-item #flow .flow__item:last-child::before, #service-item #flow .flow__item:last-child::after {
        content: none; }
      #service-item #flow .flow__item:last-child .flow__item__wrapper {
        margin-top: 0; }
      #service-item #flow .flow__item::before, #service-item #flow .flow__item::after {
        content: "";
        width: 6.4rem;
        height: 2.13333rem;
        display: inline-block;
        position: absolute;
        bottom: 0; }
      #service-item #flow .flow__item::before {
        left: 0;
        border-top: 1.06667rem solid transparent;
        border-right: 3.2rem solid transparent;
        border-bottom: 1.06667rem solid #fff;
        border-left: 3.2rem solid #fff; }
      #service-item #flow .flow__item::after {
        right: 0;
        border-top: 1.06667rem solid transparent;
        border-right: 3.2rem solid #fff;
        border-bottom: 1.06667rem solid #fff;
        border-left: 3.2rem solid transparent; }
      #service-item #flow .flow__item__wrapper {
        letter-spacing: 0.04em;
        text-align: center;
        white-space: nowrap;
        margin-top: -0.768rem;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        transform: translate(-50%, -50%); }
      #service-item #flow .flow__item__number {
        font-size: 0.59733rem;
        line-height: 1.21429;
        font-weight: 600;
        color: #21589c;
        margin-bottom: 0.384rem; }
      #service-item #flow .flow__item__text {
        font-size: 0.68267rem;
        line-height: 1.5;
        font-weight: 700; }
  #service-item #suppliers {
    background: #f7f9fa;
    padding: 4.26667rem 0 4.26667rem;
    position: relative; }
    #service-item #suppliers .suppliers__list {
      width: 100%; }
      #service-item #suppliers .suppliers__list li {
        width: 8.53333rem;
        height: 8.53333rem;
        background: #fff;
        padding-top: 3.79733rem;
        margin: 0.85333rem auto; }
        #service-item #suppliers .suppliers__list li img {
          width: 6.43968rem; }
  #service-item #contact {
    padding: 3.84rem 0 5.46133rem;
    background: #f7f9fa;
    position: relative; }
    #service-item #contact .contact__title {
      font-size: 1.19467rem;
      line-height: 1.42857;
      font-weight: 700;
      color: #21589c;
      text-align: center;
      margin-bottom: 2.47467rem; }
    #service-item #contact .contact__btn {
      width: 100%; }
      #service-item #contact .contact__btn a {
        font-size: 0.85333rem;
        line-height: 1.5;
        font-weight: 700;
        color: #fff;
        text-align: center;
        display: inline-block;
        width: 100%;
        padding: 1.62133rem 0;
        background: #21589c;
        position: relative; }
        #service-item #contact .contact__btn a::after {
          content: "";
          width: 0.85333rem;
          height: 0.57003rem;
          background: url(../img/common/arrow_right.svg) center/contain no-repeat;
          position: absolute;
          top: 50%;
          right: 1.70667rem;
          transform: translateY(-50%);
          z-index: 0; }
  #service-item #links {
    padding: 3.92533rem 0 5.20533rem;
    background: #fff;
    position: relative; }
    #service-item #links .links__title {
      font-size: 1.19467rem;
      line-height: 2.42857;
      letter-spacing: 0.04em;
      font-weight: 700;
      color: #21589c;
      text-align: center;
      border: 0.04267rem solid currentColor;
      margin-bottom: 2.048rem; }
    #service-item #links .links__block:nth-child(n + 2) {
      margin-top: 3.008rem; }
    #service-item #links .links__block__title {
      font-size: 1.28rem;
      line-height: 1.2;
      letter-spacing: 0.04em;
      font-weight: 700;
      padding-left: 1.70667rem;
      margin-bottom: 1.92rem;
      position: relative; }
      #service-item #links .links__block__title::before {
        content: "";
        width: 1.06667rem;
        height: 0.08533rem;
        background: currentColor;
        position: absolute;
        top: 0.72533rem;
        left: 0; }
    #service-item #links .links__item {
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      width: 100%;
      min-height: 8.53333rem;
      background: #f7f9fa;
      position: relative; }
      #service-item #links .links__item > a {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1; }
      #service-item #links .links__item:nth-child(n + 2) {
        margin-top: 1.28rem; }
      #service-item #links .links__item__img {
        width: 8.53333rem;
        height: 8.53333rem; }
      #service-item #links .links__item__content {
        letter-spacing: 0.04em;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        padding: 0 1.62133rem; }
      #service-item #links .links__item__title {
        font-size: 1.06667rem;
        line-height: 1.44;
        font-weight: 700;
        color: #21589c; }
      #service-item #links .links__item__desc {
        font-size: 0.85333rem;
        line-height: 1.4;
        color: #21589c;
        margin-bottom: 0.34133rem; }
  #service-item #call-back {
    padding: 0 0 5.20533rem;
    background: #fff;
    position: relative; }
    #service-item #call-back .call-back__blocks__block {
      width: 100%;
      margin-bottom: 2.56rem; }
      #service-item #call-back .call-back__blocks__block__img {
        width: 100%;
        margin-bottom: 1.10933rem; }
      #service-item #call-back .call-back__blocks__block__title {
        font-size: 1.024rem;
        line-height: 1;
        letter-spacing: 0.04em;
        font-weight: 700;
        color: #21589c;
        margin-bottom: 0.63147rem; }
      #service-item #call-back .call-back__blocks__block__desc {
        font-size: 0.64rem;
        line-height: 1.6;
        letter-spacing: 0.04em; } }

@media (min-width: 769px) {
  #newsrelease .sec .container {
    width: 100%;
    max-width: 32rem;
    padding: 0 3.6rem;
    margin: 0 auto;
    position: relative; }
  #newsrelease .sec-title {
    font-size: 0.56rem;
    line-height: 1.42857;
    font-weight: 700;
    color: #21589c;
    margin-bottom: 0.52rem; }
  #newsrelease #head {
    letter-spacing: 0.04em;
    font-weight: 700;
    padding: 2.08rem 0 3.04rem;
    background: #fff; }
    #newsrelease #head .head__title {
      font-size: 0.72rem;
      line-height: 1.5;
      padding-bottom: 0.24rem;
      position: relative; }
      #newsrelease #head .head__title::after {
        content: "";
        display: inline-block;
        width: 0.8rem;
        height: 0.04rem;
        background: #21589c;
        position: absolute;
        bottom: 0;
        left: 0; }
    #newsrelease #head .head__desc {
      font-size: 0.36rem;
      line-height: 1.77778;
      margin-top: 1.44rem; }
  #newsrelease #news-head {
    letter-spacing: 0.04em;
    font-weight: 700;
    padding: 2.08rem 0 0;
    background: #fff; }
    #newsrelease #news-head .head__title {
      font-size: 0.64rem;
      line-height: 1.5;
      position: relative;
      margin-bottom: 0.452rem; }
  #newsrelease #news {
    padding: 0 0 3.19rem;
    background: #fff; }
    #newsrelease #news .news__list {
      margin-bottom: 1.2rem; }
    #newsrelease #news .news__item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      padding: 0.76rem 0.4rem;
      border-top: 0.02rem solid #e5e5e5;
      position: relative;
      transition: 0.3s ease all; }
      #newsrelease #news .news__item:hover {
        background: #f7f9fa;
        opacity: 0.7; }
      #newsrelease #news .news__item:last-child {
        border-bottom: 0.02rem solid #e5e5e5; }
      #newsrelease #news .news__item > a {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1; }
      #newsrelease #news .news__item__time {
        font-size: 0.32rem;
        line-height: 1.5;
        margin-right: 0.38rem; }
      #newsrelease #news .news__item__category {
        font-size: 0.24rem;
        line-height: 2;
        color: #fff;
        text-align: center;
        width: 1.8rem;
        border-radius: 0.24rem;
        margin-right: 0.44rem; }
        #newsrelease #news .news__item__category.category01 {
          background: #77a8e5; }
        #newsrelease #news .news__item__category.category02 {
          background: #6dd9bc; }
        #newsrelease #news .news__item__category.category03 {
          background: #5fcad8; }
      #newsrelease #news .news__item__title {
        font-size: 0.32rem;
        line-height: 1.5;
        letter-spacing: 0.04em;
        flex: 1; }
  #newsrelease #article {
    padding: 0 0 3.19rem;
    background: #fff; }
    #newsrelease #article .article__list {
      margin-bottom: 1.2rem; }
    #newsrelease #article .article__item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      padding-bottom: 0.806rem;
      margin-bottom: 1rem;
      border-bottom: 0.02rem solid #e5e5e5;
      position: relative;
      transition: 0.3s ease all; }
      #newsrelease #article .article__item__time {
        font-size: 0.32rem;
        line-height: 1.5;
        margin-right: 0.28rem; }
      #newsrelease #article .article__item__category {
        font-size: 0.24rem;
        line-height: 2;
        color: #fff;
        text-align: center;
        width: 1.8rem;
        border-radius: 0.24rem;
        margin-right: 0.44rem; }
        #newsrelease #article .article__item__category.category01 {
          background: #C6CACE; }
        #newsrelease #article .article__item__category.category02 {
          background: #C6CACE; }
        #newsrelease #article .article__item__category.category03 {
          background: #C6CACE; }
    #newsrelease #article .article__content {
      font-size: 0.32rem;
      line-height: 1.875;
      letter-spacing: 0.04em;
      margin-bottom: 0.9rem; }
    #newsrelease #article .article__btn {
      width: 5.6rem;
      margin: 0 auto; }
      #newsrelease #article .article__btn a {
        font-size: 0.32rem;
        line-height: 1.875;
        font-weight: 700;
        color: #fff;
        text-align: center;
        display: inline-block;
        width: 100%;
        padding: 0.48rem 0;
        background: #21589c;
        position: relative; }
        #newsrelease #article .article__btn a::after {
          content: "";
          width: 0.3664rem;
          height: 0.2448rem;
          background: url(../img/common/arrow_right.svg) center/contain no-repeat;
          position: absolute;
          top: 50%;
          right: 0.672rem;
          transform: translateY(-50%);
          z-index: 0; } }

@media (max-width: 768px) {
  #newsrelease .sec .container {
    width: 100%;
    max-width: 32rem;
    padding: 0 1.70667rem;
    margin: 0 auto;
    position: relative; }
  #newsrelease .sec-title {
    font-size: 1.19467rem;
    line-height: 1.42857;
    font-weight: 700;
    color: #21589c;
    margin-bottom: 1.62133rem; }
  #newsrelease #head {
    letter-spacing: 0.04em;
    font-weight: 700;
    padding: 4.43733rem 0 6.48533rem;
    background: #fff; }
    #newsrelease #head .head__title {
      font-size: 1.536rem;
      line-height: 1.5;
      padding-bottom: 0.512rem;
      position: relative; }
      #newsrelease #head .head__title::after {
        content: "";
        display: inline-block;
        width: 1.70667rem;
        height: 0.08533rem;
        background: #21589c;
        position: absolute;
        bottom: 0;
        left: 0; }
    #newsrelease #head .head__desc {
      font-size: 0.768rem;
      line-height: 1.77778;
      margin-top: 3.072rem; }
  #newsrelease #news-head {
    letter-spacing: 0.04em;
    font-weight: 700;
    padding: 4.43733rem 0 0;
    background: #fff; }
    #newsrelease #news-head .head__title {
      font-size: 1.36533rem;
      line-height: 1.5;
      position: relative;
      margin-bottom: 0.96427rem; }
  #newsrelease #news {
    padding: 0 0 6.80533rem;
    background: #fff; }
    #newsrelease #news .news__list {
      margin-bottom: 2.56rem; }
    #newsrelease #news .news__item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
      padding: 1.28rem 0.74667rem;
      border-top: 0.04267rem solid #e5e5e5;
      position: relative;
      transition: 0.3s ease all; }
      #newsrelease #news .news__item:last-child {
        border-bottom: 0.04267rem solid #e5e5e5; }
      #newsrelease #news .news__item > a {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1; }
      #newsrelease #news .news__item__time {
        font-size: 0.85333rem;
        line-height: 1.2;
        margin-right: 0.85333rem; }
      #newsrelease #news .news__item__category {
        font-size: 0.68267rem;
        line-height: 1.5;
        color: #fff;
        text-align: center;
        width: 5.12rem;
        padding: 0.21333rem 0;
        border-radius: 0.85333rem; }
        #newsrelease #news .news__item__category.category01 {
          background: #77a8e5; }
        #newsrelease #news .news__item__category.category02 {
          background: #6dd9bc; }
        #newsrelease #news .news__item__category.category03 {
          background: #5fcad8; }
      #newsrelease #news .news__item__title {
        font-size: 0.85333rem;
        line-height: 1.5;
        letter-spacing: 0.04em;
        width: 100%;
        margin-top: 0.21333rem; }
  #newsrelease #article {
    padding: 0 0 6.80533rem;
    background: #fff; }
    #newsrelease #article .article__list {
      margin-bottom: 2.56rem; }
    #newsrelease #article .article__item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      padding-bottom: 1.71947rem;
      margin-bottom: 2.13333rem;
      border-bottom: 0.04267rem solid #e5e5e5;
      position: relative;
      transition: 0.3s ease all; }
      #newsrelease #article .article__item__time {
        font-size: 0.68267rem;
        line-height: 1.5;
        margin-right: 0.59733rem; }
      #newsrelease #article .article__item__category {
        font-size: 0.512rem;
        line-height: 2;
        color: #fff;
        text-align: center;
        width: 3.84rem;
        border-radius: 0.512rem;
        margin-right: 0.93867rem; }
        #newsrelease #article .article__item__category.category01 {
          background: #C6CACE; }
        #newsrelease #article .article__item__category.category02 {
          background: #C6CACE; }
        #newsrelease #article .article__item__category.category03 {
          background: #C6CACE; }
    #newsrelease #article .article__content {
      font-size: 0.68267rem;
      line-height: 1.875;
      letter-spacing: 0.04em;
      margin-bottom: 1.92rem; }
    #newsrelease #article .article__btn {
      width: 11.94667rem;
      margin: 0 auto; }
      #newsrelease #article .article__btn a {
        font-size: 0.68267rem;
        line-height: 1.875;
        font-weight: 700;
        color: #fff;
        text-align: center;
        display: inline-block;
        width: 100%;
        padding: 1.024rem 0;
        background: #21589c;
        position: relative; }
        #newsrelease #article .article__btn a::after {
          content: "";
          width: 0.78165rem;
          height: 0.52224rem;
          background: url(../img/common/arrow_right.svg) center/contain no-repeat;
          position: absolute;
          top: 50%;
          right: 1.4336rem;
          transform: translateY(-50%);
          z-index: 0; } }

@media (min-width: 769px) {
  #contact .sec .container {
    width: 100%;
    max-width: 32rem;
    padding: 0 3.6rem;
    margin: 0 auto;
    position: relative; }
  #contact .sec-title {
    font-size: 0.56rem;
    line-height: 1.42857;
    font-weight: 700;
    color: #21589c;
    margin-bottom: 0.52rem; }
  #contact #contact {
    padding: 2rem 0 3.8rem;
    background: #fff; }
    #contact #contact .contact__form .contact__item__input {
      display: block; }
    #contact #contact .contact__form .contact__item__confirm {
      display: none; }
    #contact #contact .contact__form .contact__confirm {
      display: block; }
    #contact #contact .contact__form .contact__submit {
      display: none; }
    #contact #contact .contact__form.confirm .contact__item {
      margin-bottom: 0.6rem; }
      #contact #contact .contact__form.confirm .contact__item__input {
        display: none; }
      #contact #contact .contact__form.confirm .contact__item__confirm {
        display: block; }
    #contact #contact .contact__form.confirm .contact__box {
      display: none; }
    #contact #contact .contact__form.confirm .contact__confirm {
      display: none; }
    #contact #contact .contact__form.confirm .contact__submit {
      display: block; }
    #contact #contact .contact__item {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 0.4rem;
      position: relative; }
      #contact #contact .contact__item__label {
        width: 4.98rem;
        padding-top: 0.49rem;
        margin-right: 0.52rem;
        font-size: 0.32rem;
        font-weight: 700;
        letter-spacing: 0.04em; }
      #contact #contact .contact__item .required::after {
        content: "【必須】";
        font-size: 0.32rem;
        letter-spacing: 0.04em;
        color: #FF0000;
        padding-left: 0.12rem; }
      #contact #contact .contact__item__input,
      #contact #contact .contact__item textarea {
        width: 12.8rem; }
      #contact #contact .contact__item textarea {
        height: 4rem; }
      #contact #contact .contact__item label {
        position: relative;
        display: block;
        width: 100%; }
        #contact #contact .contact__item label::before {
          position: absolute;
          content: "";
          top: 45%;
          left: 0.42rem;
          border: 0.12rem solid transparent;
          border-top: 0.14rem solid #6F8293; }
        #contact #contact .contact__item label select {
          display: block;
          width: 100%;
          font-size: 0.32rem;
          color: #6F8293;
          line-height: 1.875;
          padding: 0.36rem;
          padding-left: 0.74rem;
          border: 0.02rem solid #CCCCCC;
          border-radius: 0.1rem;
          outline: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none; }
          #contact #contact .contact__item label select::-ms-expand {
            display: none; }
      #contact #contact .contact__item input[type=text] {
        font-size: 0.32rem;
        color: #6F8293;
        line-height: 1.875;
        box-sizing: border-box;
        width: 100%;
        padding: 0.36rem;
        border: 0.02rem solid #CCCCCC;
        border-radius: 0.1rem;
        outline: none; }
      #contact #contact .contact__item textarea {
        font-size: 0.32rem;
        color: #6F8293;
        line-height: 1.875;
        box-sizing: border-box;
        width: 100%;
        padding: 0.36rem;
        border: 0.02rem solid #CCCCCC;
        border-radius: 0.1rem;
        outline: none;
        resize: none; }
      #contact #contact .contact__item__error p {
        font-size: 0.32rem;
        letter-spacing: 0.04em;
        line-height: 1.5;
        color: #f00;
        margin-top: 0.24rem; }
      #contact #contact .contact__item__confirm {
        font-size: 0.32rem;
        letter-spacing: 0.04em;
        padding-top: 0.49rem; }
    #contact #contact .contact__item:nth-child(10) {
      margin-bottom: 0.76rem; }
    #contact #contact .contact__box {
      width: 12.8rem;
      height: 5.6rem;
      overflow: auto;
      box-sizing: border-box;
      margin-left: 5.5rem;
      margin-bottom: 0.78rem;
      padding: 0.4rem;
      border: 0.02rem solid #CCCCCC;
      border-radius: 0.1rem;
      outline: none;
      font-size: 0.28rem;
      line-height: 1.71429;
      letter-spacing: 0.04em; }
    #contact #contact .contact__confirm__agree, #contact #contact .contact__submit__agree {
      width: 4.24rem;
      margin: 0 auto;
      margin-bottom: 0.6rem; }
      #contact #contact .contact__confirm__agree input[type=checkbox], #contact #contact .contact__submit__agree input[type=checkbox] {
        display: none; }
      #contact #contact .contact__confirm__agree__label, #contact #contact .contact__submit__agree__label {
        box-sizing: border-box;
        cursor: pointer;
        display: inline-block;
        position: relative;
        width: auto;
        outline: none;
        font-size: 0.32rem;
        line-height: 1.875;
        color: #435a6e;
        padding-left: 0.62rem; }
        #contact #contact .contact__confirm__agree__label::before, #contact #contact .contact__submit__agree__label::before {
          background: #fff;
          border: 0.02rem solid #DDDDDD;
          border-radius: 0.04rem;
          content: '';
          display: block;
          height: 0.4rem;
          left: 0;
          margin-top: -0.16rem;
          position: absolute;
          top: 50%;
          width: 0.4rem; }
        #contact #contact .contact__confirm__agree__label::after, #contact #contact .contact__submit__agree__label::after {
          border-right: 0.02rem solid #231815;
          border-bottom: 0.02rem solid #231815;
          content: '';
          display: block;
          height: 0.3rem;
          left: 3.2%;
          margin-top: -0.14rem;
          opacity: 0;
          position: absolute;
          top: 50%;
          transform: rotate(45deg);
          width: 0.16rem; }
      #contact #contact .contact__confirm__agree p, #contact #contact .contact__submit__agree p {
        font-size: 0.32rem;
        line-height: 1.875;
        color: #435a6e;
        text-align: center; }
      #contact #contact .contact__confirm__agree input[type=checkbox]:checked + .contact__submit__agree__label::after, #contact #contact .contact__submit__agree input[type=checkbox]:checked + .contact__submit__agree__label::after {
        opacity: 1; }
    #contact #contact .contact__confirm__btn, #contact #contact .contact__submit__btn {
      width: 5.6rem;
      margin: 0 auto; }
      #contact #contact .contact__confirm__btn button, #contact #contact .contact__submit__btn button {
        font-size: 0.32rem;
        line-height: 1.875;
        font-weight: 700;
        color: #fff;
        text-align: center;
        display: inline-block;
        width: 100%;
        padding: 0.48rem 0;
        border: 0;
        outline: 0;
        background: #21589c;
        position: relative;
        cursor: pointer; }
        #contact #contact .contact__confirm__btn button::after, #contact #contact .contact__submit__btn button::after {
          content: "";
          width: 0.3664rem;
          height: 0.2448rem;
          background: url(../img/common/arrow_right.svg) center/contain no-repeat;
          position: absolute;
          top: 50%;
          right: 0.672rem;
          transform: translateY(-50%);
          z-index: 0; }
    #contact #contact .contact__submit__return {
      font-size: 0.32rem;
      line-height: 1.5;
      text-align: center;
      margin-top: 0.92rem;
      cursor: pointer; }
    #contact #contact .contact__thanks, #contact #contact .contact__error {
      font-size: 0.32rem;
      line-height: 2;
      text-align: center;
      margin-bottom: 2.4rem; }
      #contact #contact .contact__thanks__btn, #contact #contact .contact__error__btn {
        width: 5.6rem;
        margin: 0 auto; }
        #contact #contact .contact__thanks__btn button, #contact #contact .contact__error__btn button {
          font-size: 0.32rem;
          line-height: 1.875;
          font-weight: 700;
          color: #fff;
          text-align: center;
          display: inline-block;
          width: 100%;
          padding: 0.48rem 0;
          border: 0;
          outline: 0;
          background: #21589c;
          position: relative;
          cursor: pointer; }
          #contact #contact .contact__thanks__btn button::after, #contact #contact .contact__error__btn button::after {
            content: "";
            width: 0.3664rem;
            height: 0.2448rem;
            background: url(../img/common/arrow_right.svg) center/contain no-repeat;
            position: absolute;
            top: 50%;
            right: 0.672rem;
            transform: translateY(-50%);
            z-index: 0; } }

@media (max-width: 768px) {
  #contact .sec .container {
    width: 100%;
    max-width: 32rem;
    padding: 0 1.70667rem;
    margin: 0 auto;
    position: relative; }
  #contact .sec-title {
    font-size: 1.19467rem;
    line-height: 1.42857;
    font-weight: 700;
    color: #21589c;
    margin-bottom: 1.62133rem; }
  #contact #contact {
    padding: 4.26667rem 0 8.10667rem;
    background: #fff; }
    #contact #contact .contact__form .contact__item__input {
      display: block; }
    #contact #contact .contact__form .contact__item__confirm {
      display: none; }
    #contact #contact .contact__form .contact__confirm {
      display: block; }
    #contact #contact .contact__form .contact__submit {
      display: none; }
    #contact #contact .contact__form.confirm .contact__item {
      margin-bottom: 1.28rem; }
      #contact #contact .contact__form.confirm .contact__item__input {
        display: none; }
      #contact #contact .contact__form.confirm .contact__item__confirm {
        display: block; }
    #contact #contact .contact__form.confirm .contact__box {
      display: none; }
    #contact #contact .contact__form.confirm .contact__confirm {
      display: none; }
    #contact #contact .contact__form.confirm .contact__submit {
      display: block; }
    #contact #contact .contact__item {
      width: 100%;
      margin-bottom: 0.85333rem;
      position: relative; }
      #contact #contact .contact__item__label {
        width: 13.65333rem;
        margin-right: 1.10933rem;
        font-size: 0.68267rem;
        padding: 1.04533rem 0;
        font-weight: 700;
        letter-spacing: 0.04em; }
      #contact #contact .contact__item .required::after {
        content: "【必須】";
        font-size: 0.68267rem;
        letter-spacing: 0.04em;
        color: #FF0000;
        padding-left: 0.256rem; }
      #contact #contact .contact__item__input, #contact #contact .contact__item textarea {
        width: 100%; }
      #contact #contact .contact__item textarea {
        height: 8.53333rem; }
      #contact #contact .contact__item label {
        position: relative;
        display: block;
        width: 100%; }
        #contact #contact .contact__item label::before {
          position: absolute;
          content: "";
          top: 45%;
          left: 0.896rem;
          border: 0.256rem solid transparent;
          border-top: 0.29867rem solid #6F8293; }
        #contact #contact .contact__item label select {
          display: block;
          width: 100%;
          font-size: 0.68267rem;
          color: #6F8293;
          line-height: 1.875;
          padding: 0.768rem;
          padding-left: 1.57867rem;
          border: 0.04267rem solid #CCCCCC;
          border-radius: 0.21333rem;
          outline: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none; }
          #contact #contact .contact__item label select::-ms-expand {
            display: none; }
      #contact #contact .contact__item input[type=text] {
        font-size: 0.68267rem;
        color: #6F8293;
        line-height: 1.875;
        box-sizing: border-box;
        width: 100%;
        padding: 0.768rem;
        border: 0.04267rem solid #CCCCCC;
        border-radius: 0.21333rem;
        outline: none; }
      #contact #contact .contact__item textarea {
        font-size: 0.68267rem;
        color: #6F8293;
        line-height: 1.875;
        box-sizing: border-box;
        width: 100%;
        padding: 0.768rem;
        border: 0.04267rem solid #CCCCCC;
        border-radius: 0.21333rem;
        outline: none;
        resize: none; }
      #contact #contact .contact__item__error p {
        font-size: 0.68267rem;
        letter-spacing: 0.04em;
        line-height: 1.5;
        color: #f00;
        margin-top: 0.512rem; }
      #contact #contact .contact__item__confirm {
        font-size: 0.68267rem;
        letter-spacing: 0.04em;
        padding: 1.04533rem 0; }
    #contact #contact .contact__item:nth-child(10) {
      margin-bottom: 1.62133rem; }
    #contact #contact .contact__box {
      width: 100%;
      height: 11.94667rem;
      overflow: auto;
      box-sizing: border-box;
      margin-bottom: 1.664rem;
      padding: 0.85333rem;
      border: 0.04267rem solid #CCCCCC;
      border-radius: 0.21333rem;
      outline: none;
      font-size: 0.59733rem;
      line-height: 1.71429;
      letter-spacing: 0.04em; }
    #contact #contact .contact__confirm__agree, #contact #contact .contact__submit__agree {
      width: 11.09333rem;
      margin: 0 auto;
      margin-bottom: 1.28rem; }
      #contact #contact .contact__confirm__agree input[type=checkbox], #contact #contact .contact__submit__agree input[type=checkbox] {
        display: none; }
      #contact #contact .contact__confirm__agree__label, #contact #contact .contact__submit__agree__label {
        box-sizing: border-box;
        cursor: pointer;
        display: inline-block;
        position: relative;
        width: auto;
        outline: none;
        font-size: 0.68267rem;
        line-height: 1.875;
        color: #435a6e;
        padding-left: 1.32267rem; }
        #contact #contact .contact__confirm__agree__label::before, #contact #contact .contact__submit__agree__label::before {
          background: #fff;
          border: 0.04267rem solid #DDDDDD;
          border-radius: 0.08533rem;
          content: '';
          display: block;
          height: 0.85333rem;
          left: 0;
          margin-top: -0.34133rem;
          position: absolute;
          top: 50%;
          width: 0.85333rem; }
        #contact #contact .contact__confirm__agree__label::after, #contact #contact .contact__submit__agree__label::after {
          border-right: 0.04267rem solid #231815;
          border-bottom: 0.04267rem solid #231815;
          content: '';
          display: block;
          height: 0.64rem;
          left: 3.2%;
          margin-top: -0.29867rem;
          opacity: 0;
          position: absolute;
          top: 50%;
          transform: rotate(45deg);
          width: 0.34133rem; }
      #contact #contact .contact__confirm__agree p, #contact #contact .contact__submit__agree p {
        font-size: 0.68267rem;
        line-height: 1.875;
        color: #435a6e;
        text-align: center; }
      #contact #contact .contact__confirm__agree input[type=checkbox]:checked + .contact__submit__agree__label::after, #contact #contact .contact__submit__agree input[type=checkbox]:checked + .contact__submit__agree__label::after {
        opacity: 1; }
    #contact #contact .contact__confirm__btn, #contact #contact .contact__submit__btn {
      width: 11.94667rem;
      margin: 0 auto; }
      #contact #contact .contact__confirm__btn button, #contact #contact .contact__submit__btn button {
        font-size: 0.68267rem;
        line-height: 1.875;
        font-weight: 700;
        color: #fff;
        text-align: center;
        display: inline-block;
        width: 100%;
        padding: 1.024rem 0;
        border: 0;
        outline: 0;
        background: #21589c;
        position: relative; }
        #contact #contact .contact__confirm__btn button::after, #contact #contact .contact__submit__btn button::after {
          content: "";
          width: 0.78165rem;
          height: 0.52224rem;
          background: url(../img/common/arrow_right.svg) center/contain no-repeat;
          position: absolute;
          top: 50%;
          right: 1.4336rem;
          transform: translateY(-50%);
          z-index: 0; }
    #contact #contact .contact__submit__return {
      font-size: 0.68267rem;
      line-height: 1.5;
      text-align: center;
      margin-top: 1.96267rem;
      cursor: pointer; }
    #contact #contact .contact__thanks, #contact #contact .contact__error {
      font-size: 0.68267rem;
      line-height: 2;
      text-align: center;
      margin-bottom: 5.12rem; }
      #contact #contact .contact__thanks__btn, #contact #contact .contact__error__btn {
        width: 11.94667rem;
        margin: 0 auto; }
        #contact #contact .contact__thanks__btn button, #contact #contact .contact__error__btn button {
          font-size: 0.68267rem;
          line-height: 1.875;
          font-weight: 700;
          color: #fff;
          text-align: center;
          display: inline-block;
          width: 100%;
          padding: 1.024rem 0;
          border: 0;
          outline: 0;
          background: #21589c;
          position: relative;
          cursor: pointer; }
          #contact #contact .contact__thanks__btn button::after, #contact #contact .contact__error__btn button::after {
            content: "";
            width: 0.78165rem;
            height: 0.52224rem;
            background: url(../img/common/arrow_right.svg) center/contain no-repeat;
            position: absolute;
            top: 50%;
            right: 1.4336rem;
            transform: translateY(-50%);
            z-index: 0; } }

@media (min-width: 769px) {
  #recruit .sec .container {
    width: 100%;
    max-width: 32rem;
    padding: 0 3.6rem;
    margin: 0 auto;
    position: relative; }
  #recruit .sec-title {
    font-size: 0.56rem;
    line-height: 1.42857;
    font-weight: 700;
    color: #21589c;
    margin-bottom: 0.52rem; }
  #recruit #desc {
    padding: 2rem 0 1.2rem;
    background: #fff; }
    #recruit #desc .desc__desc {
      font-size: 0.36rem;
      font-weight: 700;
      line-height: 1.77778;
      letter-spacing: 0.04em; }
  #recruit .sbiwork {
    padding-bottom: 1.8rem !important; }
    #recruit .sbiwork .desc__desc {
      text-align: center; }
  #recruit #box {
    padding: 0 0 4rem;
    background: #fff; }
    #recruit #box .box {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap; }
      #recruit #box .box__title {
        width: 11.6rem;
        height: 4rem;
        text-align: center;
        background-color: #3AC319;
        font-size: 0.48rem;
        line-height: 1.5;
        color: #fff;
        border-radius: 0.2rem;
        padding-top: 1.78rem; }
      #recruit #box .box a:first-child .box__title {
        padding-top: 1.4rem; }
      #recruit #box .box a:nth-child(even) {
        margin-left: auto; }
      #recruit #box .box a:nth-child(n+3) {
        margin-top: 1.2rem; }
  #recruit #sbiwork_box {
    padding: 0 0 4rem;
    background: #fff; }
    #recruit #sbiwork_box .sbiwork_box {
      text-align: center; }
      #recruit #sbiwork_box .sbiwork_box__title {
        width: 11.6rem;
        height: 2rem;
        text-align: center;
        background-color: #3AC319;
        font-size: 0.48rem;
        line-height: 1.5;
        color: #fff;
        border-radius: 0.2rem;
        padding-top: 0.66rem; }
  #recruit #head {
    letter-spacing: 0.04em;
    font-weight: 700;
    padding: 2.08rem 0 1.4rem;
    background: #fff; }
    #recruit #head .head__title {
      font-size: 0.72rem;
      line-height: 1.5;
      padding-bottom: 0.24rem;
      position: relative; }
      #recruit #head .head__title::after {
        content: "";
        display: inline-block;
        width: 0.8rem;
        height: 0.04rem;
        background: #21589c;
        position: absolute;
        bottom: 0;
        left: 0; }
    #recruit #head .head__desc {
      font-size: 0.36rem;
      line-height: 1.77778;
      margin-top: 1.44rem; }
  #recruit #btn .btn {
    width: 11.6rem;
    margin: 0 auto; }
    #recruit #btn .btn a {
      letter-spacing: 0.04em;
      font-size: 0.48rem;
      font-weight: 700;
      line-height: 1.33333;
      text-align: center;
      display: inline-block;
      width: 100%;
      padding: 0.66rem 3.56rem 0.64rem;
      color: #3AC319;
      background: #fff;
      margin-bottom: 2rem;
      border: 0.02rem solid #3AC319;
      border-radius: 0.2rem; }
  #recruit #content {
    letter-spacing: 0.04em;
    font-weight: 700;
    padding: 1.87rem 0 2rem;
    background: #EAF7EF; }
    #recruit #content .content__title {
      font-size: 0.6rem;
      line-height: 1.2;
      color: #3AC319;
      margin-bottom: 0.72rem; }
    #recruit #content .content__box {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: #fff;
      font-size: 0.32rem;
      font-weight: 700;
      line-height: 2.25;
      padding: 0.828rem 0.98rem 0.82rem 1rem;
      border-radius: 0.2rem; }
      #recruit #content .content__box ul {
        width: 15rem; }
        #recruit #content .content__box ul li {
          position: relative;
          padding-left: 0.5rem; }
          #recruit #content .content__box ul li::before {
            position: absolute;
            content: "";
            width: 0.32rem;
            height: 0.32rem;
            top: 0.22rem;
            left: 0;
            vertical-align: top;
            border-radius: 0.32rem;
            background-color: #3AC319; }
      #recruit #content .content__box__img {
        width: 7.2rem;
        margin-left: 0.6rem; }
    #recruit #content .content__title:nth-child(3) {
      margin-top: 1.6rem; }
  #recruit #question {
    letter-spacing: 0.04em;
    padding: 2rem 0 1.99rem;
    background: #fff; }
    #recruit #question .question__title {
      font-size: 0.6rem;
      font-weight: 700;
      line-height: 1.2;
      color: #3AC319;
      margin-bottom: 1.108rem; }
    #recruit #question .question__item {
      margin-bottom: 0.6rem; }
      #recruit #question .question__item__title {
        background: #3AC319;
        font-size: 0.36rem;
        font-weight: 700;
        line-height: 1.66667;
        color: #fff;
        padding: 0.554rem 8.08rem 0.434rem 0.4rem;
        border-radius: 0.2rem 0.2rem 0 0;
        position: relative; }
        #recruit #question .question__item__title h4::before {
          content: "";
          display: inline-block;
          width: 0.8rem;
          height: 0.8rem;
          background: url(../img/recruit/lifestyle/icon_lifestyle01.svg) no-repeat;
          background-size: contain;
          vertical-align: middle;
          margin-right: 0.4rem; }
      #recruit #question .question__item__desc {
        position: relative;
        background: #fff;
        font-size: 0.32rem;
        line-height: 1.875;
        padding: 0.536rem 0.72rem 0.652rem 1.6rem;
        border: 0.02rem solid #DDDDDD;
        border-radius: 0 0 0.2rem 0.2rem; }
        #recruit #question .question__item__desc span::before {
          position: absolute;
          content: "";
          display: inline-block;
          width: 0.8rem;
          height: 0.8rem;
          background: url(../img/recruit/lifestyle/icon_lifestyle02.svg) no-repeat;
          background-size: contain;
          top: 0.4rem;
          left: 0.4rem;
          padding-right: 0.4rem; }
  #recruit #found {
    padding: 1.992rem 0 2rem;
    background: #EAF7EF; }
    #recruit #found .found__title {
      font-size: 0.6rem;
      line-height: 1.53333;
      font-weight: 700;
      color: #3AC319;
      text-align: center;
      margin-bottom: 0.64rem; }
    #recruit #found .found__desc {
      font-size: 0.32rem;
      line-height: 1.875;
      text-align: center;
      margin-bottom: 1.14rem; }
    #recruit #found .found__btn {
      width: 11.6rem;
      margin: 0 auto; }
      #recruit #found .found__btn a {
        font-size: 0.48rem;
        line-height: 1.33333;
        font-weight: 700;
        color: #fff;
        text-align: center;
        display: inline-block;
        width: 100%;
        padding: 0.66rem 0;
        background: #3AC319;
        border-radius: 0.2rem; }
  #recruit #flow {
    background: #EAF7EF;
    padding: 2rem 0;
    position: relative; }
    #recruit #flow .flow__title {
      color: #3AC319; }
    #recruit #flow .flow__desc {
      font-size: 0.32rem;
      line-height: 1.875;
      letter-spacing: 0.04em;
      font-weight: 500;
      margin-bottom: 1.08rem; }
    #recruit #flow .flow__contents {
      display: flex;
      align-items: stretch;
      justify-content: flex-start; }
    #recruit #flow .flow__item {
      height: 3rem;
      flex: 1;
      background: #fff;
      position: relative; }
      #recruit #flow .flow__item:nth-child(n + 2) {
        margin-left: 0.2rem; }
      #recruit #flow .flow__item:last-child::before, #recruit #flow .flow__item:last-child::after {
        content: none; }
      #recruit #flow .flow__item:last-child .flow__item__wrapper {
        margin-left: 0; }
      #recruit #flow .flow__item::before, #recruit #flow .flow__item::after {
        content: "";
        width: 0.64rem;
        height: 1.5rem;
        display: inline-block;
        position: absolute;
        right: 0; }
      #recruit #flow .flow__item::before {
        top: 0;
        border-top: 0.75rem solid #EAF7EF;
        border-right: 0.32rem solid #EAF7EF;
        border-bottom: 0.75rem solid transparent;
        border-left: 0.32rem solid transparent; }
      #recruit #flow .flow__item::after {
        bottom: 0;
        border-top: 0.75rem solid transparent;
        border-right: 0.32rem solid #EAF7EF;
        border-bottom: 0.75rem solid #EAF7EF;
        border-left: 0.32rem solid transparent; }
      #recruit #flow .flow__item__wrapper {
        letter-spacing: 0.04em;
        text-align: center;
        white-space: nowrap;
        margin-left: -0.2rem;
        position: absolute;
        top: 0.56rem;
        left: 50%;
        z-index: 2;
        transform: translateX(-50%); }
      #recruit #flow .flow__item__number {
        font-size: 0.28rem;
        line-height: 1.21429;
        font-weight: 600;
        color: #3AC319;
        margin-bottom: 0.18rem; }
      #recruit #flow .flow__item__text {
        font-size: 0.32rem;
        line-height: 1.5;
        font-weight: 700; }
  #recruit #contact {
    padding: 1.8rem 0 4rem;
    background: #fff; }
    #recruit #contact .contact__title {
      font-size: 0.6rem;
      line-height: 1.2;
      font-weight: 700;
      color: #3AC319;
      text-align: center;
      margin-bottom: 1.108rem; }
    #recruit #contact .contact__btn {
      width: 11.6rem;
      margin: 0 auto; }
      #recruit #contact .contact__btn a {
        font-size: 0.48rem;
        line-height: 1.33333;
        font-weight: 700;
        color: #fff;
        text-align: center;
        display: inline-block;
        width: 100%;
        padding: 0.66rem 0;
        background: #3AC319;
        border-radius: 0.2rem; }
  #recruit #jobform {
    padding: 1.8rem 0 3.2rem;
    background: #fff; }
    #recruit #jobform .container {
      padding: 0 5.2rem; }
    #recruit #jobform .jobform__title {
      font-size: 0.6rem;
      line-height: 1.2;
      font-weight: 700;
      color: #3AC319;
      text-align: center;
      margin-bottom: 1.092rem; }
    #recruit #jobform .jobform__content {
      padding: 1.2rem 1.66rem 1.57rem 1.64rem;
      border: 0.02rem solid #CCCCCC;
      border-radius: 0.2rem; }
      #recruit #jobform .jobform__content__desc {
        width: 14.64rem;
        font-size: 0.32rem;
        line-height: 1.875;
        margin: 0 auto;
        margin-bottom: 0.9rem; }
      #recruit #jobform .jobform__content__item {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 0.4rem;
        position: relative; }
        #recruit #jobform .jobform__content__item__label {
          width: 4.98rem;
          margin-right: 1.2rem;
          font-size: 0.32rem;
          line-height: 4.0625;
          font-weight: 700;
          color: #435a6e;
          letter-spacing: 0.04em; }
        #recruit #jobform .jobform__content__item__input {
          width: 12.8rem; }
          #recruit #jobform .jobform__content__item__input__box {
            width: 100%;
            height: 5.6rem;
            overflow-x: auto;
            box-sizing: border-box;
            margin-bottom: 0.78rem;
            padding: 0.4rem;
            border: 0.02rem solid #CCCCCC;
            border-radius: 0.1rem;
            outline: none;
            font-size: 0.28rem;
            line-height: 1.71429;
            letter-spacing: 0.04em; }
          #recruit #jobform .jobform__content__item__input__radio {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start; }
            #recruit #jobform .jobform__content__item__input__radio__label {
              position: relative;
              /* ボックスの位置を指定する */
              padding: 0 0 0 42px;
              /* ボックス内側の余白を指定する */
              font-size: 0.32rem;
              line-height: 3.75;
              font-weight: 700;
              color: #435a6e; }
            #recruit #jobform .jobform__content__item__input__radio__label:after, #recruit #jobform .jobform__content__item__input__radio__label:before {
              position: absolute;
              /* ボックスの位置を指定する */
              content: "";
              /* ボックスのコンテンツ */
              display: block;
              /* ブロックレベル要素化する */
              top: 50%;
              /* 上部から配置の基準位置を決める */ }
            #recruit #jobform .jobform__content__item__input__radio__label:after {
              left: 15px;
              /* 左から配置の基準位置を決める */
              margin-top: -10px;
              /* チェック枠の位置 */
              width: 16px;
              /* ボックスの横幅を指定する */
              height: 16px;
              /* ボックスの高さを指定する */
              border: 2px solid #ccc;
              /* ボックスの境界線を実線で指定する */
              border-radius: 50%;
              /* ボックスの角丸を指定する */ }
            #recruit #jobform .jobform__content__item__input__radio__label:before {
              left: 20px;
              /* 左から配置の基準位置を決める */
              margin-top: -5px;
              /* チェックマークの位置 */
              width: 10px;
              /* ボックスの横幅を指定する */
              height: 10px;
              /* ボックスの高さを指定する */
              background: red;
              /* ボックスの背景色を指定する */
              border-radius: 50%;
              /* ボックスの角丸を指定する */
              opacity: 0;
              /* 要素を透過指定する */ }
            #recruit #jobform .jobform__content__item__input__radio input[type=radio] {
              display: none; }
            #recruit #jobform .jobform__content__item__input__radio input[type=radio]:checked + .jobform__content__item__input__radio__label::before {
              opacity: 1;
              /* 要素を表示する */ }
            #recruit #jobform .jobform__content__item__input__radio__label:hover:after {
              border-color: #0171bd;
              /* ボックスの境界線を実線で指定する */ }
        #recruit #jobform .jobform__content__item .birthday__input {
          width: 2.4rem !important;
          margin-right: 0.19rem; }
        #recruit #jobform .jobform__content__item .birthday__span {
          font-size: 0.32rem;
          line-height: 1.875;
          letter-spacing: 0.04em;
          color: #435a6e;
          margin-right: 0.39rem; }
        #recruit #jobform .jobform__content__item label {
          position: relative;
          display: block;
          width: 100%; }
          #recruit #jobform .jobform__content__item label::before {
            position: absolute;
            content: "";
            top: 45%;
            left: 0.42rem;
            border: 0.12rem solid transparent;
            border-top: 0.14rem solid #6F8293; }
          #recruit #jobform .jobform__content__item label select {
            display: block;
            width: 100%;
            font-size: 0.32rem;
            color: #6F8293;
            line-height: 1.875;
            padding: 0.36rem;
            padding-left: 0.74rem;
            border: 0.02rem solid #CCCCCC;
            border-radius: 0.1rem;
            outline: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none; }
            #recruit #jobform .jobform__content__item label select::-ms-expand {
              display: none; }
        #recruit #jobform .jobform__content__item input[type=text] {
          font-size: 0.32rem;
          color: #6F8293;
          line-height: 1.875;
          box-sizing: border-box;
          width: 100%;
          padding: 0.36rem;
          border: 0.02rem solid #CCCCCC;
          border-radius: 0.1rem;
          outline: none; }
      #recruit #jobform .jobform__content__submit__agree {
        width: 4.24rem;
        margin: 0 auto;
        margin-bottom: 0.6rem; }
        #recruit #jobform .jobform__content__submit__agree input[type=checkbox] {
          display: none; }
        #recruit #jobform .jobform__content__submit__agree__label {
          box-sizing: border-box;
          cursor: pointer;
          display: inline-block;
          position: relative;
          width: auto;
          outline: none;
          font-size: 0.32rem;
          line-height: 1.875;
          color: #435a6e;
          padding-left: 0.62rem; }
          #recruit #jobform .jobform__content__submit__agree__label::before {
            background: #fff;
            border: 0.02rem solid #DDDDDD;
            border-radius: 0.04rem;
            content: '';
            display: block;
            height: 0.4rem;
            left: 0;
            margin-top: -0.16rem;
            position: absolute;
            top: 50%;
            width: 0.4rem; }
          #recruit #jobform .jobform__content__submit__agree__label::after {
            border-right: 0.02rem solid #231815;
            border-bottom: 0.02rem solid #231815;
            content: '';
            display: block;
            height: 0.3rem;
            left: 3.2%;
            margin-top: -0.14rem;
            opacity: 0;
            position: absolute;
            top: 50%;
            transform: rotate(45deg);
            width: 0.16rem; }
        #recruit #jobform .jobform__content__submit__agree input[type=checkbox]:checked + .jobform__content__submit__agree__label::after {
          opacity: 1; }
      #recruit #jobform .jobform__content__submit__btn {
        width: 4rem;
        margin: 0 auto; }
        #recruit #jobform .jobform__content__submit__btn a {
          font-size: 0.32rem;
          line-height: 1.875;
          font-weight: 700;
          color: #fff;
          text-align: center;
          display: inline-block;
          width: 100%;
          padding: 0.48rem 0;
          background: #3AC319;
          border-radius: 0.2rem; } }

@media (max-width: 768px) {
  #recruit .sec .container {
    width: 100%;
    max-width: 32rem;
    padding: 0 1.70667rem;
    margin: 0 auto;
    position: relative; }
  #recruit .sec-title {
    font-size: 1.19467rem;
    line-height: 1.42857;
    font-weight: 700;
    color: #21589c;
    margin-bottom: 1.62133rem; }
  #recruit #desc {
    padding: 4.26667rem 0 2.56rem;
    background: #fff; }
    #recruit #desc .desc__desc {
      font-size: 0.768rem;
      font-weight: 700;
      line-height: 1.77778;
      letter-spacing: 0.04em; }
  #recruit .sbiwork {
    padding-bottom: 3.84rem !important; }
    #recruit .sbiwork .desc__desc {
      text-align: center; }
  #recruit #box {
    padding: 2.13333rem 0 0;
    background: #fff; }
    #recruit #box .box__title {
      width: 100%;
      height: 8.53333rem;
      text-align: center;
      background-color: #3AC319;
      font-size: 1.024rem;
      line-height: 1.5;
      color: #fff;
      border-radius: 0.42667rem;
      padding-top: 3.79733rem; }
    #recruit #box .box a:first-child .box__title {
      padding-top: 2.98667rem; }
    #recruit #box .box a:nth-child(n+2) .box__title {
      margin-top: 2.56rem; }
  #recruit #sbiwork_box {
    padding: 0 0 8.53333rem;
    background: #fff; }
    #recruit #sbiwork_box .sbiwork_box {
      text-align: center; }
      #recruit #sbiwork_box .sbiwork_box__title {
        width: 100%;
        height: 4.26667rem;
        text-align: center;
        background-color: #3AC319;
        font-size: 1.024rem;
        line-height: 1.5;
        color: #fff;
        border-radius: 0.42667rem;
        padding-top: 1.408rem; }
  #recruit #head {
    letter-spacing: 0.04em;
    font-weight: 700;
    padding: 4.43733rem 0 2.98667rem;
    background: #fff; }
    #recruit #head .head__title {
      font-size: 1.536rem;
      line-height: 1.5;
      padding-bottom: 0.512rem;
      position: relative; }
      #recruit #head .head__title::after {
        content: "";
        display: inline-block;
        width: 1.70667rem;
        height: 0.08533rem;
        background: #21589c;
        position: absolute;
        bottom: 0;
        left: 0; }
    #recruit #head .head__desc {
      font-size: 0.768rem;
      line-height: 1.77778;
      margin-top: 3.072rem; }
  #recruit #btn .btn {
    width: 24.74667rem;
    margin: 0 auto; }
    #recruit #btn .btn a {
      letter-spacing: 0.04em;
      font-size: 1.024rem;
      font-weight: 700;
      line-height: 1.33333;
      text-align: center;
      display: inline-block;
      width: 100%;
      padding: 1.408rem 7.59467rem 1.36533rem;
      color: #3AC319;
      background: #fff;
      margin-bottom: 4.26667rem;
      border: 0.04267rem solid #3AC319;
      border-radius: 0.42667rem; }
  #recruit #content {
    letter-spacing: 0.04em;
    font-weight: 700;
    padding: 3.98933rem 0 4.26667rem;
    background: #EAF7EF; }
    #recruit #content .content__title {
      font-size: 1.28rem;
      line-height: 1.2;
      color: #3AC319;
      margin-bottom: 1.536rem; }
    #recruit #content .content__box {
      background: #fff;
      font-size: 0.68267rem;
      font-weight: 700;
      line-height: 2.25;
      padding: 1.7664rem 0.85333rem 1.74933rem 0.85333rem;
      border-radius: 0.42667rem; }
      #recruit #content .content__box ul {
        width: 100%; }
        #recruit #content .content__box ul li {
          position: relative;
          padding-left: 1.06667rem; }
          #recruit #content .content__box ul li::before {
            position: absolute;
            content: "";
            width: 0.68267rem;
            height: 0.68267rem;
            top: 0.59733rem;
            left: 0;
            vertical-align: top;
            border-radius: 0.68267rem;
            background-color: #3AC319; }
      #recruit #content .content__box__img {
        width: 15.36rem;
        margin: 0 auto;
        margin-top: 1.28rem; }
    #recruit #content .content__title:nth-child(3) {
      margin-top: 3.41333rem; }
  #recruit #question {
    letter-spacing: 0.04em;
    padding: 4.26667rem 0 4.24533rem;
    background: #fff; }
    #recruit #question .question__title {
      font-size: 1.28rem;
      font-weight: 700;
      line-height: 1.2;
      color: #3AC319;
      margin-bottom: 2.36373rem; }
    #recruit #question .question__item {
      margin-bottom: 1.28rem; }
      #recruit #question .question__item__title {
        background: #3AC319;
        font-size: 0.768rem;
        font-weight: 700;
        line-height: 1.66667;
        color: #fff;
        padding: 1.18187rem 0.85333rem 0.92587rem 0.85333rem;
        border-radius: 0.42667rem 0.42667rem 0 0;
        position: relative; }
        #recruit #question .question__item__title h4::before {
          content: "";
          display: inline-block;
          width: 1.70667rem;
          height: 1.70667rem;
          background: url(../img/recruit/lifestyle/icon_lifestyle01.svg) no-repeat;
          background-size: contain;
          vertical-align: middle;
          margin-right: 0.85333rem; }
      #recruit #question .question__item__desc {
        position: relative;
        background: #fff;
        font-size: 0.68267rem;
        line-height: 1.875;
        padding: 1.14347rem 1.536rem 1.39093rem 3.41333rem;
        border: 0.04267rem solid #DDDDDD;
        border-radius: 0 0 0.42667rem 0.42667rem; }
        #recruit #question .question__item__desc span::before {
          position: absolute;
          content: "";
          display: inline-block;
          width: 1.70667rem;
          height: 1.70667rem;
          background: url(../img/recruit/lifestyle/icon_lifestyle02.svg) no-repeat;
          background-size: contain;
          top: 0.85333rem;
          left: 0.85333rem;
          margin-right: 0.64rem; }
  #recruit #found {
    padding: 4.2496rem 0 4.26667rem;
    background: #EAF7EF; }
    #recruit #found .found__title {
      font-size: 1.28rem;
      line-height: 1.53333;
      font-weight: 700;
      color: #3AC319;
      text-align: center;
      margin-bottom: 1.36533rem; }
    #recruit #found .found__desc {
      font-size: 0.68267rem;
      line-height: 1.875;
      text-align: center;
      margin-bottom: 2.432rem; }
    #recruit #found .found__btn {
      width: 24.74667rem;
      margin: 0 auto; }
      #recruit #found .found__btn a {
        font-size: 1.024rem;
        line-height: 1.33333;
        font-weight: 700;
        color: #fff;
        text-align: center;
        display: inline-block;
        width: 100%;
        padding: 1.408rem 0;
        background: #3AC319;
        border-radius: 0.42667rem; }
  #recruit #flow {
    background: #EAF7EF;
    padding: 4.26667rem 0;
    position: relative; }
    #recruit #flow .flow__title {
      color: #3AC319; }
    #recruit #flow .flow__desc {
      font-size: 0.68267rem;
      line-height: 1.875;
      letter-spacing: 0.04em;
      font-weight: 500;
      margin-bottom: 2.304rem; }
    #recruit #flow .flow__contents {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex-wrap: wrap; }
    #recruit #flow .flow__item {
      width: 12.8rem;
      height: 8.53333rem;
      background: #fff;
      position: relative; }
      #recruit #flow .flow__item:nth-child(n + 2) {
        margin-top: 0.42667rem; }
      #recruit #flow .flow__item:last-child::before, #recruit #flow .flow__item:last-child::after {
        content: none; }
      #recruit #flow .flow__item:last-child .flow__item__wrapper {
        margin-top: 0; }
      #recruit #flow .flow__item::before, #recruit #flow .flow__item::after {
        content: "";
        width: 6.4rem;
        height: 2.13333rem;
        display: inline-block;
        position: absolute;
        bottom: 0; }
      #recruit #flow .flow__item::before {
        left: 0;
        border-top: 1.06667rem solid transparent;
        border-right: 3.2rem solid transparent;
        border-bottom: 1.06667rem solid #EAF7EF;
        border-left: 3.2rem solid #EAF7EF; }
      #recruit #flow .flow__item::after {
        right: 0;
        border-top: 1.06667rem solid transparent;
        border-right: 3.2rem solid #EAF7EF;
        border-bottom: 1.06667rem solid #EAF7EF;
        border-left: 3.2rem solid transparent; }
      #recruit #flow .flow__item__wrapper {
        letter-spacing: 0.04em;
        text-align: center;
        white-space: nowrap;
        margin-top: -0.768rem;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        transform: translate(-50%, -50%); }
      #recruit #flow .flow__item__number {
        font-size: 0.59733rem;
        line-height: 1.21429;
        font-weight: 600;
        color: #3AC319;
        margin-bottom: 0.384rem; }
      #recruit #flow .flow__item__text {
        font-size: 0.68267rem;
        line-height: 1.5;
        font-weight: 700; }
  #recruit #contact {
    padding: 3.84rem 0 8.53333rem;
    background: #fff; }
    #recruit #contact .contact__title {
      font-size: 1.28rem;
      line-height: 1.2;
      font-weight: 700;
      color: #3AC319;
      text-align: center;
      margin-bottom: 2.36373rem; }
    #recruit #contact .contact__btn {
      width: 24.74667rem;
      margin: 0 auto; }
      #recruit #contact .contact__btn a {
        font-size: 1.024rem;
        line-height: 1.33333;
        font-weight: 700;
        color: #fff;
        text-align: center;
        display: inline-block;
        width: 100%;
        padding: 1.408rem 0;
        background: #3AC319;
        border-radius: 0.42667rem; }
  #recruit #jobform {
    padding: 3.84rem 0 6.82667rem;
    background: #fff; }
    #recruit #jobform .jobform__title {
      font-size: 1.28rem;
      line-height: 1.2;
      font-weight: 700;
      color: #3AC319;
      text-align: center;
      margin-bottom: 2.3296rem; }
    #recruit #jobform .jobform__content {
      padding: 1.28rem 1.70667rem 1.28rem 1.70667rem;
      border: 0.04267rem solid #CCCCCC;
      border-radius: 0.42667rem; }
      #recruit #jobform .jobform__content__desc {
        width: 100%;
        font-size: 0.68267rem;
        line-height: 1.875;
        margin: 0 auto;
        margin-bottom: 1.92rem; }
      #recruit #jobform .jobform__content__item {
        width: 100%;
        margin-bottom: 0.85333rem;
        position: relative; }
        #recruit #jobform .jobform__content__item__label {
          width: 100%;
          margin-right: 2.56rem;
          font-size: 0.68267rem;
          line-height: 4.0625;
          font-weight: 700;
          color: #435a6e;
          letter-spacing: 0.04em; }
        #recruit #jobform .jobform__content__item__input {
          width: 100%; }
          #recruit #jobform .jobform__content__item__input__box {
            width: 100%;
            height: 11.94667rem;
            overflow-x: auto;
            box-sizing: border-box;
            margin-bottom: 1.664rem;
            padding: 0.85333rem;
            border: 0.04267rem solid #CCCCCC;
            border-radius: 0.21333rem;
            outline: none;
            font-size: 0.59733rem;
            line-height: 1.71429;
            letter-spacing: 0.04em; }
        #recruit #jobform .jobform__content__item .birthday__input {
          width: 5.12rem !important;
          margin-right: 0.40533rem; }
        #recruit #jobform .jobform__content__item .birthday__span {
          font-size: 0.68267rem;
          line-height: 1.875;
          letter-spacing: 0.04em;
          color: #435a6e;
          margin-right: 0.832rem; }
        #recruit #jobform .jobform__content__item label {
          position: relative;
          display: block;
          width: 100%; }
          #recruit #jobform .jobform__content__item label::before {
            position: absolute;
            content: "";
            top: 45%;
            left: 0.896rem;
            border: 0.256rem solid transparent;
            border-top: 0.29867rem solid #6F8293; }
          #recruit #jobform .jobform__content__item label select {
            display: block;
            width: 100%;
            font-size: 0.68267rem;
            color: #6F8293;
            line-height: 1.875;
            padding: 0.768rem;
            padding-left: 1.57867rem;
            border: 0.04267rem solid #CCCCCC;
            border-radius: 0.21333rem;
            outline: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none; }
            #recruit #jobform .jobform__content__item label select::-ms-expand {
              display: none; }
        #recruit #jobform .jobform__content__item input[type=text] {
          font-size: 0.68267rem;
          color: #6F8293;
          line-height: 1.875;
          box-sizing: border-box;
          width: 100%;
          padding: 0.768rem;
          border: 0.04267rem solid #CCCCCC;
          border-radius: 0.21333rem;
          outline: none; }
      #recruit #jobform .jobform__content__submit__agree {
        width: 11.09333rem;
        margin: 0 auto;
        margin-bottom: 1.28rem; }
        #recruit #jobform .jobform__content__submit__agree input[type=checkbox] {
          display: none; }
        #recruit #jobform .jobform__content__submit__agree__label {
          box-sizing: border-box;
          cursor: pointer;
          display: inline-block;
          position: relative;
          width: auto;
          outline: none;
          font-size: 0.68267rem;
          line-height: 1.875;
          color: #435a6e;
          padding-left: 1.32267rem; }
          #recruit #jobform .jobform__content__submit__agree__label::before {
            background: #fff;
            border: 0.04267rem solid #DDDDDD;
            border-radius: 0.08533rem;
            content: '';
            display: block;
            height: 0.85333rem;
            left: 0;
            margin-top: -0.34133rem;
            position: absolute;
            top: 50%;
            width: 0.85333rem; }
          #recruit #jobform .jobform__content__submit__agree__label::after {
            border-right: 0.04267rem solid #231815;
            border-bottom: 0.04267rem solid #231815;
            content: '';
            display: block;
            height: 0.64rem;
            left: 3.2%;
            margin-top: -0.29867rem;
            opacity: 0;
            position: absolute;
            top: 50%;
            transform: rotate(45deg);
            width: 0.34133rem; }
        #recruit #jobform .jobform__content__submit__agree input[type=checkbox]:checked + .jobform__content__submit__agree__label::after {
          opacity: 1; }
      #recruit #jobform .jobform__content__submit__btn {
        width: 8.53333rem;
        margin: 0 auto; }
        #recruit #jobform .jobform__content__submit__btn a {
          font-size: 0.68267rem;
          line-height: 1.875;
          font-weight: 700;
          color: #fff;
          text-align: center;
          display: inline-block;
          width: 100%;
          padding: 1.024rem 0;
          background: #3AC319;
          border-radius: 0.42667rem; } }
