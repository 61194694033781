#kv {
  &.kv-common {
    color: $color-white;
    width: 100%;
    height: cal(340, pc);
    position: relative;

    .container {
      width: 100%;
      max-width: cal($pcsize, pc);
      height: 100%;
      padding: 0 cal(180, pc);
      margin: 0 auto;
      position: relative;
    }

    .kv {
      &__bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__title {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        h1 {
          font-size: cal(48, pc);
          line-height: (70 / 48);
          letter-spacing: 0.04em;
          font-weight: $bold;
          margin-bottom: cal(14.5, pc);
        }

        p {
          font-size: cal(20, pc);
          line-height: (29 / 20);
          letter-spacing: 0.04em;
          font-weight: $bold;
        }
      }

      &__breadcrumb {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: absolute;
        left: cal(180, pc);
        bottom: cal(32, pc);

        li {
          font-size: cal(14, pc);
          line-height: (20 / 14);
          letter-spacing: 0.04em;
          margin-right: cal(28, pc);
          position: relative;

          &:last-child {
            &::after {
              content: none;
            }
          }

          &::after {
            content: "";
            width: cal(10, pc);
            height: cal(1, pc);
            background: currentColor;
            position: absolute;
            top: 55%;
            left: calc(100% + #{cal(9, pc)});
            transform: translateY(-50%) rotate(-45deg);
          }
        }
      }
    }
  }
}

#top,
#service-list {
  #service {
    .service {
      &__title {
        margin-bottom: cal(70, pc);
      }

      &__contents {
        margin-bottom: cal(80, pc);
      }

      &__block {
        &:nth-child(n + 2) {
          margin-top: cal(51.5, pc);
        }

        &__title {
          font-size: cal(24, pc);
          line-height: (36 / 24);
          letter-spacing: 0.04em;
          font-weight: $bold;
          padding-left: cal(35, pc);
          margin-bottom: cal(36, pc);
          position: relative;

          &::before {
            content: "";
            width: cal(20, pc);
            height: cal(2, pc);
            background: currentColor;
            position: absolute;
            top: cal(17, pc);
            left: 0;
          }
        }
      }

      &__list {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      &__item {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: calc(50% - #{cal(20, pc)});
        min-height: cal(200, pc);
        background: $color-white;
        position: relative;
        transition: 0.3s ease all;

        &.hover-animation {
          &:hover {
            opacity: 0.7;
          }
        }

        >a {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }

        &:nth-child(n + 3) {
          margin-top: cal(30, pc);
        }

        &__img {
          width: cal(200, pc);
          height: cal(200, pc);
        }

        &__content {
          flex: 1;
          padding: cal(21.5, pc) cal(30, pc) 0 cal(38.5, pc);
        }

        &__title {
          font-size: cal(20, pc);
          line-height: (24 / 20);
          letter-spacing: 0.04em;
          font-weight: $bold;
          color: $primary-color;
          margin-bottom: cal(7.5, pc);
        }

        &__desc {
          font-size: cal(15, pc);
          line-height: (21 / 15);
          letter-spacing: 0.04em;
          color: $primary-color;
          min-height: cal(21, pc);
          margin-bottom: cal(15, pc);
        }

        &__text {
          font-size: cal(15, pc);
          line-height: (24 / 15);
          letter-spacing: 0.04em;
        }
      }

      &__btn {
        margin: 0 auto;
        @include Button;
      }
    }
  }
}