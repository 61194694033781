#recruit {
  .sec {
    .container {
      width: 100%;
      max-width: cal($spsize);
      padding: 0 cal(40);
      margin: 0 auto;
      position: relative;
    }

    &-title {
      font-size: cal(28);
      line-height: (40 / 28);
      font-weight: $bold;
      color: $primary-color;
      margin-bottom: cal(38);
    }
  }

  #desc {
    padding: cal(100) 0 cal(60);
    background: $color-white;

    .desc {
      &__desc {
        font-size: cal(18);
        font-weight: $bold;
        line-height: (32 / 18);
        letter-spacing: 0.04em;
      }
    }
  }

  .sbiwork{
    padding-bottom: cal(90) !important;

    .desc__desc {
      text-align: center;
    }
  }

  #box {
    padding: cal(50) 0 0;
    background: $color-white;

    .box {
      &__title {
        width: 100%;
        height: cal(200);
        text-align: center;
        background-color: $color-green;
        font-size: cal(24);
        line-height: (36 / 24);
        color: $color-white;
        border-radius: cal(10);
        padding-top: cal(89);
      }

      a:first-child {
        .box__title{
          padding-top: cal(70);
        }
      }

      a:nth-child(n+2) {
        .box__title{
          margin-top: cal(60);
        }
      }
    }
  }

  #sbiwork_box {
    padding: 0 0 cal(200);
    background: $color-white;

    .sbiwork_box {
      text-align: center;

      &__title {
        width: 100%;
        height: cal(100);
        text-align: center;
        background-color: $color-green;
        font-size: cal(24);
        line-height: (36 / 24);
        color: $color-white;
        border-radius: cal(10);
        padding-top: cal(33);
      }

    }
  }

  #head {
    letter-spacing: 0.04em;
    font-weight: $bold;
    padding: cal(104) 0 cal(70);
    background: $color-white;

    .head {
      &__title {
        font-size: cal(36);
        line-height: (54 / 36);
        padding-bottom: cal(12);
        position: relative;

        &::after {
          content: "";
          display: inline-block;
          width: cal(40);
          height: cal(2);
          background: $primary-color;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }

      &__desc {
        font-size: cal(18);
        line-height: (32 / 18);
        margin-top: cal(72);
      }
    }
  }

  #btn {
    .btn{
      width: cal(580);
      margin: 0 auto;

      a {
        letter-spacing: 0.04em;
        font-size: cal(24);
        font-weight: $bold;
        line-height: (32 / 24);
        text-align: center;
        display: inline-block;
        width: 100%;
        padding: cal(33) cal(178) cal(32);
        color: $color-green;
        background: $color-white;
        margin-bottom: cal(100);
        border: cal(1) solid $color-green;
        border-radius: cal(10);
      }
    }
  }

  #content {
    letter-spacing: 0.04em;
    font-weight: $bold;
    padding: cal(93.5) 0 cal(100);
    background: $tertiary-background-color;

    .content {
      &__title {
        font-size: cal(30);
        line-height: (36 / 30);
        color: $color-green;
        margin-bottom: cal(36);
      }

      &__box {
        background: $color-white;
        font-size: cal(16);
        font-weight: $bold;
        line-height: (36 / 16);
        padding: cal(41.4) cal(20) cal(41) cal(20);
        border-radius: cal(10);

        ul{
          width: 100%;

          li{
            position: relative;
            padding-left: cal(25);

            &::before {
              position: absolute;
              content: "";
              width: cal(16);
              height: cal(16);
              top: cal(14);
              left: 0;
              vertical-align: top;
              border-radius: cal(16);
              background-color: $color-green;
            }
          }
        }

        &__img {
          width: cal(360);
          margin: 0 auto;
          margin-top: cal(30);
        }
      }

      &__title:nth-child(3){
        margin-top: cal(80);
      }
    }
  }

  #question {
    letter-spacing: 0.04em;
    padding: cal(100) 0 cal(99.5);
    background: $color-white;

    .question {
      &__title {
        font-size: cal(30);
        font-weight: $bold;
        line-height: (36 / 30);
        color: $color-green;
        margin-bottom: cal(55.4);
      }

      &__item {
        margin-bottom: cal(30);

        &__title {
          background: $color-green;
          font-size: cal(18);
          font-weight: $bold;
          line-height: (30 / 18);
          color: $color-white;
          padding: cal(27.7) cal(20) cal(21.7) cal(20);
          border-radius: cal(10) cal(10) 0 0;

          position: relative;

          h4{
            &::before {
              content: "";
              display: inline-block;
              width: cal(40);
              height: cal(40);
              background: url(../img/recruit/lifestyle/icon_lifestyle01.svg) no-repeat;
              background-size: contain;
              vertical-align: middle;
              margin-right: cal(20);
            }
          }
        }

        &__desc {
          position: relative;
          background: $color-white;
          font-size: cal(16);
          line-height: (30 / 16);
          padding: cal(26.8) cal(36 ) cal(32.6) cal(80);
          border: cal(1) solid #DDDDDD;
          border-radius: 0 0 cal(10) cal(10);

          span{
            &::before {
              position: absolute;
              content: "";
              display: inline-block;
              width: cal(40);
              height: cal(40);
              background: url(../img/recruit/lifestyle/icon_lifestyle02.svg) no-repeat;
              background-size: contain;
              top: cal(20);
              left: cal(20);
              margin-right: cal(15);
            }
          }
        }
      }
    }
  }

  #found {
    padding: cal(99.6) 0 cal(100);
    background: $tertiary-background-color;

    .found {
      &__title {
        font-size: cal(30);
        line-height: (46 / 30);
        font-weight: $bold;
        color: $color-green;
        text-align: center;
        margin-bottom: cal(32);
      }

      &__desc {
        font-size: cal(16);
        line-height: (30 / 16);
        text-align: center;
        margin-bottom: cal(57);
      }

      &__btn {
        width: cal(580);
        margin: 0 auto;

        a {
          font-size: cal(24);
          line-height: (32 / 24);
          font-weight: $bold;
          color: $color-white;
          text-align: center;
          display: inline-block;
          width: 100%;
          padding: cal(33) 0;
          background: $color-green;
          border-radius: cal(10);

        }
      }
    }
  }

  #flow {
    background: $tertiary-background-color;
    padding: cal(100) 0;
    position: relative;

    .flow {
      &__title {
        color: $color-green;
      }

      &__desc {
        font-size: cal(16);
        line-height: (30 / 16);
        letter-spacing: 0.04em;
        font-weight: $medium;
        margin-bottom: cal(54);
      }


      &__contents {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
      }

      &__item {
        width: cal(300);
        height: cal(200);
        background: $background-color;
        position: relative;

        &:nth-child(n + 2) {
          margin-top: cal(10);
        }

        &:last-child {
          &::before,
          &::after {
            content: none;
          }

          .flow__item__wrapper {
            margin-top: 0;
          }
        }

        &::before,
        &::after {
          content: "";
          width: cal(150);
          height: cal(50);
          display: inline-block;
          position: absolute;
          bottom: 0;
        }

        &::before {
          left: 0;
          border-top: cal(25) solid transparent;
          border-right: cal(75) solid transparent;
          border-bottom: cal(25) solid $tertiary-background-color;
          border-left: cal(75) solid $tertiary-background-color;
        }

        &::after {
          right: 0;
          border-top: cal(25) solid transparent;
          border-right: cal(75) solid $tertiary-background-color;
          border-bottom: cal(25) solid $tertiary-background-color;
          border-left: cal(75) solid transparent;
        }

        &__wrapper {
          letter-spacing: 0.04em;
          text-align: center;
          white-space: nowrap;
          margin-top: cal(-18);
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 2;
          transform: translate(-50%, -50%);
        }

        &__number {
          font-size: cal(14);
          line-height: (17 / 14);
          font-weight: $semibold;
          color: $color-green;
          margin-bottom: cal(9);
        }

        &__text {
          font-size: cal(16);
          line-height: (24 / 16);
          font-weight: $bold;
        }
      }
    }
  }

  #contact {
    padding: cal(90) 0 cal(200);
    background: $background-color;

    .contact {
      &__title {
        font-size: cal(30);
        line-height: (36 / 30);
        font-weight: $bold;
        color: $color-green;
        text-align: center;
        margin-bottom: cal(55.4);
      }

      &__btn {
        width: cal(580);
        margin: 0 auto;

        a {
          font-size: cal(24);
          line-height: (32 / 24);
          font-weight: $bold;
          color: $color-white;
          text-align: center;
          display: inline-block;
          width: 100%;
          padding: cal(33) 0;
          background: $color-green;
          border-radius: cal(10);

        }
      }
    }
  }

  #jobform {
    padding: cal(90) 0 cal(160);
    background: $color-white;

    .jobform {
      &__title {
        font-size: cal(30);
        line-height: (36 / 30);
        font-weight: $bold;
        color: $color-green;
        text-align: center;
        margin-bottom: cal(54.6);
      }

      &__content {
        padding: cal(30) cal(40) cal(30) cal(40);
        border: cal(1) solid #CCCCCC;
        border-radius: cal(10);

        &__desc {
          width: 100%;
          font-size: cal(16);
          line-height: (30 / 16);
          margin: 0 auto;
          margin-bottom: cal(45);
        }

        &__item {
          width: 100%;
          margin-bottom: cal(20);
          position: relative;

          &__label {
            width: 100%;
            margin-right: cal(60);
            font-size: cal(16);
            line-height: (65 / 16);
            font-weight: $bold;
            color: $text-color;
            letter-spacing: 0.04em;
          }

          &__input {
            width: 100%;

            &__box {
              width: 100%;
              height: cal(280);
              overflow-x: auto;
              box-sizing: border-box;
              margin-bottom: cal(39);
              padding: cal(20);
              border: cal(1) solid #CCCCCC;
              border-radius: cal(5);
              outline: none;

              font-size: cal(14);
              line-height: (24 / 14);
              letter-spacing: 0.04em;
            }
          }

          .birthday {
            &__input {
              width: cal(120) !important;
              margin-right: cal(9.5);
            }

            &__span {
              font-size: cal(16);
              line-height: (30 / 16);
              letter-spacing: 0.04em;
              color: $text-color;
              margin-right: cal(19.5);
            }
          }

          label {
            position: relative;
            display: block;
            width: 100%;

            &::before {
              position: absolute;
              content: "";
              top: 45%;
              left: cal(21);
              border: cal(6) solid transparent;
              border-top: cal(7) solid #6F8293;
            }

            select {
              display: block;
              width: 100%;
              font-size: cal(16);
              color: #6F8293;
              line-height: (30 / 16);
              padding: cal(18);
              padding-left: cal(37);
              border: cal(1) solid #CCCCCC;
              border-radius: cal(5);
              outline: none;

              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;

              &::-ms-expand {
                display: none;
              }
            }
          }

          input[type=text] {
            font-size: cal(16);
            color: #6F8293;
            line-height: (30 / 16);
            box-sizing: border-box;
            width: 100%;
            padding: cal(18);
            border: cal(1) solid #CCCCCC;
            border-radius: cal(5);
            outline: none;
          }
        }

        &__submit {
          &__agree {
            width: cal(260);
            margin: 0 auto;
            margin-bottom: cal(30);

            input[type=checkbox] {
              display: none;
            }

            &__label {
              box-sizing: border-box;
              cursor: pointer;
              display: inline-block;
              position: relative;
              width: auto;
              outline: none;
              font-size: cal(16);
              line-height: (30 / 16);
              color: $text-color;
              padding-left: cal(31);

              &::before {
                background: #fff;
                border: cal(1) solid #DDDDDD;
                border-radius: cal(2);
                content: '';
                display: block;
                height: cal(20);
                left: 0;
                margin-top: cal(-8);
                position: absolute;
                top: 50%;
                width: cal(20);
              }
              &::after {
                border-right: cal(1) solid #231815;
                border-bottom: cal(1) solid #231815;
                content: '';
                display: block;
                height: cal(15);
                left: 3.2%;
                margin-top: cal(-7);
                opacity: 0;
                position: absolute;
                top: 50%;
                transform: rotate(45deg);
                width: cal(8);
              }
            }

            input[type=checkbox]:checked + .jobform__content__submit__agree__label::after {
              opacity: 1;
            }
          }
          &__btn {
            width: cal(200);
            margin: 0 auto;

            a {
              font-size: cal(16);
              line-height: (30 / 16);
              font-weight: $bold;
              color: $color-white;
              text-align: center;
              display: inline-block;
              width: 100%;
              padding: cal(24) 0;
              background: $color-green;
              border-radius: cal(10);
            }
          }
        }
      }
    }
  }
}