#contact {
  .sec {
    .container {
      width: 100%;
      max-width: cal($spsize);
      padding: 0 cal(40);
      margin: 0 auto;
      position: relative;
    }

    &-title {
      font-size: cal(28);
      line-height: (40 / 28);
      font-weight: $bold;
      color: $primary-color;
      margin-bottom: cal(38);
    }
  }
  
  #contact {
    padding: cal(100) 0 cal(190);
    background: $color-white;

    .contact {
      &__form {
        .contact {
          &__item {
            &__input {
              display: block;
            }

            &__confirm {
              display: none;
            }
          }

          &__confirm {
            display: block;
          }
          
          &__submit {
            display: none;
          }
        }

        &.confirm {
          .contact {
            &__item {
              margin-bottom: cal(30);

              &__input {
                display: none;
              }

              &__confirm {
                display: block;
              }
            }

            &__box {
              display: none;
            }

            &__confirm {
              display: none;
            }

            &__submit {
              display: block;
            }
          }
        }
      }

      &__item {
        width: 100%;
        margin-bottom: cal(20);
        position: relative;

        &__label {
          width: cal(320);
          margin-right: cal(26);
          font-size: cal(16);
          // line-height: (65 / 16);
          padding: cal(24.5) 0;
          font-weight: $bold;
          letter-spacing: 0.04em;
        }

        .required {
          &::after {
            content: "【必須】";
            font-size: cal(16);
            letter-spacing: 0.04em;
            color: #FF0000;
            padding-left: cal(6);
          }
        }

        &__input, textarea{
          width: 100%;
        }

        textarea {
          height: cal(200);
        }

        label {
          position: relative;
          display: block;
          width: 100%;

          &::before {
            position: absolute;
            content: "";
            top: 45%;
            left: cal(21);
            border: cal(6) solid transparent;
            border-top: cal(7) solid #6F8293;
          }

          select {
            display: block;
            width: 100%;
            font-size: cal(16);
            color: #6F8293;
            line-height: (30 / 16);
            padding: cal(18);
            padding-left: cal(37);
            border: cal(1) solid #CCCCCC;
            border-radius: cal(5);
            outline: none;

            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;

            &::-ms-expand {
              display: none;
            }
          }
        }

        input[type=text] {
          font-size: cal(16);
          color: #6F8293;
          line-height: (30 / 16);
          box-sizing: border-box;
          width: 100%;
          padding: cal(18);
          border: cal(1) solid #CCCCCC;
          border-radius: cal(5);
          outline: none;
        }

        textarea{
          font-size: cal(16);
          color: #6F8293;
          line-height: (30 / 16);
          box-sizing: border-box;
          width: 100%;
          padding: cal(18);
          border: cal(1) solid #CCCCCC;
          border-radius: cal(5);
          outline: none;
          resize: none;
        }

        
        &__error {
          p {
            font-size: cal(16);
            letter-spacing: 0.04em;
            line-height: (24 / 16);
            color: $color-red;
            margin-top: cal(12);
          }
        }

        &__confirm {
          font-size: cal(16);
          letter-spacing: 0.04em;
          padding: cal(24.5) 0;
        }
      }

      &__item:nth-child(10) {
        margin-bottom: cal(38);
      }

      &__box {
        width: 100%;
        height: cal(280);
        overflow: auto;
        box-sizing: border-box;
        margin-bottom: cal(39);
        padding: cal(20);
        border: cal(1) solid #CCCCCC;
        border-radius: cal(5);
        outline: none;

        font-size: cal(14);
        line-height: (24 / 14);
        letter-spacing: 0.04em;
      }

      &__confirm,
      &__submit {
        &__agree {
          width: cal(260);
          margin: 0 auto;
          margin-bottom: cal(30);

          input[type=checkbox] {
            display: none;
          }

          &__label {
            box-sizing: border-box;
            cursor: pointer;
            display: inline-block;
            position: relative;
            width: auto;
            outline: none;
            font-size: cal(16);
            line-height: (30 / 16);
            color: $text-color;
            padding-left: cal(31);

            &::before {
              background: #fff;
              border: cal(1) solid #DDDDDD;
              border-radius: cal(2);
              content: '';
              display: block;
              height: cal(20);
              left: 0;
              margin-top: cal(-8);
              position: absolute;
              top: 50%;
              width: cal(20);
            }
            &::after {
              border-right: cal(1) solid #231815;
              border-bottom: cal(1) solid #231815;
              content: '';
              display: block;
              height: cal(15);
              left: 3.2%;
              margin-top: cal(-7);
              opacity: 0;
              position: absolute;
              top: 50%;
              transform: rotate(45deg);
              width: cal(8);
            }
          }

          p {
            font-size: cal(16);
            line-height: (30 / 16);
            color: $text-color;
            text-align: center;
          }

          input[type=checkbox]:checked + .contact__submit__agree__label::after {
            opacity: 1;
          }
        }
        &__btn {
          width: cal(280);
          margin: 0 auto;

          button {
            font-size: cal(16);
            line-height: (30 / 16);
            font-weight: $bold;
            color: $color-white;
            text-align: center;
            display: inline-block;
            width: 100%;
            padding: cal(24) 0;
            border: 0;
            outline: 0;
            background: $primary-color;
            position: relative;

            &::after {
              content: "";
              width: cal(18.32);
              height: cal(12.24);
              background: url(../img/common/arrow_right.svg) center / contain no-repeat;
              position: absolute;
              top: 50%;
              right: cal(33.6);
              transform: translateY(-50%);
              z-index: 0;
            }
          }
        }
      }

      &__submit {
        &__return {
          font-size: cal(16);
          line-height: (24 / 16);
          text-align: center;
          margin-top: cal(46);
          cursor: pointer;
        }
      }

      &__thanks, &__error {
        font-size: cal(16);
        line-height: (32 / 16);
        text-align: center;
        margin-bottom: cal(120);

        &__btn {
          width: cal(280);
          margin: 0 auto;

          button {
            font-size: cal(16);
            line-height: (30 / 16);
            font-weight: $bold;
            color: $color-white;
            text-align: center;
            display: inline-block;
            width: 100%;
            padding: cal(24) 0;
            border: 0;
            outline: 0;
            background: $primary-color;
            position: relative;
            cursor: pointer;

            &::after {
              content: "";
              width: cal(18.32);
              height: cal(12.24);
              background: url(../img/common/arrow_right.svg) center / contain no-repeat;
              position: absolute;
              top: 50%;
              right: cal(33.6);
              transform: translateY(-50%);
              z-index: 0;
            }
          }
        }
      }
    }
  }
}