/*-----------------------------------


  アニメーション関連変数


-----------------------------------*/
$fadeinTransition : 0.8s;
$scrollanimationTransition : 3s;

/*-----------------------------------


  ローディングスクリーン


-----------------------------------*/
@keyframes loading {
  0%,
  100%,
  80% {
    box-shadow: 0 0;
    height: 4em;
  }

  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

.load_bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $background-color;
  z-index: 100000;
  transform: translate3d(0, 0, 0);

  body.animation &,
  body.loaded & {
    visibility: hidden;
    opacity: 0;
    transition: 0.3s ease opacity, 0s visibility 0.3s;
  }

  &__contents,
  &__contents:after,
  &__contents:before {
    background: $primary-color;
    animation: loading 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
  }

  &__contents {
    color: $primary-color;
    text-indent: -9999em;
    font-size: cal(20, pc);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3D(-50%, -50%, 0);
    animation-delay: -0.16s;
    @include mq(max) {
      font-size: cal(14);
    }
  }

  &__contents:after,
  &__contents:before {
    position: absolute;
    top: 0;
    content: '';
  }

  &__contents:before {
    left: -1.5em;
    animation-delay: -0.32s;
  }

  &__contents:after {
    left: 1.5em;
  }
}
/*-----------------------------------


  フェードインアニメーション


-----------------------------------*/

.js-fadeIn {
  visibility: hidden;
  opacity: 0;
  transform: translateY(20px);

  &.fadeIn {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    transition: 0s visibility, $fadeinTransition opacity ease, $fadeinTransition transform ease;
  }
}
/*-----------------------------------


  フェードイングループアニメーション


-----------------------------------*/

.js-fadeInGroup {
  .js-fadeInGroupChild {
    visibility: hidden;
    opacity: 0;
    transform: translateY(20px);
  }

  &.fadeIn {
    .js-fadeInGroupChild {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
      transition: 0s visibility, $fadeinTransition opacity ease, $fadeinTransition transform ease;
    }
  }
}
/*-----------------------------------


  フェードインテキストアニメーション


-----------------------------------*/

.js-fadeInText {
  span {
    visibility: hidden;
    opacity: 0;
    transform: translateY(20px);
  }

  &.fadeIn {
    span {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
      transition: 0s visibility, $fadeinTransition opacity ease, $fadeinTransition transform ease;
    }
  }
}
/*-----------------------------------


  KV スクロールアニメーション


-----------------------------------*/
@keyframes scrollAnimation {
  0% {
    transform: scale(0, 1);
    transform-origin: 0 0;
  }

  35% {
    transform: scale(1, 1);
    transform-origin: 0 0;
  }

  35.1% {
    transform: scale(1, 1);
    transform-origin: 100% 0;
  }

  100%,
  70% {
    transform: scale(0 , 1);
    transform-origin: 100% 0;
  }
}

.js-scroll {
  &::after {
    animation: scrollAnimation $scrollanimationTransition ease infinite;
  }
}
/*-----------------------------------


  グラデーションアニメーション


-----------------------------------*/
@keyframes gradation {
  0%,
  100% {
    background-position: 0% 0%;
  }

  50% {
    background-position: 100% 100%;
  }
}
