#banner {
  padding: cal(105) 0 cal(120);
  background: $secondry-background-color;

  .container {
    width: 100%;
    max-width: cal($spsize);
    padding: 0 cal(40);
    margin: 0 auto;
  }

  .banner {
    &__title {
      font-size: cal(30);
      line-height: (44 / 30);
      font-weight: $bold;
      margin-bottom: cal(36.5);
    }

    &__content {
      margin-bottom: cal(60);
    }

    &__block {
      border-top: cal(1) solid $border-color;
      
      &:last-child {
        border-bottom: cal(1) solid $border-color;
      }

      &__title {
        font-size: cal(20);
        line-height: (28 / 20);
        letter-spacing: 0.04em;
        font-weight: $medium;
        padding: cal(40) 0;
      }

      &__list {
        padding: cal(40) 0;
      }
    }

    &__item {
      font-size: cal(20);
      line-height: (28 / 20);
      letter-spacing: 0.04em;
      color: $primary-color;
      padding: 0 cal(24);
      position: relative;

      &:nth-child(n + 2) {
        margin-top: cal(12);
      }

      &::before {
        content: "・";
        position: absolute;
        left: 0;
        top: 0;
      }

      a {
        display: inline-block;
        text-decoration: underline;
      }
    }

    &__images {
      &__item {
        width: cal(480);
        margin: 0 auto;

        &:nth-child(n + 2) {
          margin-top: cal(20);
        }
      }
    }
  }
}